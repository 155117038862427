<script setup>
import { useUserStore } from "@/store";
import { computed, ref } from "vue";
import api from "@/common/api";
import CustomersTable from "@/components/Customers/Tables/CustomersTable.vue"
import CustomerFilters from "@/components/Customers/CustomerFilters.vue"
import { searchClientsApiURLs } from '@/lib/auxiliary/customerSearch'
import { showErrors } from "@/common/showErrors";
import { useRoute } from "vue-router";
import { minLength, helpers, or } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getUnMaskedPhone, removeEmptyFromObject } from "@/lib/utils";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const isLoading = ref(false)
const route = useRoute()

const getFormattedFilters = () => {
  const clientSearchFormCopy = structuredClone(clientSearchForm.value)
  if (searchBy.value === 'phone') {
    clientSearchFormCopy.phone = getUnMaskedPhone(clientSearchFormCopy.phone)
  }
  for (const key in clientSearchFormCopy) {
    if (clientSearchFormCopy[key] === '') {
      delete clientSearchFormCopy[key]
    }
  }
  return removeEmptyFromObject(clientSearchFormCopy)
}

const fetchClientsAsync = async (searchBy = 'phone') => {
  try {
    clientFormValidator.value.$validate()
    if (clientFormValidator.value.$errors.length) {
      throw { clientValidateError: 'Проверьте заполнение полей' }
    }
    isLoading.value = true
    const { data } = await api.get(process.env.VUE_APP_GATE_URL + searchClientsApiURLs[searchBy], { params: getFormattedFilters() })
    clients.value = data.data;
  } catch (error) {
    console.error(error)
  } finally {
    isLoading.value = false
  }
}

const searchBy = ref('phone')
const clientSearchForm = ref({
  phone: '',
  last_name: '',
  first_name: '',
  patronymic: '',
  company: '',
  street: '',
  water_company_id: 1,
  distribution_id: null,
  search: ''
})
const filterErrors = ref([])
const minFieldLength = 2
const clientFormValidationRules = {
  last_name: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'name')) },
  first_name: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'name')) },
  patronymic: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'name')) },
  phone: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'phone')) },
  company: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'company')) },
  address: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'address')) },
  contract_number: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'contract_number')) }
}
const clientFormValidator = useVuelidate(clientFormValidationRules, clientSearchForm)

const clients = ref([])

const getClientById = async (customerId) => {
  try {
    const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customer/' + customerId)
    clients.value.push(data.data)
  } catch (error) {
    showErrors(error)
  }
}

if (route.query.customerId) {
  getClientById(route.query.customerId)
  clientSearchForm.value.water_company_id = +route.query.water_company_id
}

const pageBreadcrumbs = ref([
  {
    title: 'Клиенты',
    href: '/customers/search'
  },
])
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <UILoader :loading="isLoading" />
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Клиенты</h1>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="container-xl ml-0">
            <UIBreadCrumbs :items="pageBreadcrumbs" />
            <div class="row">
              <div class="container-xl ml-0">
                <CustomerFilters v-model="clientSearchForm" v-model:searchBy="searchBy" :isLoadingSearchBtn="isLoading"
                  :validator="clientFormValidator" @search="fetchClientsAsync" @new-error="filterErrors = $event" />
                <CustomersTable :clients="clients" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
 </template>
