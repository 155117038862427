<script setup>
import CustomersTable from '@/components/Customers/Tables/CustomersTable.vue';
import { useUserStore } from "@/store";
import { computed, ref } from 'vue'
import ClientFormComponent from '@/components/Forms/ClientForm.vue';
import AddressFormComponent from '@/components/Forms/AddressForm.vue';
import BaseClient, { ClientFieldsWithEmptyValidation } from "@/lib/models/BaseClient.js"
import BaseAddress, { AddressFieldsWithEmptyValidation } from "@/lib/models/BaseAddress.js"
import api, { parseApiErrorsForValidator } from "@/common/api";
import { removeEmptyFromObject, getUnMaskedPhone } from '@/lib/utils';
import { useToast } from "vue-toastification";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, or, minLength } from '@vuelidate/validators'
import { showErrors } from '@/common/showErrors';
import { useRouter } from 'vue-router';
import { fetchPaymentTypes } from "@/lib/api/params";

const toast = useToast();
const isLoading = ref(false)

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const router = useRouter();

const clientForm = ref(new BaseClient())
const customerChanged = ref(false)
const clientFormValidationRules = {
    phone: { required: helpers.withMessage('Заполните поле телефон', required), minLength: helpers.withMessage('Минимум 11 чисел', minLength(11)) },
    phone2: { minLength: helpers.withMessage('Минимум 11 чисел', or(() => !clientForm.value.phone2, minLength(11))) },
    phone_from_call: { minLength: helpers.withMessage('Минимум 11 чисел', or(() => !clientForm.value.phone_from_call, minLength(11))) },
    payment_type: { required: helpers.withMessage('Выберите тип оплаты', required) },
    source: { required: helpers.withMessage('Выберите откуда узнали о нас', required) },
    choice: { required: helpers.withMessage('Заполните почему выбрали нас', required) },
    first_name: { required: helpers.withMessage('Заполните имя', required), regex: helpers.withMessage('Поле должно содержать только русские символы', helpers.regex(/^[-а-яА-ЯёЁ\\s]+$/)) },
    last_name: { regex: helpers.withMessage('Поле должно содержать только русские символы', helpers.regex(/^[-а-яА-ЯёЁ\\s]+$/)) },
    patronymic: { regex: helpers.withMessage('Поле должно содержать только русские символы', helpers.regex(/^[-а-яА-ЯёЁ\\s]+$/)) },
    company: { required: helpers.withMessage('Заполните компанию', or(() => clientForm.value.type === 2, required)) },
    distribution_id: { required: helpers.withMessage('Выберите партнёра', or(() => !clientForm.value.hasPartner, required)) },
    distribution_customer_id: { required: helpers.withMessage('Введите ID клиента партнёра', or(() => !clientForm.value.hasPartner, required)) },
    ...ClientFieldsWithEmptyValidation
}
const clientFormApiValidationErrors = ref({})
const clientFormValidator = useVuelidate(clientFormValidationRules, clientForm, { $externalResults: clientFormApiValidationErrors })

const getFormattedClientForm = () => {
    const clientFormCopy = structuredClone(clientForm.value)
    clientFormCopy.phone = getUnMaskedPhone(clientForm.value.phone)
    clientFormCopy.phone2 = getUnMaskedPhone(clientForm.value.phone2)
    clientFormCopy.phone_from_call = getUnMaskedPhone(clientForm.value.phone_from_call)
    return clientFormCopy
}

const fetchNewClient = async () => {
    clientFormValidator.value.$validate()
    if (clientFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей клиента' }
    }
    let data;
    if (customerChanged.value) {
        data = await api.patch(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customers/' + addressForm.value.customer_id,
            removeEmptyFromObject({ ...getFormattedClientForm() }))
    } else {
        data = await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customer',
            removeEmptyFromObject({ ...getFormattedClientForm() }))

        addressForm.value.customer_id = data.data.data.customerId
    }
    customerChanged.value = false
    return data.data
}

const addressForm = ref(new BaseAddress({ is_primary: true }))
const addressFormApiValidationErrors = ref({})
const addressFormValidationRules = {
    address_source_string: { required: helpers.withMessage('Заполните полный адрес', required) },
    phone: { minLength: helpers.withMessage('Минимум 11 чисел', or(() => !addressForm.value.phone, minLength(11))) },
    latitude: { required: helpers.withMessage('Заполните ширину', required) },
    longitude: { required: helpers.withMessage('Заполните долготу', required) },
    ...AddressFieldsWithEmptyValidation
}
const addressFormValidator = useVuelidate(addressFormValidationRules, addressForm, { $externalResults: addressFormApiValidationErrors })
const getFormattedAddressForm = () => {
    const addressFormCopy = structuredClone(addressForm.value)
    if (addressFormCopy.phone) {
        addressFormCopy.phone = getUnMaskedPhone(addressForm.value.phone)
    }

    return removeEmptyFromObject(addressFormCopy)
}
const fetchNewAddress = async () => {
    addressFormValidator.value.$validate()
    if (addressFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей адреса' }
    }
    await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/addresses', getFormattedAddressForm())
}

const addNewClientAsync = async () => {
    try {
        isLoading.value = true
        if (!addressForm.value.customer_id || customerChanged.value) {
            await fetchNewClient()
        }
        await fetchNewAddress()
        toast.success("Клиент создан")
        router.push({ path: '/customers/search', query: { customerId: addressForm.value.customer_id, water_company_id: clientForm.value.water_company_id } })
        addressForm.value = new BaseAddress()
        clientForm.value = new BaseClient()
    } catch (error) {
        showErrors(error)
        clientFormApiValidationErrors.value = parseApiErrorsForValidator(error)
        addressFormApiValidationErrors.value = parseApiErrorsForValidator(error)
    } finally {
        isLoading.value = false
    }
}



const clients = ref([])
const getSearchForm = () => {
    const clientFields = ['water_company_id', 'customer_type', 'company', 'company_inn', 'last_name', 'first_name', 'patronymic', 'contract_number', 'payment_type', 'type']
    const addressFields = ['floor', 'address_source_string', 'code', 'entrance']
    const result = {}
    for (const field of clientFields) {
        result[field] = clientForm.value[field]
    }
    
    result.phone = getUnMaskedPhone(clientForm.value.phone ?? '')
    for (const field of addressFields) {
        result[field] = addressForm.value[field]
    }
    result.address_phone = getUnMaskedPhone(addressForm.value.phone ?? '')
    result.flat_or_office = addressForm.value.flat || addressForm.value.office
    return removeEmptyFromObject(result)
}
const searchClientsAsync = async () => {
    try {
        isLoading.value = true

        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customers/findMatches', { params: getSearchForm() })
        clients.value = data.data;
    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

const paymentTypes = ref([])
const fetchPaymentTypesAsync = async (water_company_id) => {
    paymentTypes.value = await fetchPaymentTypes(water_company_id)
    const waterCompanyIdSPB = 1
    const defaultSPBPaymentTypeId = 4
    const defaultMSKPaymentTypeId = 10
    clientForm.value.payment_type = water_company_id === waterCompanyIdSPB ? defaultSPBPaymentTypeId : defaultMSKPaymentTypeId
}

const SPBWaterCompanyId = 1
fetchPaymentTypesAsync(SPBWaterCompanyId)

const updateWaterCompanyHandler = (water_company_id) => {
    fetchPaymentTypesAsync(water_company_id)
    addressForm.value.water_company_id = water_company_id
}

const pageBreadcrumbs = ref([
    {
        title: 'Клиенты',
        href: '/customers/search'
    },
    {
        title: 'Добавить',
        href: '#'
    },
])
</script>

<template>
    <div class="content-wrapper" v-if="isAuthenticated">
        <UILoader :loading="isLoading" />
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2 ">
                    <div class="col-sm-6">
                        <h1 class="m-0">Добавить нового клиента</h1>
                    </div>
                </div>
            </div>
        </div>
        <!-- header off -->
        <!-- content on -->
        <section class="content">
            <div class="container-fluid">
                <UIBreadCrumbs :items="pageBreadcrumbs" />
                <div class="row">
                    <div class="col-lg-auto col-md-12">
                        <!-- Клиент -->
                        <ClientFormComponent v-model="clientForm"
                            @update:model-value="customerChanged = !!addressForm.customer_id" isMainInfo
                            :validator="clientFormValidator" :paymentTypes="paymentTypes"
                            @update_water_company="updateWaterCompanyHandler" />

                        <!-- Адрес -->
                        <AddressFormComponent v-model="addressForm" :customerType="clientForm.type"
                            :validator="addressFormValidator" newClient :waterCompanyId="clientForm.water_company_id" />

                        <CustomersTable showButtons :clients="clients" @search="searchClientsAsync"
                            @add-client="addNewClientAsync" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
