import { helpers, minLength } from '@vuelidate/validators'

export default class BaseClient {
    water_company_id = 1
    phone = null
    type = 2
    company = null
    company_inn = null
    last_name = null
    first_name = null
    patronymic = null
    contract_number = null
    payment_type = null
    fixed_water_price = null
    source = null
    choice = null
    addresses = []
    id = null
    duty = '0,00'
    customerBindings = []
    need_check = false
    invited_customer_id = null
    distribution_id = null
    distribution_customer_id = null
    hasPartner = false
    referral = null
    email = null
    company_description = ''
    is_vip = false
    phone2 = ''
    phone_from_call = ''
    is_need_act = false
    is_phone_robot = false

    constructor(client) {
        this.water_company_id = client?.water_company?.id ?? 1
        this.phone = client?.phone ?? null
        this.type = client?.type?.id ?? 2
        this.company = client?.company ?? null
        this.company_inn = client?.company_inn ?? null
        this.last_name = client?.last_name ?? null
        this.first_name = client?.first_name ?? null
        this.patronymic = client?.patronymic ?? null
        this.contract_number = client?.contract_number ?? null
        this.payment_type = client?.payment_type ?? null
        this.fixed_water_price = client?.fixed_water_price ?? null
        this.source = client?.source ?? null
        this.choice = client?.choice ?? null
        this.addresses = client?.addresses ?? []
        this.id = client?.id ?? null
        this.duty = client?.duty ?? '0,00'
        this.customerBindings = client?.customerBindings ?? []
        this.need_check = client?.need_check ?? false
        this.invited_customer_id = client?.invited_customer_id ?? null
        this.distribution_id = client?.distribution_id ?? null
        this.distribution_customer_id = client?.distribution_customer_id ?? null
        this.hasPartner = !!client?.distribution_id ?? false
        this.email = client?.email ?? null
        this.company_description = client?.company_description ?? ''
        this.is_vip = client?.is_vip ?? false
        this.phone2 = client?.phone2 ?? ''
        this.phone_from_call = client?.phone_from_call ?? ''
        this.is_need_act = client?.is_need_act ?? false
        this.is_phone_robot = client?.is_phone_robot ?? false
        if (client?.referral?.id) {
            const referral = client.referral 
            this.referral = referral.last_name + referral.first_name + referral.patronymic
        }
    }
}


const emptyRuleForExternalErrors = { minLength: helpers.withMessage('Ошибка', minLength(0)) }
// Если поля не будет или оно будет пустое в объекте правил валидатора, то дальнейшая обработка ошибок невозможна(внутренняя ошибка vuelidate)
export const ClientFieldsWithEmptyValidation = {
    fixed_water_price: emptyRuleForExternalErrors, 
    company_inn: emptyRuleForExternalErrors,
    contract_number: emptyRuleForExternalErrors, 
    company_description: emptyRuleForExternalErrors,
    invited_customer_id: emptyRuleForExternalErrors, 
}
