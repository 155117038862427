<script setup>
import { defineProps, defineEmits } from 'vue'
import { getFormattedPhoneNumber, addToClipboard } from '@/lib/utils'

const props = defineProps({
    clients: { type: Array, required: false, default: () => [] },
    showButtons: { type: Boolean, required: false, default: () => false },
    referralSearch: { type: Boolean, required: false, default: () => false }
})

const emits = defineEmits(['search', 'add-client', 'choose'])

const getFullNameByClient = (client) => {
    return `${client.last_name ?? ''} ${client.first_name ?? ''} ${client.patronymic ?? ''}`
}


const filterClientAddressesByPhone = (client) => {
    return client.addresses.filter(address => address.phone && client.phone != address.phone)
}
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">
            <h3 class="card-title"><i class="fas fa-info-circle mr-2"></i>{{ clients.length ? `Клиенты
                (${props.clients.length})` : 'Клиентов не найдено, уточните поиск' }}</h3>
        </div>
        <div class="card-body" v-if="props.clients.length || showButtons">
            <div class="form-row pb-2" v-if="showButtons">
                <div class="form-group mb-0 mt-2 col-md-auto col-xl-auto">
                    <button type="submit" class="btn btn-primary waves-effect waves-light mr-2"
                        @click="emits('search')">Найти совпадения</button>
                    <button type="submit" class="btn btn-primary waves-effect waves-light"
                        @click="emits('add-client')">Добавить нового
                        клиента</button>
                </div>
            </div>
            <table class="table" v-if="props.clients.length">
                <thead>
                    <tr>
                        <th class="col-auto">#</th>
                        <th class="col-4">Компания, ФИО, Телефон</th>
                        <th class="col-auto">Адрес</th>
                        <th v-show="!referralSearch" class="col-2">&nbsp;</th>
                        <th v-show="referralSearch" class="col-2">Выберите&nbsp;клиента</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(client, idx) in props.clients" :key="client.id">
                        <td class="col-auto">{{ idx + 1 }}.</td>
                        <td class="col-4">
                            <b @click="addToClipboard(client.phone, 'Телефон скопирован!')" style="cursor: pointer">{{
                                getFormattedPhoneNumber(client.phone) }}</b><br v-show="client.phone" />
                            <p v-show="filterClientAddressesByPhone(client).length" class="mb-0 font-small-1">
                                <span v-for="(address, idx) in filterClientAddressesByPhone(client)"
                                    :key="address.id">
                                    {{ getFormattedPhoneNumber(address.phone) }}<span v-show="idx != filterClientAddressesByPhone(client).length - 1">, </span></span>
                            </p>
                            {{ client?.type?.shortName }}<br v-show="client?.type?.shortName" />
                            <template v-if="client.company">
                                <b>{{ client.company }}</b><br />
                            </template>
                            {{ getFullNameByClient(client) }}
                            <p v-show="client?.type?.name" class="text-secondary font-small-1">{{ client?.type?.name }}
                            </p>
                        </td>
                        <td class="col-auto" v-if="!referralSearch">
                            <div class="row" v-for="address in client.addresses" :key="address.id">
                                <router-link
                                    :to="{ path: '/order', query: { customerId: client.id, addressId: address.id } }"
                                    v-show="address.is_primary">
                                    <i class="fas fa-plus mr-2 address__icon"></i><b>{{ address.address_source_string
                                        }}</b>
                                </router-link>
                                <router-link
                                    :to="{ path: '/order', query: { customerId: client.id, addressId: address.id } }"
                                    v-show="!address.is_primary">
                                    <i class="fas fa-plus mr-2 address__icon"></i>{{ address.address_source_string }}
                                </router-link>
                            </div>
                        </td>
                        <td v-else>
                            <div class="row" v-for="address in client.addresses" :key="address.id">
                                <p v-show="address.is_primary">
                                    <b>{{ address.address_source_string
                                        }}</b>
                                </p>
                                <p v-show="!address.is_primary">
                                    {{ address.address_source_string }}
                                </p>
                            </div>
                        </td>
                        <td v-show="!referralSearch" class="col-2">
                            <div class="row">
                                <div class="col-md-12">
                                    <a href="#">
                                        <i class="fas fa-info-circle mr-2"></i>Статистика
                                    </a>
                                </div>
                                <div class="col-md-12">
                                    <router-link :to="`/customers/${client.id}`">
                                        <i class="fas fa-id-card-alt mr-2"></i>Просмотр
                                    </router-link>
                                </div>
                                <div class="col-md-12">
                                    <router-link :to="{ path: '/order', query: { customerId: client.id } }">
                                        <i class="fas fa-plus mr-2"></i>Добавить заказ
                                    </router-link>
                                </div>
                            </div>
                        </td>
                        <td v-show="referralSearch" class="col-2">
                            <button @click="emits('choose', client)"
                                class="form-control form-control-sm btn-sm btn-primary waves-effect waves-light mt-0 my-auto">Выбрать</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped lang="scss">
.address__icon {
    color: #17a2b8;
}
</style>