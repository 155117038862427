<script setup>
import {useUserStore} from "@/store";
import {computed, ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useRoute} from "vue-router";

const route = useRoute();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);

import {useToast} from 'vue-toastification';
const toast = useToast();

const tariff = ref({
  additionalTariffOptions: {},
  ordersCountOptions: [],
});
const showEditForm = ref(false);

const getTariff = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/additional-tariff/' + route.params.id).then(function (response) {
    tariff.value = response.data.data
    showEditForm.value = true
  }).catch(function (error) {
    showErrors(error)
  });
}

const updateTariff = () => {
  api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/additional-tariff-aggregate/', tariff.value).then(function () {
    toast.success("Данные обновлены")
  }).catch(function (error) {
    showErrors(error)
  });
}

const addCondition = () => {
  tariff.value.ordersCountOptions.push({
    minOrdersCount: null,
    maxOrdersCount: null,
    additionalFee: null,
  });
}

const removeCondition = (index) => {
  tariff.value.ordersCountOptions.splice(index, 1);
}

getTariff();
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Дополнительный тариф</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата</router-link>
              </li>
              <li class="breadcrumb-item active">Водители и экспедиторы</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content" v-if="showEditForm">
      <div class="container-fluid">
        <div class="card">
          <div class="card-body">
            <div class="tab-content">
              <div class="row">
                <div class="col-md-6">
                  <label class="col-form-label">Название тарифа</label>
                  <input type="text" class="form-control" autocomplete="off"
                         v-model="tariff.additionalTariff.name">
                </div>
                <div class="col-md-6">
                  <label class="col-form-label">Ставка за рабочий день</label>
                  <input type="number" class="form-control" autocomplete="off"
                         v-model="tariff.additionalTariff.salaryFee">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="tab-content">
              <button class="btn btn-primary" @click="addCondition">Добавить условие</button>
              <div class="row" v-for="(item, index) in tariff.ordersCountOptions" :key="item.id">
                <div class="col-md-3">
                  <label class="col-form-label">Заказов от</label>
                  <input type="number" class="form-control" autocomplete="off" v-model="item.minOrdersCount">
                </div>
                <div class="col-md-3">
                  <label class="col-form-label">Заказов до</label>
                  <input type="number" class="form-control" autocomplete="off" v-model="item.maxOrdersCount">
                </div>
                <div class="col-md-5">
                  <label class="col-form-label">Ставка</label>
                  <input type="number" class="form-control" autocomplete="off" v-model="item.additionalFee">
                </div>
                <div class="col-md-1">
                  <label class="col-form-label">Удалить</label>
                  <br>
                  <button class="btn btn-danger" @click="removeCondition(index)">X</button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" @click="updateTariff">Сохранить</button>
          </div>
        </div>

      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>