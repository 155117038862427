<script setup>


import {useUserStore} from "@/store";
import {computed} from "vue";
import SalaryMenu from "@/components/Salary/SalaryMenu.vue";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Тарифы</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата</router-link>
              </li>
              <li class="breadcrumb-item active">Водители и экспедиторы</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <SalaryMenu></SalaryMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-6">
            <router-link :to="{ name: 'DriverBaseTariffPage' }" class="small-box bg-info">
              <div class="inner">
                <h3>Тарифы</h3>
                <p>для водителей</p>
              </div>
              <div class="icon">
                <i class="ion ion-bag"></i>
              </div>
            </router-link>
          </div>
          <div class="col-lg-3 col-6">
            <router-link :to="{ name: 'ForwarderBaseTariffPage' }" class="small-box bg-info">
              <div class="inner">
                <h3>Тарифы</h3>
                <p>для экспедиторов</p>
              </div>
              <div class="icon">
                <i class="ion ion-bag"></i>
              </div>
            </router-link>
          </div>
          <div class="col-lg-3 col-6">
            <router-link :to="{ name: 'AdditionalTariffsPage' }" class="small-box bg-info">
              <div class="inner">
                <h3>Доп Тарифы</h3>
                <p>&nbsp;</p>
              </div>
              <div class="icon">
                <i class="ion ion-bag"></i>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>