<script setup>
import { defineProps, computed, ref, defineEmits } from "vue"
import { getFormattedPhoneNumber, addToClipboard } from '@/lib/utils'
import { getColorByStatusId } from '@/lib/auxiliary/order.js'
import UISwitcher from "../UI/UISwitcher.vue"
import { splitNumberByDigit } from "@/lib/utils";
import moment from "moment"
import UIModal from "../UI/UIModal.vue";
import { vTooltip } from 'floating-vue'
import UIInput from "../UI/UIInput.vue";
import UIButton from "../UI/UIButton.vue";
import { showErrors } from "@/common/showErrors";
import api from "@/common/api";
import { useToast } from "vue-toastification";

const props = defineProps({
    orders: { type: Array, required: true, default: () => [] },
    ordersInfo: { type: Object, required: true, default: () => ({}) },
    orderDuplicates: { type: Array, required: false, default: () => []}
})
const emits = defineEmits(['update-order'])
const toast = useToast()

const lastProductInfoIndex = computed(() => {
    return Object.keys(props.ordersInfo.product_info.products).length - 1
})

const orderIdsWithOpenedPhone = ref([])
const showPhone = (orderId) => {
    orderIdsWithOpenedPhone.value.push(orderId)
}
const statusByDriverNotShow = 1

const showLogsModal = ref(false)
const currentLogs = ref([])
const currentOrderNumberForLogs = ref(0)

const getLogsHTMLByOrder = (order) => {
    let result = ''
    Object.values(order.logs).forEach((log) => {
        result += `<p>${log.date} | ${log.user?.name ?? 'Без пользователя'} | ${log?.new_status?.name ?? 'Неизвестный статус'}</p>`
    })
    result = '<div>' + result + '</div>'
    return result
}

const showSendPayLinkModal = ref(false)
const phoneForPaymentLink = ref('')
const orderIdForPaymentLink = ref('')
const isLoadingSendPayLinkBtn = ref(false)
const sendPaymentLink = async () => {
    try {
        isLoadingSendPayLinkBtn.value = true
        const { data } = await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/order/send-payment-link', {
            order_id: orderIdForPaymentLink.value,
            phone: phoneForPaymentLink.value
        })
        if (data.data.status) {
            toast.success(data.data.log)
        } else {
            toast.error(data.data.log)
        }
    } catch (error) {
        showErrors(error)
    } finally {
        showSendPayLinkModal.value = false
        phoneForPaymentLink.value = ''
        isLoadingSendPayLinkBtn.value = false
    }
}
const openSendPayLinkModal = (orderId) => {
    phoneForPaymentLink.value = ''
    showSendPayLinkModal.value = true
    orderIdForPaymentLink.value = orderId
}

const getOrderStyleByOrder = (order) => {
    if (props.orderDuplicates.includes(order.id)) {
        return 'background-color: #ff00001a;'
    }
    const ORDER_STATUS_ID_NEW = 1
    if (order.status_id.id === ORDER_STATUS_ID_NEW) {
        return 'background-color: #46a9ff2a;'
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-info-circle mr-2"></i> Заказы</div>
        <div class="card-footer text-muted p-1">
            {{ `Заказов: ${props.ordersInfo?.orders_count ?? 0} Клиентов: ${props.ordersInfo?.clients_count ?? 0}. Бутылей:
            ${props.ordersInfo?.product_info?.total_bottles ?? 0}`}}
            <span v-if="Object.keys(props.ordersInfo?.product_info?.products || {})?.length">
                (<span v-for="(productValue, productName, index) in props.ordersInfo?.product_info?.products || []"
                    :key="productName">
                    {{ `${productName}: ${productValue}` }}<span v-show="lastProductInfoIndex !== index">, </span>
                </span>). 
            </span>
            {{ `Оборудование: покупают ${props.ordersInfo.product_info?.equipment_count ?? 0}, берут в аренду:
            ${props.ordersInfo.product_info?.pledge_count ?? 0}. Итого: ${props.ordersInfo.product_info?.total_amount ??
            0} руб.`}}
        </div>
        <div class="card-body p-0">
            <div class="row-order mb-3 p-2" :style="getOrderStyleByOrder(order)" v-for="(order) in props.orders" :key="order.id">
                <div class="row">
                    <div class="col-6">
                        <div class="alert alert-danger p-1 mb-1" v-if="order.customer.duty">
                            Задолженность <b>{{ order.customer.duty + ' ₽' }}</b>
                        </div>
                        <p v-if="order.customer.companyName" class="mt-0"><b>{{ order.customer.companyName }}</b></p>
                        <p :class="{'mt-0': !order.customer.companyName}">{{ order.customer.fullName }}</p>
                        <p><u v-if="!orderIdsWithOpenedPhone.includes(order.id)" @click="showPhone(order.id)"><b
                                    style="cursor: pointer">показать телефон</b></u>
                            <u v-else>
                                <b v-for="(phone, phoneIdx) in order.customer.phones" :key="phone"
                                    @click="addToClipboard(phone, 'Телефон скопирован!')" style="cursor: pointer">{{
                                    getFormattedPhoneNumber(phone) }}<span v-if="phoneIdx !== order.customer.phones.length - 1">, </span></b>
                            </u>
                        </p>
                        <p>{{ order.address?.addressString }}</p>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-small mb-0">
                                    <thead>
                                        <tr>
                                            <th class="col-6">название</th>
                                            <th class="col-auto">&nbsp;</th>
                                            <th class="col-auto">кол</th>
                                            <th class="col-auto text-right">цена&nbsp;за&nbsp;ед</th>
                                            <th class="col-auto">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="orderItem in order.items" :key="orderItem.id">
                                            <td>{{ orderItem.product.name }}</td>
                                            <td>{{ orderItem.productType.name }}</td>
                                            <td>{{ orderItem.quantity }}</td>
                                            <td class="text-right">{{  splitNumberByDigit(orderItem.currentCost) + '&nbsp;₽' }}</td>
                                            <td>{{ orderItem.paymentType.publicName }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pb-0" colspan="3">
                                              <div class="form-group mb-2 col-md-auto col-xl-auto form-check-inline mr-0 mb-0 align-self-end">
                                                <UISwitcher class="mr-2" label="Нужен чек" :modelValue="order.need_check"
                                                            @update:modelValue="emits('update-order', { orderId: order.id, fieldName: 'need_check', newValue: $event })" />
                                                |
                                                <UISwitcher class="mr-2 ml-2" label="Заказ проверен" :modelValue="order.is_checked_by_manager"
                                                            @update:modelValue="emits('update-order', { orderId: order.id, fieldName: 'is_checked_by_manager', newValue: $event })" />
                                                |
                                                <UISwitcher class="mr-2 ml-2" label="Надо позвонить" :modelValue="order.need_call"
                                                            @update:modelValue="emits('update-order', { orderId: order.id, fieldName: 'need_call', newValue: $event })" />
                                              </div>
                                            </td>
                                            <td class="pb-0 text-right"><b>{{ splitNumberByDigit(order.total_order_amount) + '&nbsp;₽' }}</b></td>
                                            <td class="pb-0"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 pl-3">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <small>
                            № <b>{{order.order_number}}</b>, ID <b>{{ order.id }}</b> |
                            {{order.order_comment}}<span v-show="order.order_comment"> |
                            </span><b class="text-danger">{{order.address.managerComment}}</b><span v-show="order.address.managerComment"> |
                        </span>
                        </small>
                    </div>
                </div>
                <div class="row p-1 pl-2 align-items-center">
                    <div class="form-control-sm mr-1 p-0 mt-1" v-tooltip="{ content: getLogsHTMLByOrder(order), html: true }">
                        <i class="far fa-list-alt h3 mb-0"></i>
                    </div>
                    <div 
                        v-if="order.pay_card"
                        class="col-auto py-1 px-2 mb-0 mr-1" 
                        :style="`border-width: 2px; border-color: #008038; color: #008038; height: min-content; border-radius: 8px; border-style: solid;`"
                        v-tooltip="'Оплачено картой'"
                    >
                        <span><i class="far fa-credit-card" /></span>
                    </div>
                    
                    <div class="col-auto alert p-1 mb-0" :style="`border-width: 2px; border-color: ${getColorByStatusId(order.status_id.id)}; color: ${getColorByStatusId(order.status_id.id)}; height: min-content; border-radius: 8px;`">
                        <b>{{ order.status_id.name }}
                        <span v-if="statusByDriverNotShow !== order.status_by_driver.id"> / {{ order.status_by_driver.name }} водителем</span></b>
                    </div>

                    <div class="col-auto">
                        {{ moment(order.delivery_date).format('DD.MM.YYYY') }} {{order.delivery_time?.name ?? 'Нет информации'}}<br />
                        {{ `${order.slot.start.substr(0, 5)} - ${order.slot.end.substr(0, 5)}` }}
                    </div>
                    <div class="col-auto">
                        {{ order.delivery_request?.warehouse?.name ?? '' }}<br />
                        Источник: {{ order.order_source?.name ?? '' }}
                    </div>
                    <div class="col-auto">
                        <template v-if="order.delivery_request?.staffList?.driver?.id">Водитель: {{ (order.delivery_request?.staffList?.driver?.firstName ?? '') + ' ' + (order.delivery_request?.staffList?.driver?.lastName ?? '') }} 
                        (<span v-for="(phone, idx) in order.delivery_request?.staffList?.driver?.phones" :key="phone" @click="addToClipboard(phone, 'Телефон скопирован!')">
                            <b style="cursor: pointer">{{ getFormattedPhoneNumber(phone) }}</b><span v-show="idx !== order.delivery_request.staffList.driver.phones.length - 1">, </span>
                        </span>)<br />
                        </template>
                        <template v-if="order.delivery_request?.staffList?.forfarders?.length">
                            Экспедитор: 
                            <span v-for="forfarder in order.delivery_request?.staffList?.forfarders" :key="forfarder.id">
                                {{ forfarder.firstName ?? '' + ' ' + forfarder.lastName ?? '' }}
                                (<span v-for="(phone, idx) in forfarder.phones" :key="phone" @click="addToClipboard(phone, 'Телефон скопирован!')">
                                    <b style="cursor: pointer" >{{ getFormattedPhoneNumber(phone) }}</b><span v-show="idx !== forfarder.phones.length - 1">, </span>
                                </span>)
                            </span>
                        </template>
                    </div>
                    <i class="fas fa-sms ml-auto mr-3 mt-auto mb-1 h4" style="cursor: pointer;" @click="openSendPayLinkModal(order.id)" v-tooltip="'Отправить ссылку'"/>
                    <router-link class="mr-2 mt-auto text-muted h6" :to="{path: '/order', query: { orderId: order.id }}"><i class="fa fa-pen mr-1 font-small-1"></i>Редактировать</router-link>
                </div>
            </div>
        </div>

        <UIModal v-model:show="showLogsModal" :title="`Логи по заказу №${currentOrderNumberForLogs}`">
            <div class="overflow-auto" style="max-height: 500px;">
                <p v-for="log in currentLogs" :key="log.date" class="my-1">{{ `${log.date} | ${log.user?.name ?? 'Без пользователя'} | ${log?.new_status?.name ?? 'Неизвестный статус'}` }}</p>
            </div>
        </UIModal>
        <UIModal v-model:show="showSendPayLinkModal" title="Ссылка на отправку">
            <div class="form-row">
                <UIInput label="Телефон" v-model="phoneForPaymentLink" mask="+7 (000) 000-00-00" class="form-group mb-0 col-md-8 col-xl-8" />
                <UIButton class="form-control form-control-sm btn-sm btn-primary waves-effect waves-light mt-0 col-md-4 col-xl-4 mt-auto"
                    :isLoading="isLoadingSendPayLinkBtn" :disabled="isLoadingSendPayLinkBtn"
                    @click="sendPaymentLink">Найти
                </UIButton>
            </div>
        </UIModal>
    </div>
</template>
