<template>
    <div class="ui-input form-group" :class="props.class ? props.class : 'col-md-2 col-xl-2'" >
        <label v-if="props.label" :for="UIInput?.id" class="col-form-label" :class="{ 'pb-1': props.sm }">{{ props.label }}</label>
        <input v-if="props.mask" v-uid v-tooltip="errors.length ? errors[0].$message : null" ref="UIInput" type="text" class="form-control form-control-sm"
        :class="props.errors.length ? 'has-error ' + inputClass : inputClass"
            :value="props.modelValue" v-imask="{
                mask: props.mask
            }" @focus="emits('focus')" :readonly="props.readonly" @input="inputHandler" :maxlength="maxlength">
        <input v-if="!props.mask" v-uid v-tooltip="errors.length ? errors[0].$message : null" ref="UIInput" type="text" class="form-control form-control-sm" 
            :class="props.errors.length ? 'has-error ' + inputClass : inputClass" @focus="emits('focus')"
            :value="props.modelValue" :readonly="props.readonly" @input="inputHandler" :maxlength="maxlength">
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, nextTick } from 'vue';
import { vTooltip } from 'floating-vue'

const props = defineProps({
    modelValue: { type: [String, Number, null], required: true },
    label: { type: String, required: false, default: () => '' },
    minLength: { type: Number, required: false, default: () => 0 },
    required: { type: Boolean, required: false, default: () => false },
    errors: { type: Array, required: false, default: () => [] },
    validate: { type: Boolean, required: false, default: () => false },
    maxlength: { type: [Number, String], required: false, default: () => undefined },
    sm: { type: Boolean, required: false, default: () => true },
    class: { type: [String, null], required: false, default: () => null },
    mask: { type: [String, null, Function, RegExp], required: false, default: () => null },
    readonly: { type: Boolean, required:  false, default: () => false },
    maxValue: { type: [Number, null], required: false, default: () => null},
    inputClass: { type: String, required: false, default: () => ''},
    onlyNumber: { type: Boolean, required: false, default: () => false},
    minusNumbers: { type: Boolean, required: false, default: () => false}
})

const emits = defineEmits(['update:modelValue', 'new-error', 'focus'])

const UIInput = ref(null)


const inputHandler = (event) => {
    const newValue = event.target.value
    const regularForNumbers = props.minusNumbers ? /^-?([0-9]+(\.[0-9]*)?|\.[0-9]+)?$/ : /^[0-9]+(\.)?[0-9]*$/
    if (props.onlyNumber && !regularForNumbers.test(newValue) && newValue) {
        UIInput.value.value = props.modelValue
        return
    }
    if (typeof props.maxValue === 'number' && +newValue > props.maxValue) {
        if (+props.modelValue !== +props.maxValue) {
            emits('update:modelValue', props.maxValue)
        }
        UIInput.value.value = props.maxValue
    } else {
        nextTick(() => {
            if (props.mask) {
                UIInput.value.maskRef.value = newValue
                emits('update:modelValue', UIInput.value.maskRef?.value)
            } else {
        emits('update:modelValue', newValue)
            }
        })
    }
}
</script>

<style scoped>
.ui-input {
    position: relative;
    .has-error{ 
        border-color: red;
        background-color: #ff00001a;
    }
}

.ui-input__error {
    position: absolute;
    margin: 0px;
    bottom: -8px;
    font-size: 12px;
    color: red;
}
.rounded-end-none {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.rounded-start-none {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
</style>