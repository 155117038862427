<script setup>
import { useUserStore } from "@/store";
import { computed, onBeforeMount, ref } from "vue";
import api from "@/common/api";
import DoublesCustomerTables from "@/components/Customers/Tables/DoublesCustomerTables.vue"
import CustomerDoublesFilters from "@/components/Customers/DoublesFilters.vue"
import { showErrors } from "@/common/showErrors";
import { minLength, helpers, or } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getUnMaskedPhone } from "@/lib/utils";
import { useRoute } from "vue-router";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const isLoading = ref(false)
const route = useRoute()

const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function () {
  });
}

const getFormattedFilters = () => {
  const clientSearchFormCopy = structuredClone(clientSearchForm.value)
  if (searchBy.value === 'phone') {
    clientSearchFormCopy.phone = getUnMaskedPhone(clientSearchFormCopy.phone)
  }
  for (const key in clientSearchFormCopy) {
    if (!clientSearchFormCopy[key]) {
      delete clientSearchFormCopy[key]
    }
  }
  return clientSearchFormCopy
}

const fetchClientsAsync = async () => {
  try {
    clientFormValidator.value.$validate()
    if (clientFormValidator.value.$errors.length) {
      throw { clientValidateError: 'Проверьте заполнение полей' }
    }
    isLoading.value = true
    const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customers/unprocessed-duplicates', { params: getFormattedFilters() })
    clients.value = data.data;
  } catch (error) {
    showErrors(error)
  } finally {
    isLoading.value = false
  }
}

const searchBy = ref('phone')
const clientSearchForm = ref({
  phone: route?.query?.phone ?? '',
  period_days: route?.query?.period_days ?? null
})
const filterErrors = ref([])
const minPeriodLength = 1
const minPhoneLength = 2 //75555442112
const clientFormValidationRules = {
  phone: { minLength: helpers.withMessage('Введите полный номер телефона!', or(minLength(minPhoneLength), searchBy.value !== 'phone')) },
  period_days: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minPeriodLength), searchBy.value !== 'last_days')) }
}
const clientFormValidator = useVuelidate(clientFormValidationRules, clientSearchForm)

const clients = ref([])

onBeforeMount(() => {
  checkAuth();
});

if (route?.query?.phone || route?.query?.period_days) {
  fetchClientsAsync()
}

const pageBreadcrumbs = ref([
  {
    title: 'Клиенты',
    href: '/customers/search'
  },
  {
    title: 'Дубли',
    href: '/customers/double'
  },
])
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <UILoader :loading="isLoading" />

    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Клиенты</h1>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <UIBreadCrumbs :items="pageBreadcrumbs" />
        <div class="row">
          <div class="container-xl ml-0">
            <CustomerDoublesFilters v-model="clientSearchForm" v-model:searchBy="searchBy"
              :isLoadingSearchBtn="isLoading" :validator="clientFormValidator" type="customer" removeDubles
              @search="fetchClientsAsync" @new-error="filterErrors = $event" />
            <DoublesCustomerTables :clients="clients" @choose-double="fetchClientsAsync()" />
          </div>
        </div>
      </div>
    </section>
  </div>

</template>
