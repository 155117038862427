export default class BaseProduct {
    product_id = null
    qty = 2
    price = 0
    payment_type_id = 7
    sell_type_id = 1
    rent_months = null
    isFixedPrice = false
    type_id = null

    constructor(product) {
        this.product_id = product?.product_id ?? null
        this.qty = product?.qty ?? 1
        this.price = product?.price ?? null
        this.payment_type_id = product?.payment_type_id ?? 4
        this.sell_type_id = product?.sell_type_id ?? 1
        this.rent_months = product?.rent_months ?? 0
        this.isFixedPrice = product?.isFixedPrice ?? 0
        this.type_id = product?.type_id ?? null
    }
}