<script setup>
import { useUserStore } from "@/store";
import { computed, onBeforeMount, ref } from "vue";
import api from "@/common/api";
import CustomerDoublesFilters from "@/components/Customers/DoublesFilters.vue"
import { showErrors } from "@/common/showErrors";
import { minLength, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getUnMaskedPhone } from "@/lib/utils";
import { useRoute } from "vue-router";
import DoublesAddressTable from "@/components/Customers/Tables/DoublesAddressTable.vue";
const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const isLoading = ref(false)
const route = useRoute()

const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function () {
  });
}

const getFormattedFilters = () => {
  const clientSearchFormCopy = structuredClone(clientSearchForm.value)
  clientSearchFormCopy.phone = getUnMaskedPhone(clientSearchFormCopy.phone)
  return clientSearchFormCopy
}

const fetchAddressesAsync = async () => {
  try {
    clientFormValidator.value.$validate()
    if (clientFormValidator.value.$errors.length) {
      throw { clientValidateError: 'Проверьте заполнение полей' }
    }
    isLoading.value = true
    const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/addresses/unprocessed-duplicates', { params: getFormattedFilters() })
    addresses.value = data.data;
  } catch (error) {
    showErrors(error)
  } finally {
    isLoading.value = false
  }
}

const clientSearchForm = ref({
  phone: route?.query?.phone ?? ''
})
const filterErrors = ref([])
const minPhoneLength = 2 //75555442112
const clientFormValidationRules = {
  phone: { minLength: helpers.withMessage('Введите полный номер телефона!', minLength(minPhoneLength)) }
}
const clientFormValidator = useVuelidate(clientFormValidationRules, clientSearchForm)

const addresses = ref([])

onBeforeMount(() => {
  checkAuth();
});

if (route?.query?.phone || route?.query?.period_days) {
  fetchAddressesAsync()
}

const pageBreadcrumbs = ref([
  {
    title: 'Клиенты',
    href: '/customers/search'
  },
  {
    title: 'Дубли адресов',
    href: '/address/double'
  },
])
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <UILoader :loading="isLoading" />
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Адреса</h1>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <UIBreadCrumbs :items="pageBreadcrumbs" />
        <div class="row">
          <div class="container-xl ml-0">
            <CustomerDoublesFilters v-model="clientSearchForm" :isLoadingSearchBtn="isLoading"
              :validator="clientFormValidator" removeDubles type="address" @search="fetchAddressesAsync"
              @new-error="filterErrors = $event" />
            <DoublesAddressTable :addresses="addresses" @choose-double="fetchAddressesAsync" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
