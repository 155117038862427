vue
<script setup> import {useUserStore} from "@/store";
import {computed, ref} from "vue";

import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {VueSpinnerClock} from "vue3-spinners";
import {parse} from "date-fns";
import format from "date-fns/format";
import axios from "axios";
import FileSaver from "file-saver";
import SalaryMenu from "@/components/Salary/SalaryMenu.vue";
import {useToast} from 'vue-toastification';
const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const waterCompanyId = ref(1);
const periodList = ref([])

const getPeriodList = async (waterCompanyId) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/periods/?waterCompanyId=' + waterCompanyId).then(function (response) {
    periodList.value = response.data
  }).catch(function (error) {
    showErrors(error)
    return []
  })
}
const formatDate = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(date, 'dd.MM.yyyy');
};
const downloadFile = async (id) => {
  await axios.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/statement/' + id + '/excel',
      {responseType: 'blob', headers: {'authorization': 'Bearer ' + localStorage.access_token},})
      .then(response => {
        FileSaver.saveAs(response.data, 'Зарплатная ведомость.xlsx');
      }).catch(console.error)

}


const changePeriodStatus = async (period) =>{
  try {
    await api.patch(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/period/status', {
      salaryPeriodId:period.id,
      statusId: period.statusId
    })
    toast.success('Статус успешно изменён')
  }catch (error){
    showErrors(error)
  }
}

const loading = ref(false);
getPeriodList(waterCompanyId.value);
</script>
<template>
  <div class="content-wrapper" v-if="isAuthenticated"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Периоды</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата</router-link>
              </li>
              <li class="breadcrumb-item active">Водители и экспедиторы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <SalaryMenu></SalaryMenu>
      </div>
    </section>
    <section class="content">
      <div class="vuespinner-container" v-if="loading">
        <div class="custom-spinner">
          <VueSpinnerClock size="125" color="darkslategray"/>
          <p class="spinner-text">Добавляем задачу в очередь...</p>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-title">
                <div class="form-group col-md-4"><label for="wcid" class="col-form-label">Компания</label>
                  <select id="wcid" name="wcid" class="form-control" autocomplete="off" v-model="waterCompanyId"
                          @change="getPeriodList(waterCompanyId)">
                    <option :value=1 selected="">Хваловские Воды (С-Пб)</option>
                    <option :value=2>Хваловские Воды (Мск)</option>
                  </select>
                </div>
              </div>
              <div class="card-header"><h3 class="card-title">Список периодов</h3></div>
              <div class="card-body">
                <div class="form-row">
                  <table class="table table-striped table-sm">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>Название</th>
                      <th>Период</th>
                      <th>Статус</th>
                      <th>Скачать ведомость</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in periodList.data" :key="index">
                      <td>{{ item.id }}</td>
                      <td>
                        <router-link :to="{ name: 'PeriodPage', params: { id: item.id }}">
                          {{ item.title }}
                        </router-link>
                      </td>
                      <td>с {{ formatDate(item.dateFrom) }} по {{ formatDate(item.dateTo) }}</td>
                      <td class="font-weight-bold">
                        <select class="form-control form-control-sm" v-model="item.statusId" @change="changePeriodStatus(item)">
                          <option value="1">открыт</option>
                          <option value="2">закрыт</option>
                          <option value="3">обрабатывается</option>
                          <option value="4">ошибка</option>
                        </select>
                      </td>
                      <td>
                        <button @click="downloadFile(item.id)" class="btn-success">
                          Скачать файл
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
<style scoped> .custom-spinner {
  z-index: 1000; /* Установите нужное значение z-index */
  position: fixed; /* Фиксированное позиционирование */
  top: 50%; /* Центрирование по вертикали */
  left: 50%; /* Центрирование по горизонтали */
  transform: translate(-50%, -50%); /* Центрирование по оси */
  background: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  width: 100vw; /* Полная ширина экрана */
  height: 100vh; /* Полная высота экрана */
  display: flex;
  flex-direction: column; /* Вертикальное расположение элементов */
  justify-content: center;
  align-items: center;
}

.spinner-text {
  margin-top: 20px; /* Отступ сверху */
  font-size: 1.5em; /* Размер шрифта */
  color: darkslategray; /* Цвет текста */
} </style>