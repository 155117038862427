<script setup>
import BaseOrderFilter from '@/lib/models/BaseOrdersFilters';
import OrdersFiltersComponent from './OrdersFilters.vue';
import { vTooltip } from 'floating-vue'
import { ref } from 'vue'
import { showErrors } from '@/common/showErrors';
import api from '@/common/api';
import moment from 'moment';
import FullOrdersTable from './FullOrdersTable.vue';
import UILoader from '../UI/UILoader.vue';
import { useRoute } from "vue-router";
import debounce from 'lodash/debounce';
import { patchOrder } from '@/lib/api/order';
import { getUnMaskedPhone } from '@/lib/utils';

const ordersFilters = ref(new BaseOrderFilter())
const orders = ref([])
const ordersInfo = ref({})
const isPageLoading = ref(false)
// setInterval(() => isPageLoading.value = !isPageLoading.value, 2000)
const route = useRoute()

const searchByValue = ref('')
const searchBy = ref('all')

const getFormattedOrderFilters = (orderIds) => {
    const orderFiltersCopy = structuredClone(ordersFilters.value)
    orderFiltersCopy.date_from = moment(orderFiltersCopy.date_from ?? new Date()).format('YYYY-MM-DD')
    orderFiltersCopy.date_to = moment(orderFiltersCopy.date_to ?? new Date()).format('YYYY-MM-DD')
    const splitedSearch = (orderFiltersCopy?.search || ' ').split(' ')
    if (searchBy.value === 'phone' && searchByValue.value && getUnMaskedPhone(searchByValue.value)) {
        orderFiltersCopy.search = `phone, ${getUnMaskedPhone(searchByValue.value)}`
    }
    if (splitedSearch.length === 2 && splitedSearch[1] === '') {
        orderFiltersCopy.search = null
    }
    if (orderIds?.length) {
        return { order_ids: orderIds, water_company_id: route.query.water_company_id ?? orderFiltersCopy.water_company_id }
    }
    return orderFiltersCopy
}

const orderErrorsCount = ref(0)
const ordersWithErrors = ref([])
const COUNT_DAYS_ORDER_ERRORS = 3
const orderErrorsDateForm = moment(new Date())
const orderErrorsDateTo = moment(new Date().setDate(new Date().getDate() + COUNT_DAYS_ORDER_ERRORS))
const fetchOrderErrors = async () => {
    const date_from = orderErrorsDateForm.format('YYYY-MM-DD')
    const date_to = orderErrorsDateTo.format('YYYY-MM-DD')
    const requestBody = {
        "water_company_id": 1,
        date_to,
        date_from
    }
    const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/orders/errors`, { params: requestBody })
    orderErrorsCount.value = data.data.count
    ordersWithErrors.value = data.data.ids
}
const fetchOrdersWithErrors = () => {
    fetchOrders(ordersWithErrors.value)
}
fetchOrderErrors()

const fetchOrders = async (orderIds) => {
    try {
        isPageLoading.value = true
        orders.value = []
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/orders`, { params: getFormattedOrderFilters(orderIds) })
        orders.value = data.data.orders
        ordersInfo.value = data.data.info
        const currentOrder = data.data.orders?.[0] ?? null
        if (orderIds?.length && currentOrder?.order_number) {
            searchBy.value = 'order_number'
            searchByValue.value = currentOrder.order_number
            ordersFilters.value.search = 'order_number, ' + currentOrder.order_number
            ordersFilters.value.date_from = new Date(currentOrder.delivery_date)
            ordersFilters.value.date_to = new Date(currentOrder.delivery_date)
        }
    } catch (error) {
        showErrors(error)
    } finally {
        isPageLoading.value = false
    }
}


const patchOrderDebounce = debounce(patchOrder, 500)
const updateOrder = async (orderInfo = { orderId: 0, fieldName: '', newValue: '' }) => {
    if (orderInfo.orderId) {
        const changedOrder = orders.value.find((order) => order.id === orderInfo.orderId)
        const reserveValue = changedOrder[orderInfo.fieldName]
        try {
            changedOrder[orderInfo.fieldName] = orderInfo.newValue

            const changedValue = { id: changedOrder.id }
            changedValue[orderInfo.fieldName] = orderInfo.newValue

            await patchOrderDebounce(changedValue)
        } catch (error) {
            changedOrder[orderInfo.fieldName] = reserveValue
            showErrors(error)
        }
    }
}

const showDuplicate = () => {
    orders.value = orders.value.filter((order) => ordersInfo.value.orders_duplicate.ids.includes(order.id))
}

if (route.query.orderId) {
    fetchOrders([route.query.orderId])
    ordersFilters.value.water_company_id = +route.query.water_company_id
}

const pageBreadcrumbs = ref([
    {
        title: 'Заказы',
        href: '/orders'
    }
])
</script>

<template>
    <div class="content-wrapper">
        <UILoader :loading="isPageLoading" />
        <!-- header -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Заказы</h1>
                    </div>
                </div>
            </div>
        </div>
        <!-- header off -->
        <!-- content on -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="container-xl ml-0">
                        <UIBreadCrumbs :items="pageBreadcrumbs" />
                        <!-- Основная информация -->
                        <OrdersFiltersComponent v-model="ordersFilters" v-model:searchByValue="searchByValue"
                            v-model:searchBy="searchBy" @search-orders="fetchOrders" />
                        <!-- алерты -->
                        <div class="form-row">
                            <div class="form-group mb-2 col-md-2 col-xl-2" v-show="orderErrorsCount">
                                <div class="card alert-danger mb-1" v-tooltip="`Ошибки с ${orderErrorsDateForm.format('DD.MM.YYYY')} по ${orderErrorsDateTo.format('DD.MM.YYYY')}`"
                                    @click="fetchOrdersWithErrors">
                                    <div class="card-body p-2 show-errors" style="cursor: pointer" title="смотреть">
                                        Ошибки в заказах: <b>{{ orderErrorsCount }}</b>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2 col-md-2 col-xl-2" v-if="ordersInfo?.orders_duplicate?.count"
                                @click="showDuplicate">
                                <div class="card alert-danger">
                                    <div class="card-body p-2 show-errors" style="cursor: pointer" title="смотреть">
                                        Дубликаты: <b>{{ ordersInfo.orders_duplicate.count }}</b>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group mb-2 col-md-2 col-xl-2">
                                <div class="card alert-danger">
                                    <div class="card-body p-2 show-errors" style="cursor: pointer" title="смотреть">
                                        Другая ошибка
                                    </div>
                                </div>
                            </div> -->
                        </div>

                        <FullOrdersTable :orders="orders" :orderDuplicates="ordersInfo?.orders_duplicate?.ids"
                            :ordersInfo="ordersInfo" @update-order="updateOrder" />
                    </div>
                </div>
            </div>
        </section>
        <!-- content off -->
    </div>
</template>
