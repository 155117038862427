import api from "@/common/api";
import { showErrors } from "@/common/showErrors";

export const fetchDeliveryTimes = async (water_company_id) => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/delivery-times`, { params: { water_company_id } })
        return data.data
    } catch (error) {
        showErrors(error)
    }
}

export const fetchTimeSlots = async (timeSlotsForm) => {
    try {
        const { data } = await api.post(`${process.env.VUE_APP_GATE_URL}/dss/api/v1/slots_extended`, timeSlotsForm)
        return data.data
    } catch (error) {
        showErrors(error)
    }
}