<script setup>
import { defineProps, computed } from "vue"
import { getFormattedPhoneNumber, addToClipboard } from '@/lib/utils'

const props = defineProps({
    customer: {
        type: Object,
        required: true,
        default: () => ({})
    },
    paymentTypes: {
        type: Array,
        required: false,
        default: () => []
    }
})

const legalUserTypeId = 1





const getPaumentTypeTitle = computed(() => {
    const currentPaymentType = props.paymentTypes.find((type) => type.id === props.customer.payment_type) ?? {}
    return currentPaymentType.name
})
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">Информация</div>
        <div class="card-body p-2">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <ul class="list-group list-group-unbordered mb-3">
                        <li class="list-group-item">
                            <b>Тип</b> 
                            <span class="float-right">
                                {{ customer?.type?.id === legalUserTypeId ? 'Юр. лицо' : 'Физ. лицо'}}
                            </span>
                        </li>
                        <li class="list-group-item">
                            <b>ФИО</b> <span class="float-right">{{ `${props.customer.last_name ?? ''} ${props.customer.first_name} ${props.customer.patronymic ?? ''}` }}</span>
                        </li>
                        <li class="list-group-item" v-if="props.customer?.type?.id === legalUserTypeId">
                            <b>Компания</b> <span class="float-right">{{ customer.company }}</span>
                        </li>
                        <li class="list-group-item">
                            <b>ИНН</b> <span class="float-right">{{ customer.company_inn }}</span>
                        </li>
                        <li class="list-group-item">
                            <b>Договор</b> <span class="float-right">{{ customer.contract_number }}</span>
                        </li>
                        <li v-if="customer.phone" class="list-group-item">
                            <b>Телефон</b> <b class="float-right"  @click="addToClipboard(customer.phone, 'Телефон скопирован!')" style="cursor: pointer">{{ getFormattedPhoneNumber(customer.phone) }}</b>
                        </li>
                        <li v-if="customer.phone2" class="list-group-item">
                            <b>Доп. телефон</b> <b class="float-right"  @click="addToClipboard(customer.phone2, 'Телефон скопирован!')" style="cursor: pointer">{{ getFormattedPhoneNumber(customer.phone2) }}</b>
                        </li>
                        <li v-if="customer.phone_from_call" class="list-group-item">
                            <b>Телефон откуда звонил</b> <b class="float-right"  @click="addToClipboard(customer.phone_from_call, 'Телефон скопирован!')" style="cursor: pointer">{{ getFormattedPhoneNumber(customer.phone_from_call) }}</b>
                        </li>
                        <li class="list-group-item">
                            <b>Email</b> <span class="float-right">{{ props.customer.email }}</span>
                        </li>
                        <li class="list-group-item">
                            <b>Тип оплаты</b> <span class="float-right">{{ getPaumentTypeTitle }}</span>
                        </li>
                        <li class="list-group-item">
                            <b>Заказов</b> <span class="float-right">-</span>
                        </li>
                        <li class="list-group-item">
                            <b>Телефонный робот</b> <span class="float-right">{{ customer.is_phone_robot ? 'Да' : 'Нет' }} </span>
                        </li>
                        <li class="list-group-item">
                            <b>Отправлять чек</b> <span class="float-right">{{ customer.need_check ? 'Да' : 'Нет' }}</span>
                        </li>
                        <li class="list-group-item">
                            <b>Работает по эдо</b> <span class="float-right">{{ customer.work_by_edo ? 'Да' : 'Нет' }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-6 col-md-6">
                    <router-link type="submit" :to="`/customers/edit/${props.customer.id}`"
                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light">Редактировать</router-link>
                </div>
                <div class="col-lg-6 col-md-6">
                    <router-link type="submit"
                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light" :to="{path: '/order', query: {customerId: customer.id}}">Добавить
                        заказ</router-link>
                </div>
            </div>
        </div>
    </div>
</template>