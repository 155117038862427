import {useToast} from 'vue-toastification';
import * as Sentry from '@sentry/browser';

const toast = useToast();

const timeToLifeMs = 12000;

const sendToastError = (error) => {
    // Sentry.captureException(error);

    if (!/[a-zA-Z]/.exec(error)) {
        toast.error(error,
            {
                timeout: timeToLifeMs
            });
    } else {

        toast.error("Упс, что-то пошло не так, попробуйте позже.",
            {
                timeout: timeToLifeMs
            });
    }
}

export function showErrors(response) {
    console.log(response, 'show error')
    if (response.response) {
        response = response.response
    }

    if ((response.status && response.status !== 200) || !response.status) {
        const stringifyWithDepth = (obj, depth = 5) => {
            return JSON.stringify(obj, (key, value) => {
                if (depth > 0 && typeof value === 'object' && value !== null) {
                    return Object.fromEntries(
                        Object.entries(value).map(([k, v]) => [k, stringifyWithDepth(v, depth - 1)])
                    );
                }
                return value;
            }, 2);
        };

        const errorInfo = {
            status: response.status ?? null,
            statusText: response.statusText ?? null,
            url: response.config?.url,
            method: response.config?.method,
            data: JSON.parse(stringifyWithDepth(response?.data?? "Не отвечает сервер")),
            headers: response.headers ?? null,
            config: JSON.parse(stringifyWithDepth(response.config??null)),
        };

        const customError = new Error(`API Error: ${response.status} ${response.statusText}` + Date.now());
        customError.errorInfo = errorInfo;

        Sentry.captureException(customError, {
            extra: {
                errorInfo: errorInfo
            }
        });

        // Log to console for debugging
        // console.error('Error Info:', JSON.stringify(errorInfo, null, 2));
    }

    if (response.status == 404) {
        sendToastError("Страница не найдена")

        return
    }

    if (response.status == 429) {
        sendToastError("Слишком много запросов, пожалуйста подождите")
        return
    }

    if (response.clientValidateError) {
        sendToastError(response.clientValidateError)
        return
    }

    if (!response?.data) {
        console.log(response, 'response')
        sendToastError("Неизвестная ошибка")

        return
    }

    if (response.data?.errors?.title) {
        sendToastError(response.data.errors.title)
        return
    }

    if (response.data.errors) {
        let errors = response.data.errors
        for (const key in errors) {
            if (Object.hasOwnProperty.call(errors, key)) {
                const error = errors[key][0];
                sendToastError(error)
            }
        }
        return;
    }

    if (response.data.validation) {
        let errors = response.data.validation
        for (const key in errors) {
            if (Object.hasOwnProperty.call(errors, key)) {
                const error = errors[key];
                sendToastError(error.message)
            }
        }
        return;
    }

    if (response.data.message && response.data.message.body) {
        sendToastError(response.data.message.body)
        console.log('Непредвиденная ошибка!', response.data);

        return;
    }

    if (response.data.message) {
        sendToastError('Возникла ошибка.')
        console.log('Ошибка!', response.data);

        return;
    }
}


