import { parsePhoneNumberFromString, isValidPhoneNumber } from 'libphonenumber-js';
import { useToast } from "vue-toastification";

export const removeEmptyFromObject = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key, value]) => key && value !== null)
      .map(([key, value]) => [key, value === Object(value) ? removeEmptyFromObject(value) : value])
  );
}
export const getFormattedPhoneNumber = (phone = '') => {
  let result = phone;
  if (!result) {
    return result
  }
  if (result.startsWith('8') || result.startsWith('7')) {
    result = '+7' + result.slice(1);
  }
  if (!isValidPhoneNumber(result ?? '', 'RU')) {
    return result
  }
  result = parsePhoneNumberFromString(result, 'RU')?.formatNational() || ''
  if (result.startsWith('8') || result.startsWith('7')) {
    result = '+7' + result.slice(1);
  }

  return result
}

export const splitNumberByDigit = (value) => {
  if (!value && value !== 0) {
    return 0
  }

  const digitWithCurrency = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'RUB' })
    .format(value)

  const digitWithoutCurrency = digitWithCurrency.replace('RUB', '')

  const digitWithoutDots = digitWithoutCurrency.replaceAll('.', '\u00A0')

  return digitWithoutDots
}

export const addToClipboard = async (value = '', succesText = 'Значение скопировано') => {
  const toast = useToast();
  if (value) {
    const result = await navigator.clipboard.writeText(value)
    toast.success(succesText);
    return result
  }
  return
}

export const getUnMaskedPhone = (phone) => {
  return phone.replace(/\D/g, '')
}