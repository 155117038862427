export const getColorByStatusId = (statusId) => {
    const blue = '#0b6ec2'
    const green = '#008038'
    const red = '#dc3545'
    const gray = '#e5e7eb'

    const colorsById = {
        1: blue,
        2: red,
        3: red,
        4: green,
        5: green,
        6: green,
        7: red,
        8: red,
        9: green,
        10: red
    }

    return colorsById?.[statusId] ?? gray
}