import api from "@/common/api";
import { showErrors } from "@/common/showErrors";
import { removeEmptyFromObject, getUnMaskedPhone } from '@/lib/utils';
import { useToast } from "vue-toastification";

const getRequestBody = (addressForm) => {
    const requestBody = removeEmptyFromObject({ ...addressForm, phone: getUnMaskedPhone(addressForm?.phone || '') })
    if (addressForm.phone) {
        requestBody.phone = getUnMaskedPhone(addressForm.phone)
    }
    return requestBody
}

export const postAddress = async (addressForm) => {
    try {
        const { data } = await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/addresses', getRequestBody(addressForm))
        return data.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const patchAddress = async (addressForm) => {
    try {
        const { data } =await api.patch(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/addresses/' + addressForm.id, getRequestBody(addressForm))
        return data.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const getAddressesByCusomerId = async (customerId) => {
    try {
        const { data } =await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customers/${customerId}/addresses`)
        return data.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const removeAddressById = async (addressId) => {
    try {
        await api.delete(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/addresses/${addressId}`)
        const toast = useToast()
        toast.success('Адрес удалён!')
    } catch (error) {
        showErrors(error)
    }
}