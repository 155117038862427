<script setup>
import api, { parseApiErrorsForValidator } from '@/common/api';
import { ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router'
import CustomerCard from '@/components/Customers/CustomerCard.vue';
import ReferalTable from '@/components/Customers/Tables/ReferalTable.vue';
import AddressTable from '@/components/Customers/Tables/AddressTable.vue';
import OrdersTable from '@/components/Customers/Tables/OrdersTable.vue';
import { removeEmptyFromObject } from '@/lib/utils';
import moment from 'moment';
import { getCustomerById } from '@/lib/api/customer';
import BaseAddress, { AddressFieldsWithEmptyValidation } from '@/lib/models/BaseAddress';
import UIModal from '@/components/UI/UIModal.vue';
import AddressForm from '@/components/Forms/AddressForm.vue';
import { patchAddress, postAddress, getAddressesByCusomerId, removeAddressById } from "@/lib/api/address";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, or, minLength } from '@vuelidate/validators'
import { showErrors } from '@/common/showErrors';
import { useToast } from 'vue-toastification';
import { fetchPaymentTypes } from "@/lib/api/params";
import BindingsTable from '../Tables/BindingsTable.vue';

const customer = ref({})
const customerOrders = ref([])
const customerReferrals = ref([])

const countMonthOrderStatistic = 2
const orderFiltersDateFrom = new Date().setMonth(new Date().getMonth() - countMonthOrderStatistic)
const orderFiltersDateTo = new Date().setMonth(new Date().getMonth() + countMonthOrderStatistic)
const ordersFilters = ref({ date_from: orderFiltersDateFrom, date_to: orderFiltersDateTo, address_id: null, water_company_id: 0 })

const route = useRoute()
const isLoading = ref(false)
const toast = useToast()

const getOrderFilterParams = (orderFilters) => {
    const result = structuredClone(orderFilters)
    if (result.date_from)
        result.date_from = moment(orderFilters.date_from).format("YYYY-MM-DD")
    if (result.date_to)
        result.date_to = moment(orderFilters.date_to).format("YYYY-MM-DD")
    return removeEmptyFromObject(result)
}

const fetchCustomerById = async (customerId) => {
    try {
        isLoading.value = true
        customer.value = await getCustomerById(customerId)
        addressForm.value.water_company_id = customer.value?.water_company?.id
        ordersFilters.value.water_company_id = customer.value?.water_company?.id
        getCustomerOrders(customer.value.id, ordersFilters.value)
        getCustomerReferrals(customer.value.id)
        fetchPaymentTypesAsync()
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

const getCustomerOrders = async (customerId, ordersFilters = {}) => {
    try {
        isLoading.value = true
        const formattedOrderFilters = getOrderFilterParams(ordersFilters)
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customers/${customerId}/orders`, { params: formattedOrderFilters })
        customerOrders.value = data.data
    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

const getCustomerReferrals = async (customerId) => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customers/${customerId}/referrals`)
        customerReferrals.value = data.data
    } catch (error) {
        console.error(error)
    }
}

watch(
    () => route.params,
    () => {
        fetchCustomerById(route.params.id)
    },
    {
        deep: true,
        immediate: true
    }
)

const pageBreadcrumbs = computed(() => [
    {
        title: 'Клиенты',
        href: '/customers/search'
    },
    {
        title: `${customer.value.last_name ?? ''} ${customer.value.first_name ?? ''} ${customer.value.patronymic ?? ''}`,
        href: '#'
    },
])


const showAddressModal = ref(false)
const addressForm = ref(new BaseAddress())
const addressFormValidationRules = {
    address_source_string: { required: helpers.withMessage('Заполните полный адрес', required) },
    phone: { minLength: helpers.withMessage('Минимум 11 чисел', or(() => !addressForm.value.phone, minLength(11))) },
    latitude: { required: helpers.withMessage('Заполните ширину', required) },
    longitude: { required: helpers.withMessage('Заполните долготу', required) },
    ...AddressFieldsWithEmptyValidation
}
const addressFormApiValidationErrors = ref({})
const addressFormValidator = useVuelidate(addressFormValidationRules, addressForm, { $externalResults: addressFormApiValidationErrors })

const addAddressHandler = () => {
    showAddressModal.value = true
    addressForm.value = new BaseAddress({ customer_id: customer.value.id, water_company_id: customer.value.water_company.id })
}
const editAddressHandler = (addressId) => {
    showAddressModal.value = true
    const currentAddress = customer.value.addresses.find(address => address.id === addressId)
    addressForm.value = new BaseAddress({ ...currentAddress, customer_id: customer.value.id })
}

const submitAddressHandler = async () => {
    try {
        isLoading.value = true
        addressFormValidator.value.$validate()
        if (addressFormValidator.value.$errors.length) {
            throw { clientValidateError: 'Проверьте заполнение полей адреса' }
        }

        if (addressForm.value.id) {
            await patchAddress(addressForm.value)
        } else {
            await postAddress(addressForm.value)
        }

        customer.value.addresses = await getAddressesByCusomerId(customer.value.id)
        showAddressModal.value = false
        toast.success('Адрес сохранился!')
    } catch (error) {
        showErrors(error)
        console.log(parseApiErrorsForValidator(error), 'parseApiErrorsForValidator(error)')
        addressFormApiValidationErrors.value = parseApiErrorsForValidator(error)
    } finally {
        isLoading.value = false
    }
}

const removeAddressHandler = async (addressId) => {
    await removeAddressById(addressId)
    await fetchCustomerById(route.params.id)
}

const paymentTypes = ref([])
const fetchPaymentTypesAsync = async () => {
    paymentTypes.value = await fetchPaymentTypes(customer.value.water_company.id)
}


const checkCustomerDuty = computed(() => {
    return customer.value?.customerBindings?.some(binding => binding.payer.amount)
})

const customersDutyGrouped = computed(() => {
    const result = {}

    customer.value?.customerBindings?.forEach((binding) => {
        if (!result?.[binding.payer.id]) {
            result[binding.payer.id] = binding.payer.amount
        }
    })

    return result
})

const getPayerById = (payerId) => {
    return customer.value?.customerBindings?.find((binding) => binding.payer.id === +payerId).payer
}
</script>
<template>
    <div class="content-wrapper">
        <UILoader :loading="isLoading" />
        <!-- header -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Клиент {{ `${customer.last_name} ${customer.first_name ?? ''}
                            ${customer.patronymic ?? ''}` }}</h1>
                    </div>
                </div>
            </div>
        </div>
        <!-- header off -->
        <!-- content on -->
        <section class="content">
            <div class="container-fluid">
                <UIBreadCrumbs :items="pageBreadcrumbs" />
                <div class="row">
                    <div class="col-make-auto col-lg-12 col-md-12">
                        <!-- Информация -->
                        <div class="row">
                            <div class="col-lg-8 col-md-8">
                                <CustomerCard :customer="customer" :paymentTypes="paymentTypes" />
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="card">
                                    <div class="card-header alert-info p-2 pt-1 pb-1">Долги</div>
                                    <div class="card-body p-2">
                                        <b>Безналичный долг</b><br />
                                        <template v-if="checkCustomerDuty">
                                            <p v-for="(amount, payerId) in customersDutyGrouped" :key="payerId"><b>{{
                                                getPayerById(payerId).name }}:&nbsp;</b>{{ amount }} ₽</p>
                                        </template>
                                        <template v-else>
                                            <div class="alert p-1 my-1"
                                                :style="`background-color: #008038; color: #fff; height: min-content`">
                                                Долгов нет</div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Реферальная система -->
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <ReferalTable :referrals="customerReferrals" />
                            </div>
                        </div>

                        <!-- Юридические лица | только если клиент - юридическое лицо -->
                        <div class="row" v-if="customer?.type?.id === 1">
                            <div class="col-lg-12 col-md-12">
                               <BindingsTable :customerBindings="customer.customerBindings" />
                            </div>
                        </div>

                        <!-- Адреса -->
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <AddressTable :addresses="customer.addresses" @add-address="addAddressHandler"
                                    @edit-address="editAddressHandler" @remove-address="removeAddressHandler" />
                            </div>
                        </div>
                        <UIModal v-model:show="showAddressModal">
                            <AddressForm v-model="addressForm" :validator="addressFormValidator"
                                :waterCompanyId="customer.water_company.id" :newAddress="!addressForm.id"
                                :customerType="customer?.type?.id" />
                            <button type="submit" :disabled="isLoading"
                                class="btn btn-sm btn-primary waves-effect waves-light p-2"
                                @click="submitAddressHandler">Сохранить</button>
                        </UIModal>
                        <!-- Статистика заказов -->
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <OrdersTable :orders="customerOrders" :filters="ordersFilters" :customer="customer"
                                    @update:filters="ordersFilters = $event"
                                    @search="getCustomerOrders(customer.id, ordersFilters)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- content off -->
    </div>
</template>