<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import Toggle from '@vueform/toggle'
const props = defineProps({
    modelValue: { type: Boolean, required: true, default: () => false },
    label: { type: String, required: false, default: () => '' },
    class: { type: String, required: false, default: () =>  'mr-2' },
    disabled: { type: Boolean, required: false, default: () => false }
})
const emits = defineEmits(['update:modelValue'])

const UISwitcher = ref(null)

const updateHandler = () => {
    if(!props.disabled) {
        emits('update:modelValue', !props.modelValue)
    }
}
</script>

<template>
        <div class="ui-switch" :class="props.class">
            <Toggle v-uid ref="UISwitcher" :modelValue="!!props.modelValue"
                @update:modelValue="emits('update:modelValue', $event)" :disabled="disabled" />
            <label v-if="props.label" :for="UISwitcher?.id" @click="updateHandler" :disabled="disabled">{{ props.label }}</label>
        </div>
</template>

<style src="@vueform/toggle/themes/default.css"></style>

<style lang="scss">
.ui-switch {
    height: 19px;
    display: flex;
    align-items: end;
    .toggle-container:focus {
        box-shadow: none;
        outline: none;
    }
    .toggle {
        --toggle-bg-on: #007bff; 
        --toggle-border-on: #007bff; 
        --toggle-width: 30px;
        --toggle-height: 15px;
    }
    label {
        margin-bottom: 0px;
        height: 19px;
        line-height: 19px;
        margin-left: 10px;
        cursor: pointer;
        width: max-content;
        &[disabled="true"] {
            opacity: .5;
            cursor: not-allowed;
        }
    }
}
</style>