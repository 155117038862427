<script setup>
import api, { parseApiErrorsForValidator } from '@/common/api';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed } from 'vue'
import ClientFormComponent from '@/components/Forms/ClientForm.vue';
import UIInput from "@/components/UI/UIInput.vue";
import UICheckbox from '@/components/UI/UICheckbox.vue';
import { showErrors } from '@/common/showErrors';
import { useToast } from 'vue-toastification';
import { removeEmptyFromObject } from '@/lib/utils';
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, or, minLength } from '@vuelidate/validators'
import BaseClient, { ClientFieldsWithEmptyValidation } from '@/lib/models/BaseClient';
import { fetchPaymentTypes } from "@/lib/api/params";

const toast = useToast();
const loadingPage = ref(false)
const router = useRouter()

const customerId = useRoute().params.id
const clientForm = ref(new BaseClient())
const clientFormValidationRules = {
    phone: { required: helpers.withMessage('Заполните поле телефон', required), minLength: helpers.withMessage('Минимум 11 чисел', minLength(11)) },
    phone2: { minLength: helpers.withMessage('Минимум 11 чисел', or(() => !clientForm.value.phone2, minLength(11))) },
    phone_from_call: { minLength: helpers.withMessage('Минимум 11 чисел', or(() => !clientForm.value.phone_from_call, minLength(11))) },
    payment_type: { required: helpers.withMessage('Выберите тип оплаты', required) },
    first_name: { required: helpers.withMessage('Заполните имя', required), regex: helpers.withMessage('Поле должно содержать только русские символы', helpers.regex(/^[-а-яА-ЯёЁ\\s]+$/)) },
    last_name: { regex: helpers.withMessage('Поле должно содержать только русские символы', helpers.regex(/^[-а-яА-ЯёЁ\\s]+$/)) },
    patronymic: { regex: helpers.withMessage('Поле должно содержать только русские символы', helpers.regex(/^[-а-яА-ЯёЁ\\s]+$/)) },
    company: { reuired: helpers.withMessage('Заполните компанию', or(() => clientForm.value.type === 2, required)) },
    distribution_id: { required: helpers.withMessage('Выберите партнёра', or(() => !clientForm.value.hasPartner, required)) },
    distribution_customer_id: { required: helpers.withMessage('Введите ID клиента партнёра', or(() => !clientForm.value.hasPartner, required)) },
    ...ClientFieldsWithEmptyValidation
}
const clientFormApiValidationErrors = ref({})
const clientFormValidator = useVuelidate(clientFormValidationRules, clientForm, { $externalResults: clientFormApiValidationErrors })
const getCustomer = async (id) => {
    try {
        loadingPage.value = true
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customer/${id}`)
        clientForm.value = new BaseClient(data.data)
        fetchPaymentTypesAsync(data.data.water_company.id)
    } catch (error) {
        console.error(error)
    } finally {
        loadingPage.value = false
    }
}

const updateCustomer = async (newCustomer) => {
    try {
        loadingPage.value = true
        clientFormValidator.value.$validate()
        if (clientFormValidator.value.$errors.length) {
            throw { clientValidateError: 'Проверьте заполнение полей клиента' }
        }
        await api.patch(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customers/${newCustomer.id}`, removeEmptyFromObject(newCustomer))
        toast.success('Клиент обновлён')
        router.push({path: '/customers/' + clientForm.value.id})
    } catch (error) {
        clientFormApiValidationErrors.value = parseApiErrorsForValidator(error)
        showErrors(error)
    } finally {
        loadingPage.value = false
    }
}

getCustomer(customerId)

const paymentTypes = ref([])
const fetchPaymentTypesAsync = async (water_company_id) => {
    paymentTypes.value = await fetchPaymentTypes(water_company_id)
}

const pageBreadcrumbs = computed( () => [
    {
        title: 'Клиенты',
        href: '/customers/search'
    },
    {
        title: `${clientForm.value.last_name ?? ''} ${clientForm.value.first_name ?? ''} ${clientForm.value.patronymic ?? ''}`,
        href: '/customers/' + clientForm.value.id
    },
    {
        title: 'Редактировать',
        href: '#'
    },
])
</script>

<template>
    <div class="content-wrapper">
        <UILoader :loading="loadingPage" />
        <!-- header -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Клиент {{ `${clientForm.last_name ?? ''} ${clientForm.first_name}
                            ${clientForm.patronymic ?? ''}` }} - редактирование</h1>
                    </div>
                </div>
            </div>
        </div>
        <!-- header off -->
        <!-- content on -->
        <section class="content">
            <div class="container-fluid">
                <UIBreadCrumbs :items="pageBreadcrumbs"/>
                <div class="row">
                    <div class="col-make-auto col-lg-12 col-md-12">

                        <!-- Клиент -->
                        <ClientFormComponent v-model="clientForm" isMainInfo :validator="clientFormValidator" :paymentTypes="paymentTypes" />

                        <!-- Дополнительно -->
                        <div class="card">
                            <div class="card-header alert-info p-2 pt-1 pb-1"><i
                                    class="fas fa-question-circle mr-2"></i>Дополнительно</div>
                            <div class="card-body p-2">
                                <div class="form-row">
                                    <UIInput v-model="clientForm.email" class="mb-0 col-md-4 col-xl-4" sm
                                        maxlength="254" label="E-mail" />
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-5 col-xl-5">
                                        <UICheckbox v-model="clientForm.is_need_act" label="Печатать даты акта" />
                                        <UICheckbox v-model="clientForm.is_phone_robot" label="Автоответчик" />
                                        <UICheckbox v-model="clientForm.need_check" label="Всегда отправлять чек" />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group mb-0 col-md-auto col-xl-auto">
                                        <button type="submit"
                                            class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light"
                                            @click="updateCustomer(clientForm)">Редактировать</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
        <!-- content off -->
    </div>
</template>
