<script setup>
import { defineProps, computed, ref, watch, defineEmits } from 'vue'
import { getFormattedPhoneNumber, addToClipboard } from '@/lib/utils'
import { useParamsStore } from "@/store/params";
import UISwitcher from '@/components/UI/UISwitcher.vue';
import { showErrors } from '@/common/showErrors';
import { vTooltip } from 'floating-vue'
import api from '@/common/api';
import {useToast} from "vue-toastification";
// import UICheckbox from '@/components/UI/UICheckbox.vue';

const props = defineProps({
    clients: { type: Array, required: false, default: () => [] },
})
const emits = defineEmits(['choose-double'])
const toast = useToast();


const getFullNameByClient = (client) => {
    return `${client.last_name ?? ''} ${client.first_name ?? ''} ${client.patronymic ?? ''}`
}


const companyCities = computed(() => useParamsStore().cities)
const getCityByClient = (client) => {
    const clientCity = companyCities.value.find(city => city.id === client.water_company_id)
    return clientCity.short_name
}


const mainCustomer = ref({})
const chooseMainCustomerHandler = (event, client) => {
    if (event) {
        mainCustomer.value = client
    } else {
        resetMainAndDoubles()
    }
}

const clientDoubles = ref([])
const setDoubleHandler = (event, client) => {
    if (event) {
        clientDoubles.value.push(client.id)
    } else {
        clientDoubles.value = clientDoubles.value.filter((clientId) => clientId !== client.id)
    }
}

const setAllClientAsDouble = () => {
    clientDoubles.value = []
    props.clients.forEach((client) => {
        if(client.id !== mainCustomer.value.id) {
            clientDoubles.value.push(client.id)
        }
    })
}
const resetMainAndDoubles = () => {
    mainCustomer.value = {}
    clientDoubles.value = []
}


const setDoublesAsync = async () => {
    try {
        await api.post(process.env.VUE_APP_GATE_URL + `/crm3core/api/v1/customers/${mainCustomer.value.id}/bind-duplicates`, {
            duplicates_ids: clientDoubles.value
        })
        resetMainAndDoubles()
        toast.success("Дубли связаны!");
        emits('choose-double')
    } catch (error) {
        showErrors(error)
    }
}


watch(() => props.clients, () => {
    resetMainAndDoubles()
})
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">
            <p class="py-1 card-title d-block mr-4 my-auto"><i class="fas fa-info-circle mr-2"></i>{{ clients.length ?
                `Клиенты
                (${props.clients.length})` : 'Клиентов не найдено, уточните поиск' }}</p>
            <div class="py-auto h-100 form-row">
                <button v-if="mainCustomer.id" type="submit" class="py-1 btn btn-primary waves-effect waves-light"
                    @click="setAllClientAsDouble">
                    Отметить всех как дубли
                </button>
                <button v-if="mainCustomer.id" type="submit" class="py-1 btn btn-primary waves-effect waves-light ml-2"
                    :disabled="!clientDoubles.length" v-tooltip="!clientDoubles.length ? 'Выберите дубли' : null" @click="setDoublesAsync">
                    Подтвердить дубли
                </button>
            </div>
        </div>
        <div class="card-body" v-if="props.clients.length">

            <table class="table" v-if="props.clients.length">
                <thead>
                    <tr>
                        <th class="col-4">Телефон, ФИО, город</th>
                        <th class="co-3">Выберите основной аккаунт</th>
                        <th class="col-2">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="client in props.clients" :key="client.id">
                        <td class="col-4">
                            <b @click="addToClipboard(client.phone, 'Телефон скопирован!')" style="cursor: pointer">{{
                                getFormattedPhoneNumber(client.phone) }}</b><br v-show="client.phone" />
                            {{ client?.type?.shortName }}<br v-show="client?.type?.shortName" />
                            <template v-if="client.company">
                                <b>{{ client.company }}</b><br />
                            </template>
                            {{ getFullNameByClient(client) }}
                            <p v-show="client?.type?.name" class="text-secondary font-small-1">{{ client?.type?.name }}
                            </p>
                            <p class="text-secondary font-small-1">{{ getCityByClient(client) }}</p>
                        </td>
                        <td v-if="!mainCustomer.id || mainCustomer.id === client.id">
                            <UISwitcher class="mt-3" label="Основной" :modelValue="mainCustomer.id === client.id"
                                @update:model-value="chooseMainCustomerHandler($event, client)" />
                        </td>
                        <td v-else>
                            <UISwitcher class="mt-3" label="Дубль" :modelValue="clientDoubles.includes(client.id)"
                                @update:model-value="setDoubleHandler($event, client)" />
                        </td>
                        <td class="col-2">
                            <div class="row">
                                <div class="col-md-12">
                                    <a href="#">
                                        <i class="fas fa-info-circle mr-2"></i>Статистика
                                    </a>
                                </div>
                                <div class="col-md-12">
                                    <router-link :to="`/customers/${client.id}`">
                                        <i class="fas fa-id-card-alt mr-2"></i>Просмотр
                                    </router-link>
                                </div>
                                <div class="col-md-12">
                                    <router-link :to="{ path: '/order', query: { customerId: client.id } }">
                                        <i class="fas fa-plus mr-2"></i>Добавить заказ
                                    </router-link>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped lang="scss">
.address__icon {
    color: #17a2b8;
}
</style>