export const loaderPhrases = [
    'Данные в пути!',
    'Скоро будет интересно',
    'Загружаем ваши сокровища',
    'Ваше терпение — наше всё',
    'Подождите, это того стоит',
    'Чайник закипает...',
    'Собираем пазл данных',
    'Приготовьтесь к взлёту',
    'Ждём, пока приготовится',
    'Без спешки, но в срок',
    'Мы не торопимся, мы аккуратны',
    'Загрузка: искусство терпения',
    'Ваше время — наша работа',
    'Не гоним, а делаем качественно',
    'Секунду, идёт магия',
    'Мы не спим, мы работаем',
    'Терпение — залог успеха',
    'Включаем все цилиндры',
    'Сбор данных в процессе',
    'Собираем информацию по кусочкам…',
    'Немного волшебства для загрузки…',
    'Ищем нужную страничку в интернете…',
    'Отправляем запрос в дальние края сети…',
    'Разговариваем с компьютерами, подождите…',
    'Заглядываем в базу данных за ответом…',
    'Готовим всё необходимое для вас…',
    'Складываем пазл из информации…',
    'Проверяем все полочки с данными…',
    'Приближаем желаемое…',
    'Копаем глубже за нужной информацией…',
    'Заряжаем энергией вашу страницу…',
    'Соединяем точки в сети…',
    'Приносим данные на блюдечке…',
    'Проверяем каждую буковку…',
    'Очищаем дорогу к вашим запросам…',
    'Почти готово, еще чуть-чуть…',
    'Упаковываем данные в красивую коробочку…',
    'Подготавливаем всё самое нужное…',
    'Тормошим интернет, чтобы не зевал…',
    'Наполняем ваш стакан',
    'Идёт процесс очистки',
    'Вода на подходе!',
    'Чистая вода — чистый код',
    'Бутылка за бутылкой',
    'Распространяем свежесть',
    'Ваша вода течёт',
    'Идёт наполнение резервуара',
    'Готовим водный поток',
    'Вода на стартовой позиции',
    'Подготавливаем глоток свежести',
    'Идет процесс минерализации',
    'Скоро ваша бутылка будет полна',
    'Выпустите разработчиков из подвала',
    'Заливаем данные',
    'Ваше море терпения...',
    'Пузырьки в пути',
    'Водный поток активирован',
    'Наполняем ваш оазис',
    'Ожидайте освежающего результата',
    'Готовим водную магию',
    'Идет подготовка к увлажнению',
    'Вода в движении',
    'Прозрачность на подходе',
    'Скоро будет бурлящая энергия',
    'Ваш источник чистоты',
    'Подготавливаем родниковую прохладу',
    'Идет сбор капель',
    'Ждите — скоро будет волна',
    'Освежаем ваши данные'
]