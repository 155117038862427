<script setup>


import {useUserStore} from "@/store";
import {computed, ref} from "vue";
import SalaryMenu from "@/components/Salary/SalaryMenu.vue";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const pageBreadcrumbs = ref([
  {
    title: 'Сотрудники',
    href: '/staff-list'
  },
  {
    title: 'Водители/Экспедиторы',
    href: '/staff-list'
  },
  {
    title: 'ЗП',
    href: '/salary/drivers'
  },
])
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Водители и экспедиторы</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <UIBreadCrumbs :items="pageBreadcrumbs" />
        <SalaryMenu></SalaryMenu>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>