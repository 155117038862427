<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import debounce from 'lodash/debounce';
import { onClickOutside } from '@vueuse/core'
import { vTooltip } from 'floating-vue'
import api from "@/common/api";
import { getFormattedPhoneNumber } from '@/lib/utils';

const props = defineProps({
    modelValue: { type: Number, required: true },
    label: { type: String, required: false, default: () => '' },
    errors: { type: Array, required: false, default: () => [] },
    readonly: { type: Boolean, required: false, default: () => false },
    waterCompanyId: { type: Number, required: true, default: () => 1 },
    bindings: { type: Boolean, required: false, default: () => false }
})
const emits = defineEmits(['update:modelValue'])

const UIInput = ref(null)


const customerSugestions = ref([])
const fetchingCustomerSugestions = ref(false)
const updateCustomerSugestionsAsync = async () => {
    const lengthForSearch = 2
    if (currentQuery.value.length >= lengthForSearch) {
        try {
            fetchingCustomerSugestions.value = true
            const searchUrl = props.bindings ? '/crm3core/api/v1/filter-options/saldos' : '/crm3core/api/v1/customers/findMatches'
            const { data } = await api.get(process.env.VUE_APP_GATE_URL + searchUrl, {
                params: { search: currentQuery.value, water_company_id: props.waterCompanyId }
            })
            customerSugestions.value = data.data

        } catch (error) {
            console.error(error)
        } finally {
            fetchingCustomerSugestions.value = false
        }
    } else {
        customerSugestions.value = []
    }
}
const updateCusomersSugestionsDebounce = debounce(updateCustomerSugestionsAsync, 300)

const isActiveElement = ref(false)
const autocompleteElement = ref(null)
onClickOutside(autocompleteElement, () => isActiveElement.value = false)
const showSuggestions = computed(() => {
    return !fetchingCustomerSugestions.value && isActiveElement.value && customerSugestions.value.length && !props.readonly
})

const inputHandler = (suggest) => {
    emits('update:modelValue', suggest.id)
    isActiveElement.value = false
    autocompleteElement.value.blur()
}

const currentQuery = ref('')

const currentCustomer = computed(() => {
    return customerSugestions.value.find((customer) => customer.id === props.modelValue)
})

const getCustomerName = (customer) => {
    if (props.bindings) {
        return customer.name
    }
    return `${customer.last_name} ${customer.first_name} ${customer.patronymic}`
}

const getInputValue = computed(() => {
    if (!isActiveElement.value) {
        if (currentCustomer.value) {
            return getCustomerName(currentCustomer.value)
        } else {
            return ''
        }
    } else {
        return currentQuery.value
    }
})

const udpateQueryHandler = (event) => {
    currentQuery.value = event.target.value
    updateCusomersSugestionsDebounce()
}
</script>

<template>
    <div ref="autocompleteElement" class="customer-search-input form-group col-md-2 col-xl-2 mb-0"
        @blur="isActiveElement = false">
        <label :for="UIInput?.id" class="col-form-label pb-1">{{ props.label }}</label>
        <div class="position-relative">
            <input v-uid v-tooltip="errors.length ? errors[0].$message : null" ref="UIInput" type="text"
                class="form-control form-control-sm" :value="getInputValue"
                :readonly="props.readonly || fetchingCustomerSugestions" @input="udpateQueryHandler"
                @focus="isActiveElement = true" :class="errors.length ? 'has-error ' : ''">
            <span class="spinner-container" v-if="fetchingCustomerSugestions">
                <svg class="spinner" width="20px" height="20px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                    </circle>
                </svg>
            </span>
        </div>
        <div class="customer-search-input__suggestions-container" v-if="showSuggestions">
            <div v-for="suggest in customerSugestions" :key="suggest.id" class="customer-search-input__suggestion"
                @click="inputHandler(suggest)">
                {{ getCustomerName(suggest) }}
                <p class="customer-search-input__suggestion-desc mb-0">{{ getFormattedPhoneNumber(suggest.phone) }}</p>
                <p v-if="!bindings" class="font-small-1 text-muted">{{ suggest.type.short_name }}</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.customer-search-input {
    position: relative;

    .has-error {
        border-color: red;
        background-color: #ff00001a;
    }

    .customer-search-input__error {
        position: absolute;
        margin: 0px;
        bottom: -8px;
        font-size: 12px;
        color: red;
    }

    .customer-search-input__suggestions-container {
        position: absolute;
        margin-left: 5px;
        top: 100%;
        left: 0px;
        width: calc(100% - 10px);
        z-index: 10;
        padding: 8px 0px;
        background-color: white;
        border: 1px gray solid;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        max-height: 300px;
        overflow-y: auto;

        .customer-search-input__suggestion {
            margin: 0px;
            padding: 4px 8px;
            cursor: pointer;

            .customer-search-input__suggestion-desc {
                color: gray;
                font-size: 13px;
            }
        }

        .customer-search-input__suggestion:hover {
            background-color: #17a2b8;
            color: #fff;

            .customer-search-input__suggestion-desc {
                color: #fff;
            }
        }
    }
}
$offset: 187;
$duration: 1.4s;

.spinner-container {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%);
}

.spinner {
    animation: rotator $duration linear infinite;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

.path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: #17a2b8;
    animation:
        dash $duration ease-in-out infinite,
        // colors ($duration*4) ease-in-out infinite;
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}
</style>