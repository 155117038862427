import api from "@/common/api";
import { showErrors } from "@/common/showErrors";

export const fetchPaymentTypes = async (water_company_id) => {
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/filter-options/payment-types', {
            params: {
                water_company_id
            }
        })
        return data.data
    } catch (error) {
        showErrors(error)
    }
}