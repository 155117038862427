<script setup>
import { defineProps } from 'vue'
const props = defineProps({
    items: {
        type: Array,
        required: false,
        default: () => []
    }
})
</script>

<template>
    <div class="form-row">
        <ol class="ui-breadcrumb breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
            <li class="breadcrumb-item" v-for="(item, idx) in props.items" :key="item.title" :class="{'acrive': props.items.length-1 === idx}"><a :href="item.href">{{ item.title }}</a></li>
        </ol>
    </div>
</template>

<style scoped lang="scss">
.ui-breadcrumb {
    background-color: #f4f6f9;
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>