<script setup>
import { defineProps, ref, watch } from 'vue'
import { VueSpinnerComment } from "vue3-spinners";
import { loaderPhrases } from '@/lib/auxiliary/Loader';

const props = defineProps({
    loading: {
        type: Boolean,
        required: true,
        default: () => false
    },
    delayShow: {
        type: Number,
        required: false,
        default: () => 1000
    }
})

const delayChangeShow = async () => new Promise(resolve => setTimeout(resolve, props.delayShow))
const showSpinner = ref(false)

const currentLoaderPhrase = ref('')

const setRandomPhrase = () => {
    const randomIndex = Math.floor(Math.random() * loaderPhrases.length);
    currentLoaderPhrase.value = loaderPhrases[randomIndex];
}

watch(props,
    async () => {
        if (props.loading) {
            setRandomPhrase()
            showSpinner.value = true
        }
        await delayChangeShow()
        if (!props.loading) {
            showSpinner.value = false
            setRandomPhrase()
        }
    },
    { deep: true, immediate: true }
)
</script>

<template>
    <Transition name="fade">
        <div class="custom-spinner" v-if="showSpinner">
            <p class="spinner-text">{{ currentLoaderPhrase }}</p>
            <VueSpinnerComment size="100" color="darkslategray" />
        </div>
    </Transition>
</template>

<sctyle lang="scss">
.custom-spinner {
    z-index: 1100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    span {
        margin-top: 0px;
    }
}

.spinner-text {
    margin-top: 20px;
    font-size: 1.5em;
    color: #343a40;
}

.fade-enter-active,
.fade-leave-active {
    transition: all .5s;
}

.fade-enter-to,
.fade-leave-to {
    opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transition-duration: .5s;
}
</sctyle>