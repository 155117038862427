<script setup>
import { computed, ref } from 'vue'
import Datapicker from "vue3-datepicker";
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import axios from "axios";
import { showErrors } from '@/common/showErrors';
import FileSaver from "file-saver";
import UIButton from '../UI/UIButton.vue';

const reportForm = ref({
    from_dt: new Date(),
    to_dt: new Date()
})

const formattedFrom = computed(() => {
    return moment(reportForm.value.from_dt).format('DD-MM-YYYY')
})
const formattedTo = computed(() => {
    return moment(reportForm.value.to_dt).format('DD-MM-YYYY')
})
const downloadReportLink = computed(() => {
    return process.env.VUE_APP_GATE_URL + `/crm3core/export/products/komus?from_dt=${formattedFrom.value}&to_dt=${formattedTo.value}`
})

const isLoadingReport = ref(false)
const getReport = async () => {
    try {
        isLoadingReport.value = true
        const { data } = await axios.get(downloadReportLink.value,
            { responseType: 'blob', headers: { 'authorization': 'Bearer ' + localStorage.access_token }, })
        FileSaver.saveAs(data, `Отчёт комус с ${formattedFrom.value} по ${formattedTo.value}.xlsx`);

    } catch (error) {
        showErrors(error)
    } finally {
        isLoadingReport.value = false
    }
}
const pageBreadcrumbs = ref([
    {
        title: 'Отчёты',
        href: '/reports/komus'
    },
    {
        title: 'Комус',
        href: '/reports/komus'
    },
])
</script>


<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Отчёт Комус</h1>
                    </div>
                </div>
            </div>
        </div>
        <!-- header off -->
        <!-- content on -->
        <section class="content">
            <div class="container-fluid">
                <UIBreadCrumbs :items="pageBreadcrumbs" />
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-search mr-2"></i>Поиск
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group mb-0">
                                        <label for="" class="col-form-label pb-1">От</label>
                                        <Datapicker class="form-control form-control-sm" v-model="reportForm.from_dt"
                                            :locale="ru" input-format='dd.MM.yyyy' format="dd.MM.yyyy" typeable
                                            :upperLimit="reportForm.to_dt" />
                                    </div>
                                    <div class="form-group mb-0 ml-2">
                                        <label for="" class="col-form-label pb-1">До</label>
                                        <Datapicker class="form-control form-control-sm" v-model="reportForm.to_dt"
                                            :lowerLimit="reportForm.from_dt" :locale="ru" input-format='dd.MM.yyyy'
                                            typeable />
                                    </div>
                                    <div class="form-group mb-0 ml-3 d-flex align-items-end">
                                        <UIButton
                                            class="btn btn-primary waves-effect waves-light py-0 mt-auto form-control-sm"
                                            @click="getReport" :disabled="isLoadingReport" :isLoading="isLoadingReport">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true" v-if="buttonActive"></span>
                                            Скачать
                                        </UIButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
