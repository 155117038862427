<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import { getFormattedPhoneNumber } from '@/lib/utils'

const props = defineProps({
    addresses: {
        type: Array,
        required: true,
        default: () => []
    }
})

const emits = defineEmits(['add-address', 'edit-address', 'remove-address'])

const activeBtnShowFullTable = ref(false)
const minCountForOpenButton = 5
const showFullTable = computed(() => {
    if (props.addresses.length < minCountForOpenButton) {
        return true
    } else {
        return activeBtnShowFullTable.value
    }
})

const addressesForShow = computed(() => {
    if (showFullTable.value) {
        return props.addresses
    } else {
        return props.addresses.slice(0, 2)
    }
})
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1 d-flex">
            <span>Адреса</span>
            <button type="submit" class=" ml-auto btn btn-sm btn-primary waves-effect waves-light"
                title="Добавить заказ на этот адрес" @click="emits('add-address')"><i
                    class="fas fa-plus mr-2"></i>Добавить адрес</button>
        </div>
        <div class="card-body p-0 position-relative">
            <table class="table table-hover">
                <tbody>
                    <tr v-for="address in addressesForShow" :key="address.id"
                        :class="{ 'address--removed': address.delete_label || address.deleted_at }">
                        <td class="col-auto"><b v-if="address.is_primary">{{ address.address_source_string }}</b><span
                                v-else>{{
                                    address.address_source_string }}</span></td>
                        <td class="col-2"><template v-if="address.phone">{{ getFormattedPhoneNumber(address.phone) ?? ''
                                }}</template>
                        </td>
                        <td class="col-2">
                            <div class="form-row">
                                <button type="submit" :disabled="address.delete_label || address.deleted_at"
                                    class="btn btn-sm btn-primary waves-effect waves-light ml-auto"
                                    title="Редактировать" @click="emits('edit-address', address.id)"><i
                                        class="fas fa-edit"></i></button>
                                <button type="submit" :disabled="address.delete_label || address.deleted_at"
                                    class="btn btn-sm btn-primary waves-effect waves-light ml-2" title="Скрыть"
                                    @click="emits('remove-address', address.id)"><i
                                        class="fas fa-eye-slash"></i></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="show-full-table-btn--container"  v-if="props.addresses.length >= minCountForOpenButton">
                <button class="show-full-table-btn text-primary" @click="activeBtnShowFullTable = !activeBtnShowFullTable">
                    <b>{{ activeBtnShowFullTable ? 'Скрыть' : 'Показать всё' }}</b>
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.address--removed {
    opacity: .5;

    &:hover {
        opacity: .5;
        background-color: #fff;
    }
}

.show-full-table-btn--container {
    // position: absolute;
    width: 100%;
    padding: 8px;
    background: rgb(166,166,166);
    background: linear-gradient(0deg, rgba(166,166,166,.7) 0%, rgba(255,255,255,.0) 100%);
    // bottom: 0px;
    // left: 50%;
    // transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    .show-full-table-btn {
        border: none;
        background: none;
        font-size: 1rem;
    }
}
</style>