<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import Datapicker from "vue3-datepicker";
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import UISelect from '@/components/UI/UISelect.vue';
import { VueSpinnerComment } from "vue3-spinners";


const props = defineProps({
    orders: {
        type: Array,
        required: false,
        default: () => []
    },
    filters: {
        type: Object,
        required: true,
        default: () => ({})
    },
    customer: {
        type: Object,
        required: true,
        default: () => ({})
    },
    loading: {
        type: Boolean,
        required: false,
        default: () => false
    },
    isFinishedOrderRequest: {
        type: Boolean,
        required: false,
        default: () => true
    }
})
const emits = defineEmits(['update:filters', 'search'])

const activeBtnShowFullTable = ref(false)
const minCountForOpenButton = 5
const showFullTable = computed(() => {
    if (props.orders.length < minCountForOpenButton) {
        return true
    } else {
        return activeBtnShowFullTable.value
    }
})

const ordersForShow = computed(() => {
    if (showFullTable.value) {
        return props.orders
    } else {
        return props.orders.slice(0, 2)
    }
})
</script>

<template>
    <div class="card">

        <div class="card-header alert-info p-2 pt-1 pb-1">
            Статистика заказов
        </div>
        <div class="card-body pb-0 px-2 pt-2">
            <div class="form-row">
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    С
                </div>
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    <Datapicker class="form-control fix-datepicker--class form-control-sm d-inline-block" :modelValue="filters.date_from"
                        @update:model-value="emits('update:filters', { ...props.filters, date_from: $event })"
                        :locale="ru" input-format='dd.MM.yyyy' position="left" typeable clearable />
                </div>
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    По
                </div>
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    <Datapicker class="form-control fix-datepicker--class form-control-sm d-inline-block" :modelValue="filters.date_to"
                        @update:model-value="emits('update:filters', { ...props.filters, date_to: $event })"
                        :locale="ru" input-format='dd.MM.yyyy' typeable clearable />
                </div>
                <UISelect class="mb-0 col-md-3 col-xl-3" :modelValue="props.filters.address_id"
                    :options="customer.addresses" sm clearable clearOptionTitle="Адрес не важен"
                    :mapOptions="(option) => ({ title: option.address_source_string, value: option.id })"
                    @update:model-value="emits('update:filters', { ...props.filters, address_id: $event })" />
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    <button type="submit"
                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light"
                        @click="emits('search')">Найти</button>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-lg-12 col-md-12">
                    <div class="alert alert-info alert-dismissible p-1">
                        Заказов: {{ props.orders.length }}. Бутылей привезено: 0 (4) шт. Бутылей возврашено: 0 шт.
                        Бутылей у клиента: 0
                        шт.<br />
                        Долги: Долгов нет
                    </div>
                </div>
            </div>
            <div class="row pt-2 position-relative">
                <Transition name="fade">
                    <div class="order-spinner" v-if="loading">
                        <p class="order-spinner__text">Загружаем статистику заказов</p>
                        <VueSpinnerComment size="100" color="darkslategray" />
                    </div>
                </Transition>
                <div class="col-lg-12 col-md-12">
                    <table v-if="props.orders.length" class="table table-hover" :class="{ 'loading': loading }">
                        <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Статус</th>
                                <th>Адрес / заказа</th>
                                <th>Прив</th>
                                <th>Верн</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in ordersForShow" :key="order.id">
                                <td class="col-auto">{{ moment(order.delivery_date).format('DD.MM.YYYY') }}</td>
                                <td class="col-auto">{{ order?.status_id?.name }}</td>
                                <td class="col-auto">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            {{ order?.address?.addressString }}
                                        </div>
                                    </div>
                                    <div class="row font-small-1" v-for="item in order.items" :key="item.id">
                                        <div class="col-lg-7 col-md-7">
                                            {{ item?.product?.name }}
                                        </div>
                                        <div class="col-lg-auto col-md-auto">
                                            {{ item.quantity }} шт / {{ item.currentCost }}&nbsp;Р / {{ item.totalAmount
                                            }}&nbsp;Р
                                        </div>
                                        <div class="col-lg-auto col-md-auto">
                                            {{ item.paymentType.name }}
                                        </div>
                                    </div>
                                </td>
                                <td class="col-auto">{{ order.total_bottles }}</td>
                                <td class="col-auto">{{ order.bottles_return }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <p v-if="!props.orders.length && !loading && isFinishedOrderRequest" class="text-center mt-5 mb-5">
                        <b>Заказов ещё не было</b></p>
                </div>
            </div>
        </div>
        <div class="show-full-table-btn--container" v-if="props.orders.length >= minCountForOpenButton">
            <button class="show-full-table-btn text-primary" @click="activeBtnShowFullTable = !activeBtnShowFullTable">
                <b>{{ activeBtnShowFullTable ? 'Скрыть' : 'Показать всё' }}</b>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.table.loading {
    opacity: .5;

    tr:hover {
        background-color: #fff;
    }
}

.order-spinner {
    z-index: 1099;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .order-spinner__text {
        margin-bottom: 0px;
        margin-top: 20px;
        font-size: 1.5em;
        color: #343a40;
    }
}


.show-full-table-btn--container {
    // position: absolute;
    width: 100%;
    padding: 8px;
    background: rgb(166, 166, 166);
    background: linear-gradient(0deg, rgba(166, 166, 166, .7) 0%, rgba(255, 255, 255, .0) 100%);
    // bottom: 0px;
    // left: 50%;
    // transform: translate(-50%, 0);
    display: flex;
    justify-content: center;

    .show-full-table-btn {
        border: none;
        background: none;
        font-size: 1rem;
    }
}
</style>

<style>
.fix-datepicker--class {
    width: auto;
}
</style>