<script setup>
import { ref, defineEmits, defineProps } from "vue";
import api from "@/common/api";
import CustomersTable from "@/components/Customers/Tables/CustomersTable.vue"
import CustomerFilters from "@/components/Customers/CustomerFilters.vue"
import { searchClientsApiURLs } from '@/lib/auxiliary/customerSearch'
import { minLength, helpers, or } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getUnMaskedPhone, removeEmptyFromObject } from "@/lib/utils";

const isLoading = ref(false)

const emits = defineEmits('update:modelValue')
const props = defineProps({
    currentCustomer: { type: Object, required: true, default: () => ({}) }
})
const getFormattedFilters = () => {
    const clientSearchFormCopy = structuredClone(clientSearchForm.value)
    if (searchBy.value === 'phone') {
        clientSearchFormCopy.phone = getUnMaskedPhone(clientSearchFormCopy.phone)
    }
    for (const key in clientSearchFormCopy) {
        if (clientSearchFormCopy[key] === '') {
            delete clientSearchFormCopy[key]
        }
    }
    return removeEmptyFromObject(clientSearchFormCopy)
}

const fetchClientsAsync = async (searchBy = 'phone') => {
    try {
        clientFormValidator.value.$validate()
        if (clientFormValidator.value.$errors.length) {
            throw { clientValidateError: 'Проверьте заполнение полей' }
        }
        isLoading.value = true
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + searchClientsApiURLs[searchBy], { params: getFormattedFilters() })
        data.data = data.data.filter(client => client.id !== props.currentCustomer.id) // remove current custom2006
        
        clients.value = data.data;
    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

const searchBy = ref('phone')
const clientSearchForm = ref({
    phone: '',
    last_name: '',
    first_name: '',
    patronymic: '',
    company: '',
    street: '',
    water_company_id: 1,
    distribution_id: null,
    search: ''
})
const filterErrors = ref([])
const minFieldLength = 2
const clientFormValidationRules = {
    last_name: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'name')) },
    first_name: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'name')) },
    patronymic: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'name')) },
    phone: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'phone')) },
    company: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'company')) },
    address: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'address')) },
    contract_number: { minLength: helpers.withMessage('Требуется больше символов', or(minLength(minFieldLength), searchBy.value !== 'contract_number')) }
}
const clientFormValidator = useVuelidate(clientFormValidationRules, clientSearchForm)

const clients = ref([])
</script>

<template>
    <div>
        <div class="content-header">
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="container-xl ml-0">
                            <CustomerFilters :modelValue="{ ...clientSearchForm, water_company_id: props.currentCustomer.water_company_id }"
                                @update:modelValue="clientSearchForm = $event" v-model:searchBy="searchBy"
                                :isLoadingSearchBtn="isLoading" :validator="clientFormValidator" disableWaterCompany
                                @search="fetchClientsAsync" @new-error="filterErrors = $event" />
                            <div class="customer-table__container">
                                <CustomersTable :clients="clients" referralSearch
                                    @choose="emits('update:modelValue', $event)" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<style>
.customer-table__container {
    max-height: 500px;
    overflow: auto;
    width: 1200px;
    max-width: 100%;
}
</style>
