<template>
    <div class="ui-textarea form-group" :class="props.class ? props.class : 'col-md-2 col-xl-2'">
        <label :for="UITextarea?.id" class="col-form-label" :class="{ 'pb-1': props.sm }">{{ props.label }}</label>
        <textarea v-uid v-tooltip="errors.length ? errors[0].$message : null" ref="UITextarea" type="text"
            class="form-control" :class="{ 'form-control-sm': props.sm, 'has-error': props.errors.length }" :value="props.modelValue"
            @input="emits('update:modelValue', $event.target.value)" :maxlength="maxlength"></textarea>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { vTooltip } from 'floating-vue'

const props = defineProps({
    modelValue: { type: [String, Number, null], required: true },
    label: { type: String, required: false, default: () => '' },
    minLength: { type: Number, required: false, default: () => 0 },
    required: { type: Boolean, required: false, default: () => false },
    errors: { type: Array, required: false, default: () => [] },
    validate: { type: Boolean, required: false, default: () => false },
    maxlength: { type: [Number, String], required: false, default: () => undefined },
    sm: { type: Boolean, required: false, default: () => false },
    class: { type: [String, null], required: false, default: () => null }
})

const emits = defineEmits(['update:modelValue', 'new-error'])

const UITextarea = ref(null)
</script>

<style lang="scss" scoped>
.ui-textarea {
    position: relative;
    transition-duration: .5s;

    &:focus {
        .form-control {
            height: 100px;
        }
    }
    .has-error{ 
        border-color: red;
        background-color: #ff00001a;
    }
}
</style>