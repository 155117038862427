export default class BaseOrderFilter {
    water_company_id = 1
    date_from = new Date()
    date_to = new Date()
    sort = 'name,asc'
    status_id = null
    search = null
    delivery_times = []
    no_coordinates = null
    no_check = null
    warehouse_id = null
    manager_id = null
    driver_id = null
    source_id = null
    edo = null
    wand = null
    payment_type_id = null
    is_checked_by_manager = null
    distribution_id = null

    constructor(orderFilter) {
        this.water_company_id = orderFilter?.water_company_id ?? 1
        this.date_from = orderFilter?.date_from ?? new Date()
        this.date_to = orderFilter?.date_to ?? new Date()
        this.sort = orderFilter?.sort ?? 'name,asc'
        this.status_id = orderFilter?.status_id ?? null
        this.search = orderFilter?.search ?? null
        this.delivery_times = orderFilter?.search ?? []
        this.no_coordinates = orderFilter?.no_coordinates ?? null
        this.no_check = orderFilter?.no_check ?? null
        this.warehouse_id = orderFilter?.warehouse_id ?? null
        this.manager_id = orderFilter?.manager_id ?? null
        this.driver_id = orderFilter?.driver_id ?? null
        this.source_id = orderFilter?.source_id ?? null
        this.edo = orderFilter?.edo ?? null
        this.wand = orderFilter?.wand ?? null
        this.payment_type_id = orderFilter?.payment_type_id ?? null
        this.is_checked_by_manager = orderFilter?.is_checked_by_manager ?? null
        this.distribution_id = orderFilter?.distribution_id ?? null
    }
}