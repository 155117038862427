<script setup>
import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref} from "vue";
import api from "@/common/api";
import fetchDataWithLocalStorage from "@/common/localStorage";
import Datapicker from "vue3-datepicker";
import ru from 'date-fns/locale/ru';
import {showErrors} from "@/common/showErrors";
import format from 'date-fns/format';
import DeliveryRequests from "@/components/Orders/DeliveryRequests.vue";
import {useToast} from 'vue-toastification';
import DeliveryRequestOrders from "@/components/Orders/DeliveryRequestOrders.vue";
import DeliveryRequestSummary from "@/components/Orders/DeliveryRequestSummary.vue";
import {parse} from "date-fns";
import DeliveryRequestInfo from "@/components/Orders/DeliveryRequestInfo.vue";

const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const infoDR = ref({});

const wcId = ref(1);
const warehouseId = ref(null);
const warehouses = ref([]);
const intervals = ref([]);
const intervalsStr = ref('');
const intervalsSelected = ref([]);
const date = ref(new Date());
const statuses = ref([]);
const initiators = ref([]);
const orders = ref([]);
const summary = ref([]);
const summaryKey = ref(0);
const selectedDeliveryRequest = ref(null);
const deliveryRequests = ref([]);
const searchActive = ref(false);
const formattedDate = computed(() => format(date.value, 'yyyy-MM-dd'));
const formatDate = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(date, 'dd.MM.yyyy');
};
const statements = ref([])
const selectedStatementId = ref(null)
const getActiveStatements = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statements/active').then(function (response) {
    statements.value = response.data.data
  }).catch(function (error) {
    showErrors(error)
  });
}


const getWarehouses = async () => {
  warehouseId.value = null
  warehouses.value = await fetchDataWithLocalStorage('warehouses.wcId:' + wcId.value, 1000 * 60 * 10, process.env.VUE_APP_GATE_URL + '/els/api/v1/warehouses?waterCompanyId=' + wcId.value)
}

const getIntervals = async () => {
  intervals.value = null
  intervals.value = await fetchDataWithLocalStorage('intervals.wcId:' + wcId.value, 1000 * 60 * 10, process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/delivery-request/interval-list/' + wcId.value);
}

const getIntervalsStr =  () => {
  const selectedIntervals = intervalsSelected.value.length > 0 ? intervalsSelected.value : intervals.value;
  intervalsStr.value =  selectedIntervals.map(interval => interval.name).join(', ');
}

const getStatuses = async () => {
  statuses.value = await fetchDataWithLocalStorage('statuses', 1000 * 60 * 10, process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/product/status-list');
}

const getInitiators = async () => {
  initiators.value = await fetchDataWithLocalStorage('initiators', 1000 * 60 * 10, process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/product/initiator-list');
}

const refreshSummary = () => {
  summaryKey.value++;
  getSummary(selectedDeliveryRequest.value);
  getOrders(selectedDeliveryRequest.value);
}

const getDeliveryRequest = async () => {
  searchActive.value = true;
  orders.value = [];
  let searchSet = {}
  searchSet['waterCompanyId'] = wcId.value
  searchSet['date'] = formattedDate.value
  if (warehouseId.value) {
    searchSet['warehouseId'] = warehouseId.value
  }
  if (intervalsSelected.value.length > 0) {
    searchSet['intervals'] = intervalsSelected.value.map(function (interval) {
      return interval.id;
    });
  }
 await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/delivery-requests', searchSet).then(function (response) {
    deliveryRequests.value = response.data.data
    if (deliveryRequests.value.length === 0) {
      toast.info("Заявки не найдены")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
  searchActive.value = false;
}

const getOrders = async (id) => {
  selectedDeliveryRequest.value = id;
  const intervals = deliveryRequests.value.find(dr => dr.id === id).intervals;
  const intervalNames = intervals.map(interval => interval.name);
  const intervalNamesString = intervalNames.join(', ');
  infoDR.value = {
    name: deliveryRequests.value.find(dr => dr.id === id).staffList.driver.fullName,
    date: formatDate(deliveryRequests.value.find(dr => dr.id === id).date),
    interval: intervalNamesString
  }
  selectedStatementId.value = deliveryRequests.value.find(dr => dr.id === id).statementId
 await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/delivery-request/' + id + '/orders').then(function (response) {
    orders.value = response.data.data
    if (orders.value.length === 0) {
      toast.info("Заказы не найдены")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
}

const getSummary = async (id) => {
 await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/delivery-request/' + id + '/summary').then(function (response) {
    summary.value = response.data.data
    if (summary.value.length === 0) {
      toast.info("Сводка не найдена")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
}

getWarehouses();
getIntervals();

onBeforeMount(() => {
  getActiveStatements();
});
const pageBreadcrumbs = ref([
  {
    title: 'Заказы',
    href: '/orders'
  },
  {
    title: 'Доставленные',
    href: '/orders/delivered'
  },
])
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Водители и экспедиторы</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <UIBreadCrumbs :items="pageBreadcrumbs" />
        <div class="row">
          <div class="col-lg-3 col-md-3">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-filter mr-1"></i>Фильтр
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="wcId" class="col-form-label">Компания</label>
                    <select id="wcId" name="wcId" class="form-control" autocomplete="off"
                            v-model="wcId" @change="(getWarehouses($event), getIntervals($event))">
                      <option value="1">Хваловские Воды (С-Пб)</option>
                      <option value="2">Хваловские Воды (Мск)</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="status_id" class="col-form-label">Склады</label>
                    <select name="status_id" id="status_id" class="form-control" v-model="warehouseId">
                      <option :value="null" :selected=true v-if="wcId">Все</option>
                      <option v-for="w in warehouses" :key="w.id" :value="w.id">
                        {{ w.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата</label>
                    <Datapicker
                        class="form-control"
                        v-model="date"
                        :locale="ru"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="type-id" class="col-form-label">Интервал</label>
                    <div class="form-group" v-if="intervals">
                      <div class="form-check" v-for="interval in intervals" :key="interval.id">
                        <input class="form-check-input" type="checkbox" v-model="intervalsSelected" :true-value="[]" :value="interval">
                        <label class="form-check-label">{{ interval.name }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <button class="btn btn-primary waves-effect waves-light" @click="(getDeliveryRequest(), getIntervalsStr())">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="searchActive"></span>
                      Найти
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <DeliveryRequests
                v-if="deliveryRequests.length > 0"
                :data="deliveryRequests"
                @emit-id="(getOrders($event), getSummary($event), getStatuses($event), getInitiators($event))"
            />
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="card" v-if="deliveryRequests.length > 0">
              <div class="card-body">
                <router-link
                    :to="{name: 'DeliveredOrdersStats', query: {date: formattedDate, intervals: JSON.stringify(intervalsSelected.map(function(interval) {return interval.id;})), warehouseId: warehouseId, wcId: wcId, intervalsStr: intervalsStr}}"
                    target="_blank">Смотреть отчёт
                </router-link>
              </div>
            </div>

            <div v-if="deliveryRequests.length > 0">
              <DeliveryRequestInfo :info="infoDR" />
              <DeliveryRequestSummary :summary="summary" v-if="orders.length > 0 && summary.totalBottles" :key="summaryKey" />
              <DeliveryRequestOrders
                  v-for="order in orders"
                  :item="order"
                  :key="order.id"
                  :statuses="statuses"
                  :initiators="initiators"
                  @update-summary="refreshSummary"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>

</template>




