import { helpers, minLength } from '@vuelidate/validators'

const StatusConfirmedId = 4 //Подтверждён
export default class BaseOrder {
    water_company_id = 1
    order_comment = null
    bottles_return = 0
    need_check = false
    hang_promo = true
    need_pass = false
    leave_at_the_door = false
    window_from_dt = null
    window_to_dt = null
    time_id = null
    delivery_type_id = null
    cart = []
    customer_id = null
    status_id = StatusConfirmedId
    address_id = null
    id = null
    delivery_job_type_id=null
    customer_1c_connection = null

    constructor(order) {
        this.order_comment = order?.order_comment ?? null
        this.bottles_return = order?.bottles_return ?? 0
        this.need_check = order?.need_check ?? false
        this.hang_promo = order?.hang_promo ?? true
        this.need_pass = order?.need_pass ?? false
        this.leave_at_the_door = order?.leave_at_the_door ?? false
        if (order?.items) {
            // В мапе ответа от бека винить Давида, который винит Данила
            this.cart = order.items.map(item => {
                return {
                    product_id: item.product.id,
                    qty: item.quantity,
                    price: item.currentCost,
                    payment_type_id: item.paymentType.id,
                    sell_type_id: item.sellType.id,
                    isFixedPrice: false
                }
            })
        }
        this.water_company_id = order?.water_company_id ?? 1
        this.window_from_dt = order?.delivery_date ? ((order?.delivery_date ?? '') + ' ' + (order?.slot?.start ?? '')) : null
        this.window_to_dt = order?.delivery_date ? ((order?.delivery_date ?? '') + ' ' + (order?.slot?.end ?? '')) : null
        this.time_id = order?.delivery_time?.id ?? null
        this.delivery_type_id = order?.delivery_type_id ?? null
        this.customer_id = order?.customer?.id ?? null
        this.status_id = order?.status_id?.id ?? StatusConfirmedId
        this.address_id = order?.address?.id ?? null
        this.id = order?.id ?? null
        this.delivery_job_type_id = order?.delivery_job_type_id ?? null
        this.customer_1c_connection = order?.customer_1c_connection ?? null
    }
}


const emptyRuleForExternalErrors = { minLength: helpers.withMessage('Ошибка', minLength(0)) }
// Если поля не будет или оно будет пустое в объекте правил валидатора, то дальнейшая обработка ошибок невозможна(внутренняя ошибка vuelidate)
export const OrderFieldsWithEmptyValidation = {
    delivery_job_type_id: emptyRuleForExternalErrors, 
    driver_id: emptyRuleForExternalErrors,
    order_comment: emptyRuleForExternalErrors,
    need_pass: emptyRuleForExternalErrors,
    hang_promo: emptyRuleForExternalErrors,
    need_check: emptyRuleForExternalErrors,
    leave_at_the_door: emptyRuleForExternalErrors,
}
