<script setup>
import { defineProps, defineEmits, computed, ref } from "vue"
import UICheckbox from "../UI/UICheckbox.vue";
import UITextarea from "../UI/UITextarea.vue";
import UIInput from "../UI/UIInput.vue";
import UISelect from "../UI/UISelect.vue";
import UIModal from "../UI/UIModal.vue";
import CustomerSearchInput from "../Customers/CustomerSearchInput.vue";

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    },
    bindings: {
        type: Array,
        required: false,
        default: () => []
    },
    fetchingBinding: {
        type: Boolean,
        required: false,
        default: () => false
    },
    showBindings: {
        type: Boolean,
        required: false,
        default: () => false
    }
})
const emits = defineEmits(['update:modelValue', 'add-binding'])

const inputHandler = (fieldName, newValue) => {
    const modelValueCopy = structuredClone(props.modelValue)
    modelValueCopy[fieldName] = newValue
    emits('update:modelValue', modelValueCopy)
}


const getBindingsByCurrentAddress = computed(() => {
    const result = props.bindings.reduce((acc, binding) => {
        if (binding.address.id === props.modelValue.address_id) {
            acc.push({
                title: `Плательщик: ${binding.payer.name} (${binding.payer.inn}) | Грузополучатель: ${binding.consignee.name} (${binding.consignee.inn})`,
                value: binding.id
            })
        }
        return acc
    }, [])
    return result
})
const showBindingsModal = ref(false)
const bindingForm = ref({
    payer_id: null,
    consignee_id: null
})
const addBindingHandler = () => {
    emits('add-binding', bindingForm.value)
    showBindingsModal.value = false
    bindingForm.value = {
        payer_id: null,
        consignee_id: null
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-question-circle mr-2"></i>Дополнительно</div>
        <div class="card-body p-2">
            <div class="form-row">
                <UICheckbox label="Нужен чек" class="mb-0 col-md-5 col-xl-5 ml-4"
                    :modelValue="props.modelValue.need_check" @update:modelValue="inputHandler('need_check', $event)" />
                <div class="form-group mb-0 col-md-4 col-xl-4">
                    <UICheckbox label="Повесить промо на
                            бутыль" :modelValue="props.modelValue.hang_promo"
                        @update:modelValue="inputHandler('hang_promo', $event)" />
                    <UICheckbox label="Нужен пропуск" :modelValue="props.modelValue.need_pass"
                        @update:modelValue="inputHandler('need_pass', $event)" />
                    <UICheckbox label="Оставить у двери" :modelValue="props.modelValue.leave_at_the_door"
                        @update:modelValue="inputHandler('leave_at_the_door', $event)" />
                </div>
                <UIInput label="Бутылей вернут" class="mb-0 col-md-2 col-xl-2"
                    :modelValue="props.modelValue.bottles_return"
                    @update:modelValue="inputHandler('bottles_return', +$event)" maxlength="5" onlyNumber />
            </div>
            <div class="form-row">
                <UITextarea :modelValue="props.modelValue.order_comment"
                    @update:modelValue="inputHandler('order_comment', $event)" class="mb-0 col-md-12 col-xl-12"
                    label="Комментарий к заказу" maxlength="300">
                </UITextarea>
            </div>
            <template v-if="showBindings">
                <div class="form-row">
                    <UISelect label="Плательщик и грузополучатель" :readonly="fetchingBinding" class="col-10 mb-0"
                        :modelValue="modelValue.customer_1c_connection" :options="getBindingsByCurrentAddress"
                        placeholder="Выберите связку" clearable clearOptionTitle="Не выбрано"
                        @update:model-value="inputHandler('customer_1c_connection', $event)" />
                    <button @click="showBindingsModal = true" :disabled="fetchingBinding"
                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light col-2 mt-auto">Добавить
                        новую связку</button>
                </div>
                <UIModal v-model:show="showBindingsModal" title="Новая связка">
                    <CustomerSearchInput :waterCompanyId="waterCompanyId" bindings v-model="bindingForm.payer_id"
                        label="Плательщик" class="col-xl-12" />
                    <CustomerSearchInput :waterCompanyId="waterCompanyId" bindings v-model="bindingForm.consignee_id"
                        label="Грузополучатель" class="col-xl-12" />
                    <button @click="addBindingHandler"
                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light col-11 mx-auto py-2 mt-3">Добавить
                        новую связку</button>
                </UIModal>
            </template>
        </div>
    </div>
</template>
