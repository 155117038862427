<script setup>
import UIInput from "../UI/UIInput.vue";
import UISelect from "../UI/UISelect.vue";
import { defineProps, defineEmits, computed, ref, nextTick } from "vue"
import { useParamsStore } from "@/store/params";
import api from "@/common/api";
import { showErrors } from "@/common/showErrors";
import UIModal from "../UI/UIModal.vue";
import ReferralSearch from "../Customers/ReferralSearch.vue";

const clientTypes = computed(() => useParamsStore().clientTypes)

const props = defineProps({
    modelValue: { type: Object, required: true },
    errors: { type: Array, required: false, default: () => [] },
    isMainInfo: { type: Boolean, required: false, default: () => false },
    validator: { type: Object, required: false, default: () => ({}) },
    isEdit: { type: Boolean, required: false, default: () => false },
    paymentTypes: { type: Array, required: true, default: () => [] }
})

const emits = defineEmits(['update:modelValue', 'new-error', 'update_water_company'])

const updateHandler = (field, newValue) => {
    const newModelValue = { ...props.modelValue }
    newModelValue[field] = newValue
    emits('update:modelValue', newModelValue)
}

const companyCities = computed(() => useParamsStore().cities)

const customerSource = ref([])
const fetchCustomerSourceAsync = async () => {
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/filter-options/customer-sources')
        customerSource.value = data.data
    } catch (error) {
        console.error(error)
    }
}
fetchCustomerSourceAsync()

const partners = ref([])
const fetchPartners = async () => {
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/filter-options/distributions')
        partners.value = data.data
    } catch (error) {
        showErrors(error)
    }
}
fetchPartners()
const hasPartnerHandler = (event) => {
    const modelValueCopy = structuredClone(props.modelValue)
    modelValueCopy.hasPartner = event
    if (!event) {
        modelValueCopy.distribution_id = null
        modelValueCopy.distribution_customer_id = null
    }
    emits('update:modelValue', modelValueCopy)
}


const showReferralSearchModal = ref(false)
const choosenReferralName = ref('')
const mainInfoElement = ref(null)
const referralFocusHandler = () => {
    if (props.modelValue?.referral) {
        return
    }
    showReferralSearchModal.value = true
    nextTick(() => {
        window.document.activeElement.blur();
    })
}
const chooseReferralHandler = (referral) => {
    updateHandler('invited_customer_id', referral.id)
    choosenReferralName.value = `${referral.last_name} ${referral.first_name} ${referral.patronymic}`
    showReferralSearchModal.value = false
    // console.log(mainInfoElement, 'mainInfoElement')
    // mainInfoElement.value.click()
}
const referralTitle = computed(() => {
    return props.modelValue?.referral || choosenReferralName.value || ''
})

const hasPartnerVariants = [
    {
        title: 'Да',
        value: true,
    },
    {
        title: 'Нет',
        value: false,
    },
]
</script>

<template>
    <div class="card" v-if="isMainInfo">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-info-circle mr-2"></i>Основная информация
        </div>
        <div class="card-body p-2">
            <div class="form-row">
                <UISelect label="Город" :readonly="modelValue.id" class="mb-0 col-md-4 col-xl-2"
                    :modelValue="modelValue.water_company_id" :options="companyCities"
                    :mapOptions="(city) => ({ title: city.short_name, value: city.id })" sm
                    @update:modelValue="updateHandler('water_company_id', $event); emits('update_water_company', $event)"
                    :errors="validator.water_company_id?.$errors ?? []" />
                <UIInput class="mb-0 col-md-4 col-xl-2" :modelValue="props.modelValue.contract_number" sm maxlength="20"
                    @update:modelValue="updateHandler('contract_number', $event)" label="Номер договора"
                    :errors="validator.contract_number?.$errors ?? []" />
                <UISelect class="mb-0 col-md-4 col-xl-2" label="Тип оплаты" :modelValue="modelValue.payment_type" sm
                    :options="paymentTypes" :mapOptions="(type) => ({ title: type.name, value: type.id })"
                    @update:modelValue="updateHandler('payment_type', $event)"
                    :errors="validator.payment_type?.$errors ?? []" />
                <UISelect class="mb-0 col-md-4 col-xl-2" label="Откуда&nbsp;узнали&nbsp;о&nbsp;нас"
                    :modelValue="modelValue.source" sm :options="customerSource"
                    :mapOptions="(source) => ({ title: source.name, value: source.id })"
                    @update:modelValue="updateHandler('source', $event)" :errors="validator.source?.$errors ?? []" />
                <UIInput class="mb-0 col-md-4 col-xl-2" :modelValue="modelValue.choice" sm maxlength="20"
                    @update:modelValue="updateHandler('choice', $event)" label="Почему&nbsp;выбрали&nbsp;нас"
                    :errors="validator.choice?.$errors ?? []" />
                <div @mouseup="referralFocusHandler" class="form-group  col-md-4 col-xl-2">
                    <label class="col-form-label pb-1">Кто пригласил</label>
                    <span class="mb-0 form-control form-control-sm text-nowrap overflow-hidden text-truncate"> {{
                        referralTitle }}</span>
                </div>
                <UIModal v-model:show="showReferralSearchModal" title="Поиск реферала">
                    <ReferralSearch @update:modelValue="chooseReferralHandler" :currentCustomer="modelValue" />
                </UIModal>
            </div>
            <div class="form-row">
                <UISelect class="mb-0 col-md-4 col-xl-2" label="Наличие партнёра" sm :modelValue="modelValue.hasPartner"
                    @update:modelValue="hasPartnerHandler" :options="hasPartnerVariants" :readonly="modelValue.id"
                    :errors="validator.hasPartner?.$errors ?? []" />
                <UISelect v-if="modelValue.hasPartner" class="mb-0 col-md-4 col-xl-2" :options="partners"
                    :mapOptions="(partner) => ({ title: partner.name, value: partner.id })" label="Партнёр"
                    :modelValue="props.modelValue.distribution_id" :errors="validator.distribution_id?.$errors ?? []"
                    @update:modelValue="updateHandler('distribution_id', $event)" :readonly="modelValue.id" />
                <UIInput v-if="modelValue.hasPartner" class="mb-0 col-md-4 col-xl-2"
                    :modelValue="modelValue.distribution_customer_id" sm maxlength="20"
                    @update:modelValue="updateHandler('distribution_customer_id', $event)" label="ID клиента партнёра"
                    :errors="validator.distribution_customer_id?.$errors ?? []" :readonly="modelValue.id" />
            </div>
        </div>
    </div>

    <div class="card" ref="mainInfoElement">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-user mr-2"></i>Клиент
        </div>
        <div class="card-body p-2">
            <div class="form-row">
                <UISelect class="mb-0 col-md-2 col-xl-2" label="Тип" :modelValue="modelValue.type" sm
                    :options="clientTypes" @update:modelValue="updateHandler('type', +$event)" newType
                    :readonly="modelValue.id" :errors="validator.type?.$errors ?? []" />
                <UIInput class="mb-0 col-md-2 col-xl-2" :modelValue="props.modelValue.phone" sm
                    @update:modelValue="updateHandler('phone', $event)" label="Телефон" :maxlength="11" onlyNumber
                    :errors="validator.phone?.$errors ?? []" :readonly="modelValue.id" />
                <UIInput class="mb-0 col-md-2 col-xl-2" :modelValue="props.modelValue.phone2" sm
                    @update:modelValue="updateHandler('phone2', $event)" label="Доп.&nbsp;телефон" :maxlength="11"
                    onlyNumber :errors="validator.phone2?.$errors ?? []" />
                <UIInput class="mb-0 col-md-3 col-xl-3" :modelValue="props.modelValue.phone_from_call" sm
                    @update:modelValue="updateHandler('phone_from_call', $event)" label="Откуда&nbsp;звонили(телефон)"
                    :maxlength="11" onlyNumber :errors="validator.phone_from_call?.$errors || []" />
                <UIInput class="mb-0 col-md-3 col-xl-3" :modelValue="props.modelValue.fixed_water_price" sm onlyNumber
                    maxlength="5" :maxValue="4999" @update:modelValue="updateHandler('fixed_water_price', $event)"
                    label="Фикс. цена" :errors="validator.fixed_water_price?.$errors || []" />
            </div>
            <div class="form-row">
                <UIInput class="mb-0 col-md-4 col-xl-4" :modelValue="props.modelValue.last_name" sm maxlength="100"
                    @update:modelValue="updateHandler('last_name', $event)" label="Фамилия"
                    :errors="validator.last_name?.$errors ?? []" />
                <UIInput class="mb-0 col-md-4 col-xl-4" :modelValue="props.modelValue.first_name" sm maxlength="100"
                    @update:modelValue="updateHandler('first_name', $event)" label="Имя"
                    :errors="validator.first_name?.$errors ?? []" />
                <UIInput class="mb-0 col-md-4 col-xl-4" :modelValue="props.modelValue.patronymic" sm maxlength="100"
                    @update:modelValue="updateHandler('patronymic', $event)"
                    :errors="validator.patronymic?.$errors ?? []" label="Отчество" />
            </div>
            <!-- Если Юр.лицо-->
            <div v-if="modelValue.type === 1" class="form-row">
                <UIInput class="mb-0 col-md-4 col-xl-4" :modelValue="props.modelValue.company" sm maxlength="300"
                    @update:modelValue="updateHandler('company', $event)" label="Компания"
                    :errors="validator.company?.$errors ?? []" />
                <UIInput class="mb-0 col-md-3 col-xl-3" :modelValue="props.modelValue.company_inn" sm maxlength="12"
                    @update:modelValue="updateHandler('company_inn', $event)" onlyNumber label="ИНН"
                    :errors="validator.company_inn?.$errors ?? []" />
                <UIInput class="mb-0 col-md-5 col-xl-5" :modelValue="props.modelValue.company_description || ''" sm
                    maxlength="300" @update:modelValue="updateHandler('company_description', $event)"
                    :errors="validator.company_description?.$errors ?? []" label="Описание" />
            </div>
        </div>
    </div>
</template>