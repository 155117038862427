<script setup>
import {onBeforeMount, ref} from "vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import router from "@/router";
import UIInput from "@/components/UI/UIInput.vue";
import {useRoute} from "vue-router";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";
import {format} from "date-fns";
import {inputMethods, operationTypes} from "@/components/Transaction/types";
import {getStaffById} from "@/lib/api/staff";

const route = useRoute();
const toast = useToast();
const staff = ref(null);
const activeStatements = ref([]);
const defaultOperationType = 'debit';
const defaultInputMethod = 'office';

const cashTransaction = ref({
  staffId: route.params.staffId,
  operationType: defaultOperationType,
  amount: null,
  moneyType: 'cash',
  inputMethod: defaultInputMethod,
  reason: null,
  transactionDate: null,
  localTransactionDate: null,
  statementId: null
});

const metalTransaction = ref({
  staffId: route.params.staffId,
  operationType: defaultOperationType,
  amount: null,
  moneyType: 'metal',
  inputMethod: defaultInputMethod,
  reason: null,
  transactionDate: null,
  localTransactionDate: null,
  statementId: null
});

const fetchData = async () => {
  try {
    const [staffData, statementsData] = await Promise.all([
      getStaffById(route.params.staffId),
      api.get(`${process.env.VUE_APP_GATE_URL}/salary/api/v1/statements/active`)
    ]);
    staff.value = staffData;
    activeStatements.value = statementsData.data.data;
  } catch (error) {
    showErrors(error);
  }
};

const createTransactions = async () => {
  try {
    const transactions = [cashTransaction.value, metalTransaction.value]
        .filter(transaction => transaction.amount && transaction.amount !== 0)
        .map(transaction => ({
          ...transaction,
          transactionDate: transaction.localTransactionDate
              ? format(new Date(transaction.localTransactionDate), 'yyyy-MM-dd HH:mm:ss')
              : null
        }));

    if (transactions.length === 0) {
      toast.error('Необходимо заполнить сумму хотя бы для одной транзакции');
      return;
    }

    for (const transaction of transactions) {
      if (!transaction.statementId) {
        toast.error('Необходимо заполнить номер ведомости!');
        return;
      }
      await api.post(`${process.env.VUE_APP_GATE_URL}/salary/api/v1/salary/balance-transactions`,
          transaction
      );
    }

    toast.success('Транзакции успешно созданы');
    router.go(-1);
  } catch (error) {
    showErrors(error);
  }
};

onBeforeMount(fetchData);
</script>

<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Добавить новую транзакцию {{ staff?.firstName }} {{ staff?.lastName }} {{ staff?.patronymic }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Транзакции</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <h3>Наличные (Бумажные деньги)</h3>
                    <div class="form-group">
                      <div>
                        <label>Тип операции</label>
                        <select class="form-control" autocomplete="off" v-model="cashTransaction.operationType">
                          <option v-for="operationType in operationTypes" :value="operationType.value"
                                  :key="operationType.value">{{ operationType.name }}
                          </option>
                        </select>
                      </div>
                      <div>
                        <label>Способ внесения</label>
                        <select class="form-control" autocomplete="off" v-model="cashTransaction.inputMethod">
                          <option v-for="inputMethod in inputMethods" :value="inputMethod.value"
                                  :key="inputMethod.value">{{ inputMethod.name }}
                          </option>
                        </select>
                      </div>
                      <UIInput label="Причина" v-model="cashTransaction.reason" class="col-md-12 col-xl-12"/>
                      <UIInput label="Сумма (Наличные)" v-model="cashTransaction.amount" class="col-md-12 col-xl-12" type="number"/>
                      <div>
                        <label>Номер ведомости</label>
                        <select
                            class="form-control"
                            autocomplete="off"
                            v-model="cashTransaction.statementId"
                            :disabled="cashTransaction.operationType !== 'debit'"
                        >
                          <option v-for="statement in activeStatements" :value="statement.id" :key="statement.id">
                            {{ statement.id }}
                          </option>
                        </select>
                      </div>
                      <div>
                        <label>Дата</label>
                        <VueDatePicker
                            v-model="cashTransaction.localTransactionDate"
                            class="col-md-12"
                            locale="ru"
                            format="yyyy-MM-dd HH:mm:ss"
                            :placeholder="'Оставьте пустым для использования текущей даты'"
                            :clearable="true"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <h3>Мелочь (Железные деньги)</h3>
                    <div class="form-group">
                      <div>
                        <label>Тип операции</label>
                        <select class="form-control" autocomplete="off" v-model="metalTransaction.operationType">
                          <option v-for="operationType in operationTypes" :value="operationType.value"
                                  :key="operationType.value">{{ operationType.name }}
                          </option>
                        </select>
                      </div>
                      <div>
                        <label>Способ внесения</label>
                        <select class="form-control" autocomplete="off" v-model="metalTransaction.inputMethod">
                          <option v-for="inputMethod in inputMethods" :value="inputMethod.value"
                                  :key="inputMethod.value">{{ inputMethod.name }}
                          </option>
                        </select>
                      </div>
                      <UIInput label="Причина" v-model="metalTransaction.reason" class="col-md-12 col-xl-12"/>
                      <UIInput label="Сумма (Мелочь)" v-model="metalTransaction.amount" class="col-md-12 col-xl-12" type="number"/>
                      <div>
                        <label>Номер ведомости</label>
                        <select
                            class="form-control"
                            autocomplete="off"
                            v-model="metalTransaction.statementId"
                            :disabled="metalTransaction.operationType !== 'debit'"
                        >
                          <option v-for="statement in activeStatements" :value="statement.id" :key="statement.id">
                            {{ statement.id }}
                          </option>
                        </select>
                      </div>
                      <div>
                        <label>Дата</label>
                        <VueDatePicker
                            v-model="metalTransaction.localTransactionDate"
                            class="col-md-12"
                            locale="ru"
                            format="yyyy-MM-dd HH:mm:ss"
                            :placeholder="'Оставьте пустым для использования текущей даты'"
                            :clearable="true"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card-footer">
              <button type="submit" class="btn btn-info" @click="createTransactions">Добавить транзакции</button>
              <button type="submit" class="btn btn-default float-right" @click="router.go(-1)">Назад</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
