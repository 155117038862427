import { helpers, minLength } from '@vuelidate/validators'

export default class BaseAddress {
    address_source_string = ''
    water_company_id = 1
    customer_id = null
    flat = null
    office = null
    code = null
    entrance = null
    floor = null
    korp = null
    latitude = null
    longitude = null
    description = null
    working_hours = null
    lunch_hours = null
    logist_comment = null
    manager_comment = null
    spacing = null
    building = null
    phone = null
    id = null
    is_primary = false

    constructor(address) {
        this.address_source_string = address?.address_source_string ?? ''
        this.water_company_id = address?.water_company_id ?? 1
        this.customer_id = address?.customer_id ?? null
        this.flat = address?.flat ?? null
        this.office = address?.office ?? null
        this.code = address?.code ?? null
        this.entrance = address?.entrance ?? null
        this.floor = address?.floor ?? null
        this.korp = address?.korp ?? null
        this.latitude = address?.latitude ?? null
        this.longitude = address?.longitude ?? null
        this.description = address?.description ?? null
        this.working_hours = address?.working_hours ?? null
        this.lunch_hours = address?.lunch_hours ?? null
        this.logist_comment = address?.logist_comment ?? null
        this.manager_comment = address?.manager_comment ?? null
        this.spacing = address?.spacing ?? null
        this.building = address?.building ?? null
        this.phone = address?.phone ?? null
        this.id = address?.id ?? null
        this.is_primary = address?.is_primary ?? false
    }
} 

const emptyRuleForExternalErrors = { minLength: helpers.withMessage('Ошибка', minLength(0)) }
// Если поля не будет или оно будет пустое в объекте правил валидатора, то дальнейшая обработка ошибок невозможна(внутренняя ошибка vuelidate)
export const AddressFieldsWithEmptyValidation = {
    flat: emptyRuleForExternalErrors,    
    office: emptyRuleForExternalErrors,    
    code: emptyRuleForExternalErrors,    
    entrance: emptyRuleForExternalErrors,    
    floor: emptyRuleForExternalErrors,    
    description: emptyRuleForExternalErrors,    
    korp: emptyRuleForExternalErrors,    
    working_hours: emptyRuleForExternalErrors,    
    lunch_hours: emptyRuleForExternalErrors,    
    spacing: emptyRuleForExternalErrors,    
    manager_comment: emptyRuleForExternalErrors,    
    logist_comment: emptyRuleForExternalErrors,    
    building: emptyRuleForExternalErrors,    
}