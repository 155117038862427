<script setup>
import {computed, ref} from "vue";
import {useUserStore} from "@/store";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";

const toast = useToast();
const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const comment = ref('');
const bottles = ref('');
const reasons = [
  '№1 Отсканирован дубль! — Бутыль уже отсканирована в данном заказе, сканируй следующую',
  '№2 Отсканирован не тот тип воды!',
  '№3 Отсканирован дубль! — Данную бутыль не получится отсканировать, набери в службу поддержки',
  '№4 Отсканирован не тот тип воды! Возьми нужный тип воды',
  '№5 Отсканированный QR не найден!',
  '№6 Все QR отсканированы! Заверши сканирования и отдай товар клиенту',
  'Прочее: поврежден/стерт код',
  'Прочее: нет инета у водителя',
]

const orders = ref([]);
const orderProducts = ref({});
const orderFilter = ref({
  orderNumber: '',
  orderId: '',
  oldId: '',
  phone: ''
});
const buttonActive1 = ref(false);
const buttonActive2 = ref({});

const searchOrders = async () => {
  buttonActive1.value = !buttonActive1.value;
  orderProducts.value = {};
  orders.value = [];
  if (!orderFilter.value.orderNumber && !orderFilter.value.orderId && !orderFilter.value.oldId && !orderFilter.value.phone) {
    buttonActive1.value = !buttonActive1.value;
    return;
  }
  let searchSet = {};
  if (orderFilter.value.orderId) {
    searchSet['orderId'] = orderFilter.value.orderId
  }
  if (orderFilter.value.orderNumber) {
    searchSet['orderNumber'] = orderFilter.value.orderNumber
  }
  if (orderFilter.value.oldId) {
    searchSet['oldId'] = orderFilter.value.oldId
  }
  if (orderFilter.value.phone) {
    searchSet['phone'] = orderFilter.value.phone
  }
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/orders/search-undelivered', {
    params: searchSet
  }).then(function (response) {
    orders.value = response.data.data
    if (orders.value.length === 0) {
      toast.info("Заказы не найдены")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
  buttonActive1.value = !buttonActive1.value;
}

const closeOrder = async (orderId) => {
  buttonActive2.value[orderId] = !buttonActive2.value[orderId];
  orderProducts.value = {};

  if (comment.value.length < 5) {
    toast.error('Необходимо указать причину по которой водитель не может самостоятельно закрыть заказ')
    return []
  }

  if (!bottles.value) {
    toast.error('Необходимо указать сколько бутылей забрал водитель')
    return []
  }

  await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/order/' + orderId + '/close-by-manager',
      {
        "comment": comment.value,
        "bottles": Number(bottles.value),
      }
  ).then(function () {
    toast.success("Заказ закрыт")
    orders.value = [];
    comment.value = '';
    bottles.value = null
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
  buttonActive2.value[orderId] = !buttonActive2.value[orderId];
}


const isFieldDisabled = (field) => {
  const {orderNumber, orderId, oldId, phone} = orderFilter.value;
  switch (field) {
    case 'orderNumber':
      return orderId !== '' || oldId !== '' || phone !== '';
    case 'orderId':
      return orderNumber !== '' || oldId !== '' || phone !== '';
    case 'oldId':
      return orderNumber !== '' || orderId !== '' || phone !== '';
    case 'phone':
      return orderNumber !== '' || orderId !== '' || oldId !== '';
    default:
      return false;
  }
}

const getMergedProducts = (item) => {
  return item.orderProducts.map(product => {
    // Если needScan === false, возвращаем базовые данные
    if (!product.needScan) {
      return {
        id: product.id,
        name: product.name,
        ordered: product.quantity,
        scanned: 0, // не используется, если needScan === false
        notScanned: 0, // не используется, если needScan === false
        needScan: false
      };
    }

    // Суммируем сканы для текущего продукта
    const scanned = (item.scans || [])
        .filter(scan => scan.product_id === product.id)
        .reduce((acc, scan) => acc + scan.count, 0);

    // Вычисляем количество неотсканированных товаров
    const notScanned = product.quantity - scanned;

    return {
      id: product.id,
      name: product.name,
      ordered: product.quantity,
      scanned: scanned,
      notScanned: notScanned,
      needScan: true
    };
  });
}
const pageBreadcrumbs = ref([
{
    title: 'Заказы',
    href: '/orders'
  },
  {
    title: 'Закрыть заказ',
    href: '/orders/close'
  },
])
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Закрыть заказ</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Заказы</a></li>
              <li class="breadcrumb-item active">Закрыть заказ</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <UIBreadCrumbs :items="pageBreadcrumbs" />
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-search mr-2"></i>Этап 1: Поиск заказа
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <label for="orderNumber" class="col-form-label">Номер заказа</label>
                    <input type="text" class="form-control" placeholder="123456-7890-12" min="0" max="999999"
                           v-model="orderFilter.orderNumber" :disabled="isFieldDisabled('orderNumber')">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="orderId" class="col-form-label">ID заказа</label>
                    <input type="number" class="form-control" placeholder="1234567" min="0" max="999999"
                           v-model="orderFilter.orderId" :disabled="isFieldDisabled('orderId')">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="orderOldId" class="col-form-label">OLD ID заказа</label>
                    <input type="number" class="form-control" placeholder="12345" min="0" max="999999"
                           v-model="orderFilter.oldId" :disabled="isFieldDisabled('oldId')">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="phone" class="col-form-label">Номер клиента</label>
                    <input type="text" class="form-control" placeholder="7999999999" v-model="orderFilter.phone"
                           :disabled="isFieldDisabled('phone')">
                  </div>
                  <div class="form-group col-md-auto">
                    <label for="btn" class="col-form-label">&nbsp;</label>
                    <button type="submit" class="form-control btn btn-primary waves-effect waves-light"
                            @click="searchOrders">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            v-if="buttonActive1"></span>
                      Найти
                    </button>
                  </div>
                </div>
                <div class="form-row">
                </div>
              </div>
            </div>

            <div class="card" v-if="orders.length > 0">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-list mr-2"></i>Этап 2: выбор заказа
                </h3>
              </div>
              <div class="card-body">
                <div class="row" v-for="item in orders" :key="item.id">
                  <div class="col-lg-6 col-md-6">
                    № <b>{{ item.orderNumber }}</b><br/>
                    <b>{{ item.customer.companyName ? item.customer.companyName : '' }}</b><br/>
                    {{ item.customer.fullName }}<br/>
                    Водитель: <b>{{ item.driver ? item.driver.fullName : 'Нет информации' }}</b><br/>
                    {{ item.address?.addressString }}
                    <br>
                    <br>
                    <div>
                      <p><b>Позиции заказа:</b></p>
                      <table class="table table-striped table-sm">
                        <thead>
                        <tr>
                          <th>Название</th>
                          <th>Заказано</th>
                          <th>Отсканировано</th>
                          <th>Не отсканировано</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="product in getMergedProducts(item)" :key="product.id">
                          <td>{{ product.name }}</td>
                          <td>{{ product.ordered }} шт.</td>
                          <td>
                            <span v-if="product.needScan">{{ product.scanned }} шт.</span>
                            <span v-else>—</span>
                          </td>
                          <td>
          <span v-if="product.needScan" :style="{ color: product.notScanned > 0 ? 'red' : 'inherit' }">
            {{ product.notScanned }} шт.
          </span>
                            <span v-else>—</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="form-row">
                      <div class="form-group col-md-7">
                        <label for="" class="col-form-label">Причина</label>
                        <select class="form-control" autocomplete="off" v-model="comment">
                          <option v-for="reason in reasons" :key="reason" :value="reason">
                            {{ reason }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-3">
                        <label for="" class="col-form-label">Бут. забрал</label>
                        <input type="text" class="form-control" min="0" max="300" v-model="bottles" required>
                      </div>
                      <div class="form-group col-md-2 col-xl-2">
                        <label for="" class="col-form-label">&nbsp;</label>
                        <button type="submit" class="form-control btn btn-danger" @click="closeOrder(item.id)">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                v-if="buttonActive2[item.id]"></span>
                          Закрыть
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>