<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import api from '@/common/api';
import { showErrors } from '@/common/showErrors';
import UISwitcher from '@/components/UI/UISwitcher.vue';
import { getFormattedPhoneNumber, addToClipboard } from '@/lib/utils'
import {useToast} from "vue-toastification";

const props = defineProps({
    addresses: {
        type: Array,
        required: true,
        default: () => []
    }
})
const toast = useToast();

const emits = defineEmits([])


const mainAddress = ref({})
const chooseMainCustomerHandler = (event, address) => {
    if (event) {
        mainAddress.value = address
    } else {
        resetMainAndDoubles()
    }
}

const addressDoubles = ref([])
const setDoubleHandler = (event, address) => {
    if (event) {
        addressDoubles.value.push(address.id)
    } else {
        addressDoubles.value = addressDoubles.value.filter((addressId) => addressId !== address.id)
    }
}

const setAllAddressesAsDouble = () => {
    addressDoubles.value = []
    props.addresses.forEach((address) => {
        if (address.id !== mainAddress.value.id) {
            addressDoubles.value.push(address.id)
        }
    })
}

const setDoublesAsync = async () => {
    try {
        await api.post(process.env.VUE_APP_GATE_URL + `/crm3core/api/v1/addresses/${mainAddress.value.id}/bind-duplicates`, {
            duplicates_ids: addressDoubles.value
        })
        resetMainAndDoubles()
        toast.success("Дубли связаны!");
        emits('choose-double')
    } catch (error) {
        showErrors(error)
    }
}
const resetMainAndDoubles = () => {
    mainAddress.value = {}
    addressDoubles.value = []
}
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1 d-flex">
            <p class="py-1 card-title d-block mr-4 my-auto">
                {{ addresses.length ? 'Адреса' : 'Адресов не найдено, уточните поиск' }}
            </p>
            <div class="py-auto h-100 form-row">
                <button v-if="mainAddress.id" type="submit" class="py-1 btn btn-primary waves-effect waves-light"
                    @click="setAllAddressesAsDouble">
                    Отметить всех как дубли
                </button>
                <button v-if="mainAddress.id" type="submit" class="py-1 btn btn-primary waves-effect waves-light ml-2"
                    :disabled="!addressDoubles.length" v-tooltip="!addressDoubles.length ? 'Выберите дубли' : null"
                    @click="setDoublesAsync">
                    Подтвердить дубли
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <table class="table table-hover" v-if="props.addresses.length">
                <thead>
                    <tr>
                        <th class="col-auto">Адрес</th>
                        <th class="col-2">Телефон</th>
                        <th class="col-2">Клиент</th>
                        <th class="co-2">Выберите&nbsp;основной&nbsp;аккаунт</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="address in props.addresses" :key="address.id">
                        <td class="col-auto"><span>{{
                            address.address_source_string }}</span></td>
                        <td class="col-2"><b @click="addToClipboard(address.customer.phone, 'Телефон скопирован!')" style="cursor: pointer">{{
                            getFormattedPhoneNumber(address.customer.phone) }}</b>
                        </td>
                        <td>
                            <RouterLink :to="{ path: '/customers/' + address.customer.id }">
                                <span v-show="address.customer.last_name">{{ address.customer.last_name + ' ' }} </span>
                                <span v-show="address.customer.first_name">{{ address.customer.first_name + ' ' }} </span>
                                <span v-show="address.customer.patronymic">{{ address.customer.patronymic + ' ' }} </span>
                            </RouterLink>
                        </td>
                        <td v-if="!mainAddress.id || mainAddress.id === address.id" class="col-2">
                            <UISwitcher class="mt-0" label="Основной" :modelValue="mainAddress.id === address.id"
                                @update:model-value="chooseMainCustomerHandler($event, address)" />
                        </td>
                        <td v-else class="col-2">
                            <UISwitcher class="mt-0" label="Дубль" :modelValue="addressDoubles.includes(address.id)"
                                @update:model-value="setDoubleHandler($event, address)" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.address--removed {
    opacity: .5;

    &:hover {
        opacity: .5;
        background-color: #fff;
    }
}
</style>