<script setup>
import UIInput from "@/components/UI/UIInput.vue";
import UISelect from "@/components/UI/UISelect.vue";
import UITextarea from "@/components/UI/UITextarea.vue";
import { defineProps, defineEmits, computed } from "vue"
import AddressAutocomplete from "@/components/UI/AddressAutocomplete.vue";


const props = defineProps({
    modelValue: { type: Object, required: true },
    customerType: { type: Number, required: false, default: () => 0 },
    addresses: { type: Array, required: false, default: () => [] },
    currentAddress: { type: [Number, String], required: false, default: () => 0 },
    validator: { type: Object, required: false, default: () => ({}) },
    newAddress: { type: Boolean, required: false, default: () => true },
    newClient: { type: Boolean, required: false, default: () => false },
    waterCompanyId: { type: Number, required: true, default: () => 1 },
    disableMainFields: { type: Boolean, required: false, default: () => false },
})

const emits = defineEmits(['update:modelValue', 'new-error', 'update:currentAddress', 'update-main-info'])

const updateHandler = (field, newValue) => {
    const newModelValue = { ...props.modelValue }
    if (typeof field === 'string') {
        newModelValue[field] = newValue
    } else {
        let currentStack = newModelValue
        for (const fieldPart of field) {
            if (fieldPart !== field.at(-1)) {
                currentStack = currentStack[fieldPart]
            }
            else {
                currentStack[fieldPart] = newValue
            }
        }
    }
    emits('update:modelValue', newModelValue)
}

const updateAddressHandler = (suggest) => {
    const newModelValue = { ...props.modelValue }

    newModelValue.address_source_string = (suggest.desc ? (suggest.desc + ', ') : '') + suggest.name
    newModelValue.latitude = suggest.lat || null
    newModelValue.longitude = suggest.lon || null

    emits('update:modelValue', newModelValue)
}


const addressesWithoutRemoved = computed(() => {
    return props.addresses.filter((address) => !address.deleted_at && !address.delete_label)
})


const spacingVariants = [
    {
        value: true,
        title: 'Да'
    },
    {
        value: false,
        title: 'Нет'
    }
]
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2"><i class="fas fa-map mr-2"></i>Адрес</div>
        <div class="card-body p-2">
            <div class="form-row">
                <UISelect v-if="addresses.length" class=" mb-0 col-md-12 col-xl-12" sm label=""
                    clearOptionTitle="Новый адрес" :modelValue="props.currentAddress"
                    @update:modelValue="emits('update:currentAddress', $event)" :options="addressesWithoutRemoved"
                    clearable :readonly="disableMainFields"
                    :mapOptions="(opt) => ({ title: opt.address_source_string, value: opt.id })" />
            </div>
            <div class="form-row">
                <AddressAutocomplete :waterCompanyId="waterCompanyId" class="form-group mb-0 col-md-9 col-xl-9"
                    label="Адрес" sm :readonly="!newAddress || disableMainFields"
                    :modelValue="modelValue.address_source_string" @choose-address="emits('update-main-info')"
                    @update:modelValue="updateAddressHandler($event)"
                    :errors="validator.address_source_string?.$errors || []" />
                <div class="form-row col-3">
                    <UIInput class="mb-0 col-md-5 col-xl-5" sm label="Координаты"
                        :modelValue="props.modelValue.latitude" maxlength="20"
                        :readonly="!newAddress || disableMainFields"
                        @update:modelValue="updateHandler(['latitude'], $event); emits('update-main-info')"
                        :errors="validator.latitude?.$errors || []" />
                    <UIInput class="mb-0 col-md-5 col-xl-5" :readonly="!newAddress || disableMainFields" sm
                        label="&nbsp;" :modelValue="props.modelValue.longitude"
                        @update:modelValue="updateHandler(['longitude'], $event); emits('update-main-info')"
                        maxlength="20" :errors="validator.longitude?.$errors || []" />
                    <a :href="`https://yandex.ru/maps/?pt=${props.modelValue.longitude},${props.modelValue.latitude}&z=18&l=map`"
                        target="_blank" class="mt-auto col-2"><img src="../../assets/maps_icon.png" height="31"
                            class="mt-auto" /></a>
                </div>
            </div>
            <div class="form-row">
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Дом" readonly :modelValue="props.modelValue.building"
                    @update:modelValue="updateHandler('building', $event)" maxlength="254"
                    :errors="validator.building?.$errors || []" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Корпус" readonly :modelValue="props.modelValue.korp"
                    @update:modelValue="updateHandler('korp', $event)" maxlength="254"
                    :errors="validator.korp?.$errors || []" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Этаж" :modelValue="props.modelValue.floor"
                    @update:modelValue="updateHandler('floor', $event)" onlyNumber maxlength="254"
                    :errors="validator.floor?.$errors || []" />
                <UIInput v-if="customerType === 2" class="mb-0 col-md-2 col-xl-2" sm label="Квартира"
                    :modelValue="props.modelValue.flat" @update:modelValue="updateHandler('flat', $event)"
                    maxlength="254" :errors="validator.flat?.$errors || []" />
                <UIInput v-if="customerType === 1" class="mb-0 col-md-2 col-xl-2" sm label="Офис"
                    :modelValue="props.modelValue.office" @update:modelValue="updateHandler('office', $event)"
                    maxlength="254" :errors="validator.office?.$errors || []" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Подъезд" :modelValue="props.modelValue.entrance"
                    @update:modelValue="updateHandler('entrance', $event)" maxlength="254"
                    :errors="validator.entrance?.$errors || []" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Код" :modelValue="props.modelValue.code"
                    @update:modelValue="updateHandler('code', $event)" maxlength="254"
                    :errors="validator.code?.$errors || []" />
            </div>
            <div class="form-row">
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Телефон" :modelValue="props.modelValue.phone"
                    @update:modelValue="updateHandler('phone', $event)" onlyNumber maxlength="11"
                    :errors="validator.phone?.$errors || []" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Время работы"
                    :modelValue="props.modelValue.working_hours" :errors="validator.working_hours?.$errors || []"
                    @update:modelValue="updateHandler('working_hours', $event)" maxlength="254" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Обед" :modelValue="props.modelValue.lunch_hours"
                    @update:modelValue="updateHandler('lunch_hours', $event)" maxlength="254"
                    :errors="validator.lunch_hours?.$errors || []" />
                <UISelect class="mb-0 col-md-2 col-xl-2" sm label="Разнос" :modelValue="props.modelValue.spacing"
                    @update:modelValue="updateHandler('spacing', $event)" :errors="validator.spacing?.$errors || []"
                    :options="spacingVariants" clearOptionTitle="Не важно" clearable />
            </div>
            <div class="form-row">
                <UITextarea class="mb-0 col-md-4 col-xl-4" sm label="Комментарий к адресу"
                    :modelValue="props.modelValue.description" @update:modelValue="updateHandler('description', $event)"
                    maxlength="300" :errors="validator.description?.$errors || []" />
                <UITextarea class="mb-0 col-md-4 col-xl-4" sm label="Комментарий для логиста"
                    :modelValue="props.modelValue.logist_comment" :errors="validator.logist_comment?.$errors || []"
                    @update:modelValue="updateHandler('logist_comment', $event)" maxlength="300" />
                <UITextarea class="mb-0 col-md-4 col-xl-4" sm label="Комментарий для менеджера"
                    :modelValue="props.modelValue.manager_comment" :errors="validator.manager_comment?.$errors || []"
                    @update:modelValue="updateHandler('manager_comment', $event)" maxlength="300" />
            </div>

        </div>
    </div>
</template>
<style scoped>
@media (min-width: 1200px) {
    .col-xl-10--custom {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 80.333333%;
        max-width: 83.333333%;
    }
}
</style>