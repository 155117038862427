<script setup>
import { filterFieldsBySearchBy, searchByOptions } from '@/lib/auxiliary/customerSearch'
import UIButton from "../UI/UIButton.vue";
import UIInput from "../UI/UIInput.vue";
import UISelect from "../UI/UISelect.vue";
import { useParamsStore } from "@/store/params";
import { computed, ref, defineEmits, defineProps } from "vue";
import { vTooltip } from 'floating-vue'
import api from '@/common/api';
import { showErrors } from '@/common/showErrors';

const emits = defineEmits(['search', 'update:modelValue', 'update:searchBy'])
const props = defineProps({
    modelValue: { type: Object, required: true },
    isLoadingSearchBtn: { type: Boolean, required: false, default: () => false },
    validator: { type: Object, required: false, default: () => ({}) },
    searchBy: { type: String, required: true, default: () => 'phone' },
    disableWaterCompany: { type: Boolean, required: false, default: () => false }
})


const companyCities = computed(() => useParamsStore().cities)

const updateHandler = (field, newValue) => {
    const newModelValue = structuredClone(props.modelValue)
    newModelValue[field] = newValue
    emits('update:modelValue', newModelValue)
}

const defaultFilterValue = ref({
    phone: '',
    last_name: '',
    first_name: '',
    patronymic: '',
    company: '',
    street: '',
    water_company_id: 1,
    distribution_id: null,
    search: '',
})
const setDefaultFilters = () => {
    emits('update:modelValue', { ... defaultFilterValue.value, water_company_id: props.modelValue.water_company_id })
}

const isEmptyFilter = computed(() => {
    const copyFilterForm = structuredClone(props.modelValue)
    copyFilterForm.water_company_id = 0
    copyFilterForm.type = 0
    copyFilterForm.customerBindings = 0
    copyFilterForm.addresses = 0
    copyFilterForm.duty = 0
    for (const value of Object.values(copyFilterForm)) {
        if (value) {
            return false
        }
    }
    return true
})

const isDisabledSubmitBtn = computed(() => {
    return props.isLoadingSearchBtn || isEmptyFilter.value || props.validator.$error
})

const partners = ref([])
const fetchPartners = async () => {
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/filter-options/distributions')
        partners.value = data.data
    } catch (error) {
        showErrors(error)
    }
}
fetchPartners()
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">
            <h3 class="card-title"><i class="fas fa-search mr-2"></i>Найти клиента</h3>
        </div>
        <form @submit="emits('search', searchBy)" class="card-body">
            <div class="form-row">
                <UISelect class="mb-0 col-md-2 col-xl-2" label="Город" :modelValue="modelValue.water_company_id"
                    :options="companyCities" :mapOptions="(city) => ({ title: city.short_name, value: city.id })"
                    @update:modelValue="updateHandler('water_company_id', $event)"  :readonly="disableWaterCompany"/>
                <UISelect class="mb-0 col-md-2 col-xl-2" label="Поиск по" :modelValue="searchBy"
                    @update:modelValue="emits('update:searchBy', $event); setDefaultFilters()"
                    :options="searchByOptions" />
                <template v-if="searchBy !== 'partner'">
                    <UIInput v-for="field in filterFieldsBySearchBy[searchBy]" :key="field.title"
                        :modelValue="props.modelValue[field.model]" :minLength="field.minLength" validate
                        :maxlength="field.maxlength" :onlyNumber="field.onlyNumbers" @update:modelValue="updateHandler(field.model, $event)"
                        :label="field.title" class="form-group mb-0 col-md-2 col-xl-2"
                        :errors="validator?.[field.model]?.$errors ?? []" />
                </template>
                <template v-else>
                    <UISelect class="form-group mb-0 col-md-2 col-xl-2" :options="partners"
                        :mapOptions="(partner) => ({ title: partner.name, value: partner.id })" label="Партнёр"
                        :modelValue="props.modelValue.distribution_id"
                        @update:modelValue="updateHandler('distribution_id', $event)" />
                    <UIInput class="form-group mb-0 col-md-2 col-xl-2"
                        :modelValue="props.modelValue.search"
                        @update:modelValue="updateHandler('search', $event)" label="Поиск" />
                </template>
                <div class="form-group col-md-2 col-xl-2 mb-0">
                    <label for="wcId" class="col-form-label pb-1">&nbsp;</label>
                    <UIButton v-tooltip="isEmptyFilter || validator.$error ? 'Проверьте заполнение полей' : null"
                        class="form-control form-control-sm btn-sm btn-primary waves-effect waves-light mt-0" type="submit"
                        :isLoading="isLoadingSearchBtn" :disabled="isDisabledSubmitBtn"
                        @click="emits('search', searchBy)">Найти
                    </UIButton>
                </div>
            </div>
        </form>
    </div>
</template>
