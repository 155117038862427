<script setup>
import {showErrors} from "@/common/showErrors";
import router from "@/router";
import {useUserStore} from "@/store";
import axios from "axios";
import {ref} from "vue";
import { useParamsStore } from "@/store/params";

const user = ref({login: "", password: ""});

const userStore = useUserStore();

const login = () => {
  console.log('VUE_APP_GATE_URL' + process.env.VUE_APP_GATE_URL)
  axios.post(process.env.VUE_APP_GATE_URL + '/api/user/login', {
    login: user.value.login,
    password: user.value.password
  }).then(({data}) => {
    console.log(data.data.access_token)
    localStorage.access_token = data.data.access_token;
    userStore.setAuthentication(true)
    userStore.setUserRole(data.data.role)
    userStore.setPermissions(data.data.permissions)
    userStore.setDepartmentId(data.data.department_id)
    useParamsStore().fetchCities()
    router.push({name: 'MainPage'})
  }).catch(({response}) => {
    showErrors(response)
  })
}
</script>

<template>
  <head>
    <title>Войти</title>
  </head>
  <div class="container-fluid">
    <div id="cardSignIn" class="row justify-content-md-center">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="card">
          <div class="card-header">
            Хваловские воды
          </div>
          <div class="card-body">
            <div id="formSignIn">
              <div class="input-group mb-2">
                <span class="input-group-text mdi mdi-login fa fa-user"></span>
                <input type="text" class="form-control validate-fill" id="formSignIn_email" name="login" value=""
                       placeholder="Логин" v-model="user.login">
              </div>
              <div class="input-group mb-2">
                <span class="input-group-text mdi mdi-lock fa fa-lock"></span>
                <input type="password" class="form-control validate-fill" id="formSignIn_password" name="password"
                       value="" placeholder="Пароль" v-model="user.password"
                       @keyup.enter="login">

              </div>
              <div class="mb-2 text-end">
                <button class="btn btn-primary" @click="login">
                  <span class="mdi mdi-location-enter"></span>
                  Войти
                </button>
              </div>
              <small class="form-text text-muted">
                <router-link :to="{name: 'StaffSingIn'}">
                  Войти как водитель или экспедитор
                </router-link>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import '../../css/style.css';
</style>