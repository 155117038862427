<script setup>
import {computed, ref} from "vue";
import {useUserStore} from "@/store";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useToast} from "vue-toastification";
import format from 'date-fns/format';
import AutoPaymentMenu from "@/components/AutoPayment/AutoPaymentMenu.vue";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const toast = useToast();

const wcId = ref(1);
const limit = ref(10);
const customers = ref([]);
const selectedOrders = ref([]);
const allSelected = ref(false);
const searchActive = ref(false);
const settings = ref({});
const keyDBStorage = 'crm3_auto_payment_cost';

const selectAll = () => {
  selectedOrders.value = [];
  if (!allSelected.value) {
    for (const customer of customers.value) {
      selectedOrders.value.push(customer.order.id);
    }
  }
}

const searchCustomers = async () => {
  await get();
  customers.value = [];
  selectedOrders.value = [];
  allSelected.value = false;
  let searchSet = {};
  searchSet['wc_id'] = wcId.value;
  searchSet['limit'] = limit.value;
  searchActive.value = !searchActive.value;
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/auto-payment/get-customers', {
    params: searchSet
  }).then(function (response) {
    customers.value = response.data.data.customers;
    if (customers.value.length === 0) {
      toast.info("Клиенты не найдены")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
  searchActive.value = !searchActive.value;
}

const charge = () => {
  let searchSet = {};
  searchSet['order_ids'] = selectedOrders.value;
  searchSet['cost'] = settings.value.value;
  searchSet['is_new'] = true;
  selectedOrders.value=[]
  api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/auto-payment/writing-off-money', searchSet).then(function (response) {
    if (response.data.data) {
      toast.success("Запрос на автосписание отправлен");
      let badOrdersId = response.data.data.filter(order => order.pay_response.code != 200).map(order => order.order_id);
      let goodOrdersId = response.data.data.filter(order => order.pay_response.code == 200).map(order => order.order_id);

      let badCustomers = customers.value.filter(customer => badOrdersId.includes(customer.order.id));
      customers.value = customers.value.map(customer => {
        if (badCustomers.includes(customer)) {
          return {
            ...customer,
            alert: response.data.data.filter(order => order.order_id == customer.order.id).map(order => order.message ?? 'Не удалось списать средства для данного клиента')[0]
          };
        }
        return customer;
      });
      customers.value = customers.value.filter(customer => !goodOrdersId.includes(customer.order.id));
    }
  }).catch(function () {
    showErrors('Автосписание не проведено')
    return []
  })
}

const get = async () => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/storage', {
    params: {
      wc_id: wcId.value,
      key: keyDBStorage
    }
  }).then(function (response) {
    settings.value = response.data.data
    if (!settings.value) {
      toast.info("Настройка не найдена");
    }
  }).catch(function () {
    showErrors('Настройка не выполнена')
    return []
  });
}

const ignoreCustomer = async (id) => {
  try {
    await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/auto-payment/ignore', {
      'customer_id': id
    })
    customers.value = customers.value.filter(customer => customer.id !== id);
  } catch (error) {
    showErrors('Не удалось убрать')
  }
}

const pageBreadcrumbs = ref([
  {
    title: 'Автосписание',
    href: '/service/sberbank-log'
  },
])
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Автосписание</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <UIBreadCrumbs :items="pageBreadcrumbs" />
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <AutoPaymentMenu></AutoPaymentMenu>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-search mr-2"></i>Этап 1: Поиск клиентов
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label for="wcId" class="col-form-label">Город</label>
                    <select id="wcId" class="form-control" autocomplete="off" v-model="wcId">
                      <option value="1">Санкт-Петербург</option>
                      <option value="2">Москва</option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="count" class="col-form-label">Кол-во</label>
                    <input id="count" type="number" min="0" max="999999" class="form-control" placeholder="10"
                           v-model="limit">
                  </div>
                  <div class="form-group col-md-auto" style="padding-top: 38px;">
                    <button type="submit" class="btn btn-primary waves-effect waves-light" @click="searchCustomers">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            v-if="searchActive"></span>
                      Найти
                    </button>
                  </div>
                </div>
                <div class="form-row">
                </div>
              </div>
            </div>

            <div class="card" v-if="customers.length > 0">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-list mr-2"></i>
                  Этап 2: Выбор клиентов
                </h3>
              </div>
              <div class="card-body">
                <table class="table table-striped table-sm">
                  <tbody>
                  <tr>
                    <th>Город</th>
                    <th>ФИО</th>
                    <th>Компания</th>
                    <th>Телефон</th>
                    <th>Дата заказа</th>
                    <th>Состав заказа</th>
                    <th class="text-center">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="form-check-input" @click="selectAll" v-model="allSelected">
                      </div>
                    </th>
                    <th>Больше не показывать</th>
                  </tr>
                  <tr v-for="item in customers" :key="item.id">
                    <td>
                      {{ item.water_company_id === 2 ? 'Москва' : 'Санкт-Петербург' }}
                    </td>
                    <td>
                      {{ item.full_name }}
                      <br>
                      <div v-if="item.alert" class="info-box" style="color: red">{{ item.alert }}</div>
                    </td>
                    <td>
                      <b>{{ item.company_name }}</b>
                    </td>
                    <td>
                      +{{ item.phone }}
                    </td>
                    <td>
                      {{ format(new Date(item.order.created_at), 'dd.MM.yyyy HH:mm') }}
                    </td>
                    <td>
                      <small v-for="product in item.order.products" :key="product.product.id">
                        {{ product.product.name }}<br>
                      </small>
                    </td>
                    <td class="text-center">
                      <div class="custom-control custom-checkbox" v-if="!item.alert">
                        <input type="checkbox" class="form-check-input" v-model="selectedOrders" :value="item.order.id">
                      </div>
                    </td>
                    <td>
                      <button class="btn btn-outline-danger" @click="ignoreCustomer(item.id)">Убрать</button>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card" v-if="selectedOrders.length > 0">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-list mr-2"></i>Этап 3: Списывание средств
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-auto">
                    <button type="submit" class="btn btn-success waves-effect waves-light" @click="charge">
                      Списать средства
                    </button>
                  </div>
                </div>
                <div class="form-row">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <!-- content off -->
  </div>
</template>

<style scoped>

</style>