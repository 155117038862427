<script setup>
import { defineProps, ref, computed } from 'vue'
const props = defineProps({
    customerBindings: {
        type: Object,
        required: true,
        default: () => ({})
    }
})


const activeBtnShowFullTable = ref(false)
const minCountForOpenButton = 5
const showFullTable = computed(() => {
    if (props.customerBindings.length < minCountForOpenButton) {
        return true
    } else {
        return activeBtnShowFullTable.value
    }
})

const customerBindingsForShow = computed(() => {
    if (showFullTable.value) {
        return props.customerBindings
    } else {
        return props.customerBindings.slice(0, 2)
    }
})
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">Связки</div>
        <div class="card-body p-0">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Плательщик</th>
                        <th>Грузополучатель</th>
                        <th>Адрес</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="binding in customerBindingsForShow" :key="binding.id">
                        <td class="col-4">ИНН {{ binding.payer.inn }}<br />{{
                            binding.payer.small_name }}</td>
                        <td class="col-4">ИНН {{ binding.consignee.inn }}<br />{{
                            binding.consignee.small_name }}</td>
                        <td class="col-4">{{ binding.address.address_source_string }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="show-full-table-btn--container" v-if="props.customerBindings.length >= minCountForOpenButton">
            <button class="show-full-table-btn text-primary" @click="activeBtnShowFullTable = !activeBtnShowFullTable">
                <b>{{ activeBtnShowFullTable ? 'Скрыть' : 'Показать всё' }}</b>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.show-full-table-btn--container {
    // position: absolute;
    width: 100%;
    padding: 8px;
    background: rgb(166, 166, 166);
    background: linear-gradient(0deg, rgba(166, 166, 166, .7) 0%, rgba(255, 255, 255, .0) 100%);
    // bottom: 0px;
    // left: 50%;
    // transform: translate(-50%, 0);
    display: flex;
    justify-content: center;

    .show-full-table-btn {
        border: none;
        background: none;
        font-size: 1rem;
    }
}
</style>