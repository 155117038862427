<script setup>
import UIButton from "../UI/UIButton.vue";
import UIInput from "../UI/UIInput.vue";
import UISelect from "../UI/UISelect.vue";
import { computed, ref, defineEmits, defineProps } from "vue";
import { vTooltip } from 'floating-vue'
import { useRouter } from "vue-router";
import Datapicker from "vue3-datepicker";
import ru from 'date-fns/locale/ru';

const emits = defineEmits(['search', 'update:modelValue', 'update:searchBy'])
const props = defineProps({
    modelValue: { type: Object, required: true },
    isLoadingSearchBtn: { type: Boolean, required: false, default: () => false },
    validator: { type: Object, required: false, default: () => ({}) },
    searchBy: { type: String, required: false, default: () => 'phone' },
    type: { type: String, required: true, default: () => 'customer' } // customer || address
})

const router = useRouter()
const updateHandler = (field, newValue) => {
    const newModelValue = { ...props.modelValue }
    newModelValue[field] = newValue
    router.push({ query: newModelValue })

    emits('update:modelValue', newModelValue)
}

const defaultFilterValue = ref({
    phone: '',
    period_days: null
})
const setDefaultFilters = () => {
    emits('update:modelValue', defaultFilterValue.value)
}

const isEmptyFilter = computed(() => {
    const copyFilterForm = structuredClone(props.modelValue)
    copyFilterForm.water_company_id = 0
    copyFilterForm.type = 0
    copyFilterForm.customerBindings = 0
    copyFilterForm.addresses = 0
    copyFilterForm.duty = 0
    for (const value of Object.values(copyFilterForm)) {
        if (value) {
            return false
        }
    }
    return true
})

const isDisabledSubmitBtn = computed(() => {
    return props.isLoadingSearchBtn || isEmptyFilter.value || props.validator.$error
})

const searchByOptions = [
    { title: 'Телефон', value: 'phone' },
    { title: 'За последние дни', value: 'last_days' },
    { title: 'За период', value: 'period_days' },
]
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">
            <h3 class="card-title"><i class="fas fa-search mr-2"></i>Найти клиента</h3>
        </div>
        <form @submit="emits('search')" class="card-body">
            <div class="form-row">
                <UISelect v-if="type === 'customer'" class="mb-0 col-md-2 col-xl-2" label="Поиск по"
                    :modelValue="searchBy" @update:modelValue="emits('update:searchBy', $event); setDefaultFilters()"
                    :options="searchByOptions" />
                <UIInput v-if="props.searchBy === 'phone'" :modelValue="props.modelValue.phone" :minLength="2"
                    mask="+7 (000) 000-00-00" @update:modelValue="updateHandler('phone', $event)" label="Телефон"
                    class="form-group mb-0 col-md-2 col-xl-2" :errors="validator?.phone?.$errors ?? []" />
                <UIInput v-if="props.searchBy === 'last_days'" :modelValue="props.modelValue.period_days" onlyNumber
                    :maxValue="720" @update:modelValue="updateHandler('period_days', $event)" label="Период"
                    class="form-group mb-0 col-md-2 col-xl-2" :errors="validator?.period_days?.$errors ?? []" />
                <div class="form-row ml-2" v-if="props.searchBy === 'period_days'">
                    <div class="form-group mb-0">
                        <label for="" class="col-form-label pb-1">От</label>
                        <Datapicker class="form-control form-control-sm" :modelValue="props.modelValue.date_from"
                            @update:modelValue="updateHandler('date_from', $event)" :locale="ru"
                            input-format='dd.MM.yyyy' format="dd.MM.yyyy" typeable
                            :upperLimit="props.modelValue.date_to" />
                    </div>
                    <div class="form-group mb-0 ml-2">
                        <label for="" class="col-form-label pb-1">До</label>
                        <Datapicker class="form-control form-control-sm" :modelValue="props.modelValue.date_to"
                            @update:modelValue="updateHandler('date_to', $event)"
                            :lowerLimit="props.modelValue.date_from" :locale="ru" input-format='dd.MM.yyyy' typeable />
                    </div>
                </div>

                <div class="form-group col-md-2 col-xl-2 mb-0 ml-2">
                    <label for="wcId" class="col-form-label pb-1">&nbsp;</label>
                    <UIButton v-tooltip="isEmptyFilter || validator.$error ? 'Проверьте заполнение полей' : null"
                        class="form-control form-control-sm btn-sm btn-primary waves-effect waves-light" type="submit"
                        :isLoading="isLoadingSearchBtn" :disabled="isDisabledSubmitBtn" @click="emits('search')">Найти
                    </UIButton>
                </div>
            </div>
        </form>
    </div>
</template>