import api from "@/common/api";
import { showErrors } from "@/common/showErrors";


export const getProductList = async (params) => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/products`, { params })
        return data.data
    } catch (error) {
        console.error(error)
    }
}

export const getPreferredProducts = async (water_company_id) => { // for default cart
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/cart-validation/product-preferred', { params: { water_company_id } })
        return data.data
    } catch (error) {
        console.error(error)
        showErrors(error)
    }
}

export const getPopularProducts = async (water_company_id) => { // for fast choose in addProduct modal
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/products-popular', {
            params: {
                water_company_id
            }
        })
        return data.data
    } catch (error) {
        showErrors(error)
    }
}