<script setup>
import Datapicker from "vue3-datepicker";
import ru from 'date-fns/locale/ru';
import { useUserStore } from "@/store";
import { computed, ref, nextTick, watch, onMounted } from "vue";
import ClientForm from "@/components/Forms/ClientForm.vue";
import { useRoute, useRouter } from "vue-router";
import BaseClient, { ClientFieldsWithEmptyValidation } from "@/lib/models/BaseClient";
import BaseOrder, { OrderFieldsWithEmptyValidation } from "@/lib/models/BaseOrder";
import AddressForm from "@/components/Forms/AddressForm.vue";
import BaseAddress, { AddressFieldsWithEmptyValidation } from "@/lib/models/BaseAddress";
import OrderBasket from "@/components/Orders/OrderBasket.vue";
import { showErrors } from "@/common/showErrors";
import api, { parseApiErrorsForValidator } from '@/common/api';
import moment from "moment";
import UISelect from "@/components/UI/UISelect.vue";
import UIButton from "@/components/UI/UIButton.vue";
import OrdersTable from '@/components/Customers/Tables/OrdersTable.vue';
import AdditionalOrderInfoForm from "@/components/Orders/AdditionalOrderInfoForm.vue";
import { useToast } from "vue-toastification";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, or, minLength } from '@vuelidate/validators'
import debounce from "lodash/debounce";
import { getCustomerById, postCustomer, patchCustomer, getCustomersBindings } from "@/lib/api/customer/index.js";
import { patchAddress, postAddress } from "@/lib/api/address";
import { getPreferredProducts, getProductList, getPopularProducts } from "@/lib/api/order/products";
import { fetchDeliveryTimes, fetchTimeSlots } from "@/lib/api/order/slots";
import { getOrdersByCusomerId, getOrderStatuses, postOrder, getOrderById, patchOrder } from "@/lib/api/order";
import { fetchPaymentTypes } from "@/lib/api/params";
import BaseTimeSlotsForm from "@/lib/models/BaseTimeSlotsForm";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const router = useRouter()
const route = useRoute()
const toast = useToast()


const clientForm = ref(new BaseClient())
const clientFormValidationRules = {
    phone: { required: helpers.withMessage('Заполните поле телефон', required), minLength: helpers.withMessage('Минимум 11 чисел', minLength(11)) },
    phone2: { minLength: helpers.withMessage('Минимум 11 чисел', or(() => !clientForm.value.phone2, minLength(11))) },
    phone_from_call: { minLength: helpers.withMessage('Минимум 11 чисел', or(() => !clientForm.value.phone_from_call, minLength(11))) },
    payment_type: { required: helpers.withMessage('Выберите тип оплаты', required) },
    first_name: { required: helpers.withMessage('Заполните имя', required), regex: helpers.withMessage('Поле должно содержать только русские символы', helpers.regex(/^[-а-яА-ЯёЁ\\s]+$/)) },
    last_name: { regex: helpers.withMessage('Поле должно содержать только русские символы', helpers.regex(/^[-а-яА-ЯёЁ\\s]+$/)) },
    patronymic: { regex: helpers.withMessage('Поле должно содержать только русские символы', helpers.regex(/^[-а-яА-ЯёЁ\\s]+$/)) },
    company: { reuired: helpers.withMessage('Заполните компанию', or(() => clientForm.value.type === 2, required)) },
    distribution_id: { required: helpers.withMessage('Выберите партнёра', or(() => !clientForm.value.hasPartner, required)) },
    distribution_customer_id: { required: helpers.withMessage('Введите ID клиента партнёра', or(() => !clientForm.value.hasPartner, required)) },
    ...ClientFieldsWithEmptyValidation
}
const clientFormApiValidationErrors = ref({})
const clientFormValidator = useVuelidate(clientFormValidationRules, clientForm, { $externalResults: clientFormApiValidationErrors })
const customerChanged = ref(false)
const customerChangeHandler = (event) => {
    customerChanged.value = true
    timeSlotsForm.value.waterCompanyId = event.water_company_id
}


const customerOrders = ref([])
const addressForm = ref(new BaseAddress())
const addressChanged = ref(false)
const addressFormValidationRules = {
    address_source_string: { required: helpers.withMessage('Заполните полный адрес', required) },
    phone: { minLength: helpers.withMessage('Минимум 11 чисел', or(() => !addressForm.value.phone, minLength(11))) },
    latitude: { required: helpers.withMessage('Заполните ширину', required) },
    longitude: { required: helpers.withMessage('Заполните долготу', required) },
    ...AddressFieldsWithEmptyValidation
}
const addressFormApiValidationErrors = ref({})
const addressFormValidator = useVuelidate(addressFormValidationRules, addressForm, { $externalResults: addressFormApiValidationErrors })
const isLoading = ref(false)
const timeSlots = ref([])
const isLoadingTimeSlots = ref(false)
const isDisabledTimeSlotsBtn = computed(() => {
    return isLoadingTimeSlots.value || !orderForm.value.cart.length || orderForm.value.cart.some(item => !+item.qty)
})

const countMonthOrderStatistic = 2
const orderFiltersDateFrom = new Date().setMonth(new Date().getMonth() - countMonthOrderStatistic)
const orderFiltersDateTo = new Date().setMonth(new Date().getMonth() + countMonthOrderStatistic)
const ordersFilters = ref({ date_from: orderFiltersDateFrom, date_to: orderFiltersDateTo, address_id: null, water_company_id: 0 })
const orderStatuses = ref([])
const customerId = ref(null)

const currentOrderId = ref(null)

const orderForm = ref(new BaseOrder({ cart: [] }))
const orderBasket = ref(null)

const getProductById = (productId) => {
    return products.value.find((product) => product.id === productId) ?? {}
}
const getDefaultCart = async () => {
    const defaultProducts = await getPreferredProducts(clientForm.value.water_company_id)
    if (clientForm.value.fixed_water_price) {
        defaultProducts.forEach((product) => {
            product.price = clientForm.value.fixed_water_price
        })
    }
    if (clientForm.value.payment_type) {
        defaultProducts.forEach((product) => {
            product.payment_type_id = clientForm.value.payment_type
        })
    }
    orderForm.value.cart = defaultProducts
    for (const orderItemIdx in defaultProducts) {
        const PREMIUM_WATER_ID = 416
        console.log(defaultProducts, defaultProducts[orderItemIdx], 'defaultProducts[orderItemIdx]')
        if (!clientForm.value.fixed_water_price || defaultProducts[orderItemIdx].product_id !== PREMIUM_WATER_ID) {
            fixPriceHandler({ idx: orderItemIdx, event: true })
        }
    }
}
const fixPriceHandler = ({ idx, event }) => {
    const product = orderForm.value.cart[idx]
    if (event) {
        const currentProduct = getProductById(product.product_id)
        if (!currentProduct.fixed_price) {
            return
        }
        let newPrice = 0
        let previosValue = Object.keys(currentProduct.fixed_price)[0]
        for (const price in currentProduct.fixed_price) {
            if (+price > +product.qty) {
                newPrice = currentProduct.fixed_price[previosValue]
                break;
            }
            previosValue = price
        }
        const lastFixedPriceQty = Object.keys(currentProduct.fixed_price).at(-1)
        if (+lastFixedPriceQty <= +product.qty) {
            newPrice = currentProduct.fixed_price[lastFixedPriceQty]
        }
        orderForm.value.cart[idx].price = newPrice
    }
    nextTick(() => {
        orderForm.value.cart[idx].isFixedPrice = event
    })
}
const orderFormValidationRules = {
    time_id: { required: helpers.withMessage('Заполните слот для доставки', required) },
    window_from_dt: { required: helpers.withMessage('Заполните слот для доставки', required) },
    window_to_dt: { required: helpers.withMessage('Заполните слот для доставки', required) },
    delivery_type_id: { required: helpers.withMessage('Заполните слот для доставки', required) },
    bottles_return: { required: helpers.withMessage('Заполните кол-во бутылей для возврата', required) },
    status_id: { required: helpers.withMessage('Заполните статус заказа', required) },
    ...OrderFieldsWithEmptyValidation
}
const orderFormApiValidationErrors = ref({})
const orderFormValidator = useVuelidate(orderFormValidationRules, orderForm, { $externalResults: orderFormApiValidationErrors })
const deliveryTimes = ref([])

const fetchDeliveryTimesAsync = async () => {
    deliveryTimes.value = await fetchDeliveryTimes(clientForm.value.water_company_id)
}

const fetchCustomerById = async (customerId) => {
    isLoading.value = true
    const customerData = await getCustomerById(customerId).finally(() => isLoading.value = false)


    clientForm.value = new BaseClient(customerData)
    orderForm.value.water_company_id = customerData.water_company.id
    orderForm.value.customer_id = customerData.id
    if (route.query.addressId) {
        addressForm.value = customerData.addresses.find((customerAddress) => customerAddress.id == route.query.addressId) || customerData.addresses[0]
    } else if (orderForm.value?.address_id) {
        addressForm.value = customerData.addresses.find((customerAddress) => customerAddress.id === orderForm.value.address_id)
    } else if (customerData.addresses.length) {
        addressForm.value = customerData.addresses.find((customerAddress) => customerAddress.is_primary) || customerData.addresses[0]
    } else {
        addressForm.value.customer_id = customerData.id
    }
    orderForm.value.address_id = addressForm.value.id
    ordersFilters.value.water_company_id = customerData?.water_company?.id

    customerChanged.value = false
    fetchPaymentTypesAsync(customerData?.water_company?.id)
    fetchDeliveryTimesAsync()
    getPopularProductsAsync()
    getCustomerOrders(customerData.id, ordersFilters.value)
    await fetchProductList()
}

const customerOrdersLoading = ref(false)
const isFinishedOrderStatisticRequest = ref(false)
const getCustomerOrders = async (customerId, ordersFilters = {}) => {
    customerOrdersLoading.value = true
    customerOrders.value = await getOrdersByCusomerId(customerId, ordersFilters)
    isFinishedOrderStatisticRequest.value = true
    customerOrdersLoading.value = false
}

// В лишнем парсе винить Иванова Даниила и отказавшегося править Лацу Давида, по причине не желания ронять все сервисы хв
const cartToDSSNaming = (oldCart) => {
    return oldCart.map((product) => ({ productId: product.product_id, quantity: product.qty }))
}

// В лишнем парсе винить Иванова Даниила и отказавшегося править Лацу Давида, по причине не желания ронять все сервисы хв
const timeSlotsToClient = computed(() => {
    if (!timeSlots.value.length) {
        return []
    }
    const timeSlotsCopy = structuredClone(timeSlots.value)
    const timeSlotsCopySorted = timeSlotsCopy.sort((slot1, slot2) => slot1 - slot2)
    const firstDate = timeSlotsCopySorted[0].window_from_dt
    const result = [{
        title: new Date(firstDate) === new Date() ? 'Сегодня' : moment(firstDate).format('DD.MM.YYYY'),
        value: 'today',
        disabled: true,
        bold: true
    }]
    let currentDay = new Date(firstDate).getDay()
    for (const slot of timeSlotsCopySorted) {
        if (new Date(slot.window_from_dt).getDay() !== currentDay) {
            currentDay = new Date(slot.window_from_dt).getDay()
            result.push({
                title: moment(slot.window_from_dt).format('DD.MM.YYYY'),
                value: moment(slot.window_from_dt).format('DD.MM.YYYY'),
                disabled: true,
                bold: true
            })
        }
        const deliveryTimeName = deliveryTimes.value.find(time => time.id === slot.time_id)?.name || ''
        result.push({
            title: `с ${moment(slot.window_from_dt).format('HH:mm')} до ${moment(slot.window_to_dt).format('HH:mm')}` + (deliveryTimeName ? ` (${deliveryTimeName})` : ''),
            value: slot
        })
    }
    return result
})

const timeSlotsForm = ref(new BaseTimeSlotsForm())

const fetchTimeSlotsAsync = async () => {
    if (isDisabledTimeSlotsBtn.value) {
        return
    }
    isLoadingTimeSlots.value = true
    const fullTimeSlotsForm = {
        ...timeSlotsForm.value,
        waterCompanyId: clientForm.value.water_company_id,
        latitude: addressForm.value.latitude,
        longitude: addressForm.value.longitude,
        cart: cartToDSSNaming(orderForm.value.cart),
        fromDT: timeSlotsForm.value?.fromDT || new Date(),
        tracking_tags: {
            client_id: clientForm.value.id ?? '',
            customer_source_name: '',
            guest_id: '',
            operator_id: '',
            source_name: '',
            utm_campaign: '',
            utm_source: ''
        }
    }
    timeSlots.value = await fetchTimeSlots(fullTimeSlotsForm).finally(() =>
        isLoadingTimeSlots.value = false
    )
    checkCurrentSlot()
}
const fetchTimeSlotsDebounce = debounce(fetchTimeSlotsAsync, 1000)
const checkCurrentSlot = () => {
    const ORDER_ERROR_STATUS_ID = 10
    if (!orderForm.value.window_from_dt) {
        return
    }
    if (!timeSlots.value.find((slot) => slot.window_from_dt === orderForm.value.window_from_dt && slot.window_to_dt === orderForm.value.window_to_dt) && orderForm.value.status_id === ORDER_ERROR_STATUS_ID) {
        orderForm.value.delivery_job_type_id = null
        orderForm.value.dispatch_request_id = null
        orderForm.value.window_from_dt = null
        orderForm.value.window_to_dt = null
        orderForm.value.time_id = null
        orderForm.value.delivery_type_id = null
    }
}

const changeAddressHandler = (addressId) => {
    if (!addressId) {
        addressForm.value = new BaseAddress({ customer_id: clientForm.value.id, is_primary: true, water_company_id: clientForm.value.water_company_id });
        orderForm.value.address_id = null
    }
    else {
        addressForm.value = clientForm.value.addresses.find((customerAddress) => +customerAddress.id === +addressId)
        fetchTimeSlotsAsync()
    }
}

const formatTimeSlotValue = () => {
    if (isLoadingTimeSlots.value) {
        return 'Слоты загружаются'
    }
    if (!orderForm.value.window_from_dt) {
        return 'Выберите слот'
    }
    const deliveryTimeName = deliveryTimes.value.find(time => time.id === orderForm.value.time_id)?.name || ''
    return `${moment(orderForm.value.window_from_dt).format('DD.MM.YYYY')} - с ${moment(orderForm.value.window_from_dt).format('HH:mm')} до ${moment(orderForm.value.window_to_dt).format('HH:mm')}` + (deliveryTimeName ? ` (${deliveryTimeName})` : '')
}
const updateSlotsHandler = (event) => {
    orderForm.value.delivery_job_type_id = event.delivery_job_type_id ?? null
    orderForm.value.dispatch_request_id = event.dispatch_request_id ?? null
    orderForm.value.window_from_dt = event.window_from_dt
    orderForm.value.window_to_dt = event.window_to_dt
    orderForm.value.time_id = event.time_id
    orderForm.value.delivery_type_id = event.delivery_type_id
}
const timeSlotsErrors = computed(() => {
    const errors = [
        ...orderFormValidator.value.time_id.$errors,
        ...orderFormValidator.value.window_from_dt.$errors,
        ...orderFormValidator.value.window_to_dt.$errors,
        ...orderFormValidator.value.delivery_type_id.$errors,
    ]
    return errors
})

const fetchOrderStatuses = async (params) => {
    orderStatuses.value = await getOrderStatuses(params)
}

const createOrder = async () => {
    orderFormValidator.value.$validate()
    let sendWand = true
    if (orderFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей заказа' }
    }
    if (!currentOrderId.value) {
        return await postOrder(orderForm.value)
    } else {
        const data = await patchOrder(orderForm.value)
        sendWand = data.sendWand ?? true
        loadEditPage()
    }
    return { orderId: currentOrderId.value, sendWand }
}



const fetchNewClient = async () => {
    clientFormValidator.value.$validate()
    if (clientFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей клиента' }
    }

    let customerData;
    if (customerChanged.value && clientForm.value.id) {
        customerData = await patchCustomer(clientForm.value)
    } else if (!clientForm.value.id) {
        customerData = await postCustomer(clientForm.value)
        addressForm.value.customer_id = customerData.customerId
        orderForm.value.customer_id = customerData.customerId
        clientForm.value.id = customerData.customerId
    }
    customerChanged.value = false
    return customerData
}

const fetchNewAddress = async () => {
    addressFormValidator.value.$validate()
    if (addressFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей адреса' }
    }

    let addressData;
    if (addressChanged.value && addressForm.value.id) {
        addressData = await patchAddress(addressForm.value)
    } else if (!addressForm.value.id) {
        addressData = await postAddress(addressForm.value)
        orderForm.value.address_id = addressData.id
        addressForm.value = new BaseAddress(addressData)
    }
    addressChanged.value = false
    return addressData
}

const submitCreateOrder = async () => {
    try {
        isLoading.value = true
        await fetchNewClient()
        await fetchNewAddress()

        const { orderId, sendWand } = await createOrder()
        if (sendWand || sendWand === null) {
            toast.success(currentOrderId.value ? 'Заказ отредактирован!' : 'Заказ создан!')
            router.push({ path: '/orders', query: { orderId, water_company_id: clientForm.value.water_company_id } })
        } else {
            currentOrderId.value = orderId
            orderForm.value.id = orderId
            router.push({ query: { orderId } })
            fetchTimeSlotsAsync()
            toast.error('Не удалось отправить в Wand!')
        }
    } catch (error) {
        showErrors(error)
        clientFormApiValidationErrors.value = parseApiErrorsForValidator(error)
        addressFormApiValidationErrors.value = parseApiErrorsForValidator(error)
        orderFormApiValidationErrors.value = parseApiErrorsForValidator(error)
    } finally {
        isLoading.value = false
    }
}

const products = ref([]);
const fetchProductList = async () => {
    products.value = await getProductList({ water_company_id: clientForm.value.water_company_id })
}

const loadEditPage = async () => {
    orderForm.value = new BaseOrder(await getOrderById(currentOrderId.value))
    clientForm.value.id = orderForm.value.customer_id
    addressForm.value.id = orderForm.value.address_id
    fetchPaymentTypesAsync(orderForm.value.water_company_id)
    fetchOrderStatuses({ status_id: orderForm.value.status_id, create: false })
    await fetchCustomerById(clientForm.value.id)
    fetchTimeSlotsDebounce()
}

const setDefaultOrderParams = () => {
    orderForm.value.need_check = clientForm.value.need_check ?? false
}

watch(
    () => route.query,
    async () => {
        customerId.value = route.query.customerId ?? null
        currentOrderId.value = route.query.orderId ?? null
        if (currentOrderId.value) {
            loadEditPage()
        } else {
            if (customerId.value) {
                await fetchCustomerById(customerId.value)
                getDefaultCart()
                setDefaultOrderParams()
            } else {
                router.push('/customers/search')
            }
            fetchOrderStatuses({ create: true })
        }
    },
    {
        deep: true,
        immediate: true
    }
)

const popularProducts = ref([])
const getPopularProductsAsync = async () => {
    popularProducts.value = await getPopularProducts(clientForm.value.water_company_id)
}


const paymentTypes = ref([])
const fetchPaymentTypesAsync = async (water_company_id) => {
    paymentTypes.value = await fetchPaymentTypes(water_company_id)
    checkPaymentType()
}

const DEFAULT_SPB_PAYMENT_TYPE_ID = 4
const DEFAULT_MSK_PAYMENT_TYPE_ID = 10
const getDefaultPaymentType = () => {
    const SPB_WATER_COMPANY_ID = 1
    return clientForm.value.water_company_id === SPB_WATER_COMPANY_ID ? DEFAULT_SPB_PAYMENT_TYPE_ID : DEFAULT_MSK_PAYMENT_TYPE_ID
}
const checkPaymentType = () => {
    checkClientPaymentType()
    checkBasketPaymentTypes()
}
const checkClientPaymentType = () => {
    if (!paymentTypes.value.some((paymentType) => paymentType.id === clientForm.value.payment_type)) {
        clientForm.value.payment_type = getDefaultPaymentType()
        customerChanged.value = true
    }
}
const checkBasketPaymentTypes = () => {
    orderForm.value.cart.forEach((item) => {
        if (!paymentTypes.value.some((paymentType) => paymentType.id === item.payment_type_id)) {
            item.payment_type_id = getDefaultPaymentType()
        }
    })
}


const pageBreadcrumbs = computed(() => [
    {
        title: 'Заказы',
        href: '/orders'
    },
    {
        title: currentOrderId.value ? 'Редактировать' : 'Добавить',
        href: '#'
    },
])

onMounted(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
})

const updateAddressFormHandler = () => {
    addressChanged.value = true
}


const fetchingBinding = ref(false)
const addBindingHandler = async (event) => {
    try {
        if (!orderForm.value.address_id) {
            await fetchNewAddress()
        }
        fetchingBinding.value = true
        const { data } = await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customers/bindings/create', {
            ...event,
            address_id: orderForm.value.address_id,
            customer_id: orderForm.value.customer_id,
            water_company_id: orderForm.value.water_company_id
        })
        toast.success('Связка успешно создана!')
        orderForm.value.customer_1c_connection = data.data.id
        clientForm.value.customerBindings = await getCustomersBindings(clientForm.value.id)
    } catch (error) {
        showErrors(error)
    } finally {
        fetchingBinding.value = false
    }
}

const checkCustomerDuty = computed(() => {
    return clientForm.value?.customerBindings?.some(binding => binding.payer.amount)
})

const customersDutyGrouped = computed(() => {
    const result = {}

    clientForm.value?.customerBindings?.forEach((binding) => {
        if (!result?.[binding.payer.id]) {
            result[binding.payer.id] = binding.payer.amount
        }
    })

    return result
})

const isDangerAmout = computed(() => {
    return clientForm.value?.customerBindings?.some((binding) => binding.payer.amount > 0)
})

const getPayerById = (payerId) => {
    return clientForm.value?.customerBindings?.find((binding) => binding.payer.id === +payerId).payer
}
</script>

<template>
    <div class="content-wrapper" v-if="isAuthenticated">
        <UILoader :loading="isLoading" />

        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">{{ currentOrderId ? 'Редактировать' : 'Добавить' }} заказ</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <UIBreadCrumbs :items="pageBreadcrumbs" />
            <div class="row">
                <div class="container-xl ml-0">
                    <div v-if="checkCustomerDuty" class="card"
                        :class="{ 'alert-danger': isDangerAmout && !clientForm.is_vip, 'alert-success': clientForm.is_vip }">
                        <div class="card-body p-2">
                            Безналичный долг<br />
                            <span v-for="(amount, payerId) in customersDutyGrouped" :key="payerId">
                                {{ getPayerById(payerId).name }} - сумма: {{ amount }}&nbsp;₽<br />
                            </span>
                            Итого: <b>{{ clientForm.duty }} &nbsp;₽</b>
                        </div>
                    </div>

                    <!-- Справочные данные -->
                    <!-- <div class="card">
                        <div class="card-body p-2">
                            <div class="form-row">
                                <div class="form-group mb-0 col-md-auto mb-0">
                                    Бонус за рефералов: <b>1000,00</b>&nbsp;Р
                                </div>
                                <div class="form-group mb-0 col-md-auto mb-0">
                                    |
                                </div>
                                <div class="form-group mb-0 col-md-auto mb-0">
                                    Бонус за воду: <b>2000,00</b>&nbsp;Р
                                </div>
                            </div>
                        </div>
                    </div>
                    -->
                    <!-- Основная информация -->
                    <ClientForm v-model="clientForm" :isEdit="!!clientForm.id" isMainInfo
                        :validator="clientFormValidator" :paymentTypes="paymentTypes"
                        @update:modelValue="customerChangeHandler" />

                    <!-- Адрес -->
                    <AddressForm v-model="addressForm" :addresses="currentOrderId ? [] : clientForm.addresses"
                        :disableMainFields="isLoadingTimeSlots" v-model:currentAddress="orderForm.address_id"
                        :newAddress="orderForm.address_id === null" @update-main-info="fetchTimeSlotsAsync"
                        @update:modelValue="updateAddressFormHandler" :customerType="clientForm.type"
                        :waterCompanyId="clientForm.water_company_id" :validator="addressFormValidator"
                        @update:currentAddress="changeAddressHandler" :newClient="!clientForm.id" />

                    <!-- Товары и корзина -->
                    <OrderBasket ref="orderBasket" :client="clientForm" v-model:cart="orderForm.cart"
                        :popularProducts="popularProducts" @update-time-slots="fetchTimeSlotsDebounce"
                        :fetchingTimeSlots="isLoadingTimeSlots" :products="products" :paymentTypes="paymentTypes"
                        @fix-price="fixPriceHandler" />

                    <!-- Слоты -->
                    <div class="card">
                        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-clock mr-2"></i>Время
                            доставки</div>
                        <div class="card-body p-2">
                            <div class="form-row">
                                <Datapicker class="form-control form-control-sm" v-model="timeSlotsForm.fromDT"
                                    @update:model-value="fetchTimeSlotsAsync" :locale="ru" input-format='dd.MM.yyyy'
                                    position="left" typeable clearable :lowerLimit="new Date()"
                                    :disabled="isLoadingTimeSlots" />
                                <UISelect :options="timeSlotsToClient" :modelValue="orderForm"
                                    @update:model-value="updateSlotsHandler" :search="false"
                                    class="form-group mb-0 col-md-5 col-xl-5"
                                    :currentInputValueFormatFunction="formatTimeSlotValue" :errors="timeSlotsErrors"
                                    :readonly="isLoadingTimeSlots" />
                                <div class="form-group mb-0 col-md-2 col-xl-2">
                                    <UIButton type="submit" :isLoading="isLoadingTimeSlots"
                                        :disabled="isDisabledTimeSlotsBtn"
                                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light"
                                        @click="fetchTimeSlotsAsync">Обновить</UIButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Дополнительно -->
                    <AdditionalOrderInfoForm v-model="orderForm" :bindings="clientForm.customerBindings"
                        :fetchingBinding="fetchingBinding" :showBindings="clientForm.type === 1"
                        @add-binding="addBindingHandler" />

                    <!-- Оформление заказа -->
                    <div class="card">
                        <div class="card-body p-2">
                            <div class="form-row">
                                <div class="form-group mb-0 col-md-8 col-xl-8">
                                </div>
                                <UISelect class="mb-0 col-md-2 col-xl-2" v-model="orderForm.status_id"
                                    :options="orderStatuses"
                                    :mapOptions="(option) => ({ title: option.name, value: option.id })"
                                    :search="false" />
                                <div class="form-group mb-0 col-md-2 col-xl-2">
                                    <button type="submit" @click="submitCreateOrder" :disabled="!orderForm.cart.length"
                                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light">{{
                                            currentOrderId ? 'Редактировать' : 'Добавить' }}
                                        заказ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-5">
                <div class="container-xl ml-0">
                    <!-- Статистика -->
                    <OrdersTable :orders="customerOrders" :isFinishedOrderRequest="isFinishedOrderStatisticRequest"
                        :customer="clientForm" v-model:filters="ordersFilters" :loading="customerOrdersLoading"
                        @search="getCustomerOrders(clientForm.id, ordersFilters)" />
                </div>
            </div>
        </div>
    </div>
</template>
