<script setup>
import {useUserStore} from "@/store";
import {computed, ref} from "vue";
import api from "@/common/api";
import {useRoute} from 'vue-router'
import {showErrors} from "@/common/showErrors";
import {useToast} from 'vue-toastification';
import SpinnerLoading from "@/components/Common/SpinnerLoading.vue";
import format from "date-fns/format";
const toast = useToast();

const route = useRoute();
const date = ref(route.query.date);
const intervals = ref(route.query.intervals ? JSON.parse(route.query.intervals) : []);
const intervalsStr = ref(route.query.intervalsStr);
const report = ref([]);
const waterCompanyId = ref(route.query.wcId);
const warehouseId = ref(route.query.warehouseId);

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);

getReport();

const getReport = async () => {
  let searchSet = {}
  searchSet['waterCompanyId'] = waterCompanyId.value
  searchSet['date'] = date.value
  if (warehouseId.value) {
    searchSet['warehouseId'] = warehouseId.value
  }
  if (intervals.value.length > 0) {
    searchSet['intervals'] = intervals.value
  }
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/delivery-requests/report', {params: searchSet}).then(function (response) {
    report.value = response.data.data
    if (report.value.length === 0) {
      toast.info("Отчет не найден")
    }
  }).catch(function (error) {
    showErrors(error.response)
    return []
  })
}
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Водители и экспедиторы Отчет - {{ format(new Date(date), 'dd.MM.yyyy') }} ({{ intervalsStr }})</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
              <li class="breadcrumb-item"><a href="#">Заказы</a></li>
              <li class="breadcrumb-item active">Доставленные</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body table-responsive">
                <table class="table table-head-fixed text-nowrap table-bordered table-sm" v-if="report.length > 0">
                  <thead>
                  <tr>
                    <th>№</th>
                    <th>Интервал</th>
                    <th>Водитель</th>
                    <th>Склад</th>
                    <th>Бут<br>загружено</th>
                    <th>Бут<br>доставлено</th>
                    <th>Бут<br>не&nbsp;доставлено</th>
                    <th>Заказов<br>всего</th>
                    <th>Заказов<br>доставлено</th>
                    <th>Заказов<br>не&nbsp;доставлено</th>
                    <th>б/нал</th>
                    <th>нал.</th>
                    <th>нал. с док.</th>
                    <th>картой</th>
                    <th>отменено</th>
                    <th>частично</th>
                    <th>переплата</th>
                    <th>отдать</th>
                    <th>Нал</th>
                    <th>Итого (нал)</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in report" :key="index">
                    <td class="right">{{ index + 1 }}</td>
                    <td class="right">
                      <template v-for="(interval, index) in item.intervals" :key="interval.id">
                        <span>{{ (index < item.intervals.length - 1) ? interval.name + '/' : interval.name }}</span>
                      </template>
                    </td>
                    <td>{{ item.driver.fullName }}</td>
                    <td>{{ item.warehouse.name }}</td>
                    <td>{{ item.bottlesLoaded }}</td>
                    <td>{{ item.bottlesDelivered }}</td>
                    <td>{{ item.bottlesNotDelivered }}</td>
                    <td>{{ item.ordersTotal }}</td>
                    <td>{{ item.ordersDelivered }}</td>
                    <td>{{ item.ordersNotDelivered }}</td>
                    <td class="right">{{ item.cashless }}&nbsp;&#8381;</td>
                    <td class="right">{{ item.cash }}&nbsp;&#8381;</td>
                    <td class="right text-grey">{{ item.cashWithDoc }}&nbsp;&#8381;</td>
                    <td class="right">{{ item.byCard }}&nbsp;&#8381;</td>
                    <td class="right text-grey">{{ item.canceled }}&nbsp;&#8381;</td>
                    <td class="right text-grey">{{ item.partially }}&nbsp;&#8381;</td>
                    <td class="right text-grey">{{ item.overPayment }}&nbsp;&#8381;</td>
                    <td class="right text-grey">{{ item.giveAway }}&nbsp;&#8381;</td>
                    <td class="right">{{ item.cash }}&nbsp;&#8381;</td>
                    <td class="right total">{{ item.totalCash }}&nbsp;&#8381;</td>
                  </tr>
                  </tbody>
                </table>
                <SpinnerLoading v-else/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>




