<script setup>

import {useUserStore} from "@/store";
import {computed} from "vue";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <div class="content-header">
      <div class="container-fluid">
        <h1>Настройки</h1>
      </div>
    </div>
  </div>
</template>




